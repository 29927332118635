import { EmailMarketingCampaign } from "types/digitalMarketing/campaigns";
import { startCase } from 'lodash'
import moment from 'moment'
import { LoadingSkeleton } from 'components'
import {
  TableRow,
  TableRowLoading,
  TableRowNoData,
  TableRowName,
  TableCell,
  TableCellStatus,
  StatusBadge,
} from "./styles";
import { Check, Schedule, ErrorOutline, Pause, Block } from '@mui/icons-material';

export const Skeleton = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <TableRowLoading key={index}>
          <TableCell>
            <LoadingSkeleton />
          </TableCell>
        </TableRowLoading>
      ))}
    </>
  )
}

export const NoData = () => {
  return (
    <TableRowNoData>
      <TableCell centered fullWidth>
        No data
      </TableCell>
    </TableRowNoData>
  )
}

/**
 * Email Marketing Campaigns table Row
 */
export const CampaignItem = (props: { 
  item: EmailMarketingCampaign;
  onAction: (item: EmailMarketingCampaign) => void;
}) => {
  const { item, onAction } = props;
 
  return (
    <TableRow>
      <TableRowName onClick={() => onAction(item)}>{item?.name}</TableRowName>
      <TableCell>{item.subject}</TableCell>
      <TableCell>{item?.scheduled_at ? moment(item?.scheduled_at).format('MM/DD/YY h:mm A') : '-'}</TableCell>
      <TableCellStatus>
        <CampaignStatusBadge status={item.aasm_state} />
      </TableCellStatus>
    </TableRow>
  )
}

export const StatusIcon = ({ status } : { status: string }) => {
  switch (status) {
    case 'error':
      return <ErrorOutline style={{ color: '#C13030', fontSize: 14 }} />;
    case 'paused':
      return <Pause style={{ color: '#CB9709', fontSize: 14 }} />;
    case 'sent':
      return <Check style={{ color: '#32965D', fontSize: 14 }} />;
    case 'draft':
    case 'pending_approval':
      return <Schedule style={{ color: '#1B51A4', fontSize: 14 }} />;
    case 'approved':
      return <Check style={{ color: '#32965D', fontSize: 14 }} />;
    case 'rejected':
      return <Block style={{ color: '#C13030', fontSize: 14 }} />;
    default:
      return <Schedule style={{ color: '#1B51A4', fontSize: 14 }} />;
  }
};

export const CampaignStatusBadge = ({ status } : { status: string }) => (
  <StatusBadge status={status}>
    <StatusIcon status={status} />
    <span>{startCase(status)}</span>
  </StatusBadge>
);