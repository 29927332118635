import { ReactComponent as DownArrowIcon } from 'assets/icons/down-arrow-icon.svg'
import { ReactComponent as Popout } from 'assets/icons/popout-icon.svg'
import SearchIcon from 'assets/icons/search.svg'
import { Link, NavLink as RouterNavLink } from 'react-router-dom'
import styled from 'styled-components'
import {
  backdrop,
  borderRadius,
  breakpoints,
  colors,
  elevation,
  fontWeight,
  inset0,
  shadow,
  transition,
} from 'theme'

export const Backdrop = styled.div.attrs({ role: 'button' })({
  ...backdrop,
  backdropFilter: 'unset',
  backgroundColor: 'unset',
})

export const Container = styled.div({
  position: 'fixed',
  transform: 'none',
  display: 'none',
  width: '100vw',
  height: '88px',
  justifyContent: 'space-between',
  flexDirection: 'row',
  padding: '0px',
  fontWeight: '500',
  backgroundColor: colors.gray[400],
  borderBottom: '1px solid #EBEBEB',
  color: colors.primary.DEFAULT,
  [`@media ${breakpoints.sm}`]: {
    display: 'flex',
  },
  zIndex: 10,
})

export const MobileContainer = styled.div({
  position: 'fixed',
  width: '100%',
  height: '5rem',
  minHeight: '88px',
  fontWeight: '500',
  flexDirection: 'row',
  alignItems: 'center',
  overflow: 'hidden',
  display: 'flex',
  backgroundColor: colors.gray[400],
  color: colors.primary.DEFAULT,
  zIndex: elevation.backdrop,
  borderBottom: '1px solid #EBEBEB',
  [`@media ${breakpoints.sm}`]: {
    display: 'none',
  },
})

export const SideBar = styled.div<{ isOpen: boolean }>(({ isOpen }) => ({
  ...inset0,
  ...transition,
  transitionDuration: '200ms',
  position: 'fixed',
  transform: isOpen ? 'none' : 'translate(-100%, 0)',
  width: '100%',
  height: '100vh',
  fontWeight: '500',
  flexDirection: 'column',
  backgroundColor: colors.gray[400],
  borderBottom: '1px solid #EBEBEB',
  borderRight: '1px solid #EBEBEB',
  color: colors.primary.DEFAULT,
  zIndex: elevation.backdrop,
  display: 'flex',
  padding: '0px 24px',
  [`@media ${breakpoints.sm}`]: {
    width: '390px',
  },
}))

export const Header = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  height: 'auto',
  [`@media ${breakpoints.sm}`]: {
    alignItems: 'center',
    height: '88px',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

export const CloseButton = styled.img.attrs({ role: 'button' })({
  width: '2rem',
  height: '2rem',
  position: 'absolute',
  left: '24px',
  top: '28px',
})

export const ClearButton = styled.img.attrs({ role: 'button' })({
  width: 24,
  height: 24,
  position: 'absolute',
  right: 10,
  marginTop: -12,
  top: '50%',
})

export const LogoLink = styled(Link)({
  display: 'flex',
  justifyContent: 'center',
  margin: '28px',
  alignItems: 'center',
  height: 'auto',
  alignSelf: 'center',
  [`@media ${breakpoints.sm}`]: {
    height: '7rem',
    alignSelf: 'auto',
    margin: '0 10px',
  },
})

export const LogoLinkMobile = styled(Link)({
  display: 'flex',
  position: 'fixed',
  justifyContent: 'center',
  width: '75%',
  left: '50%',
  marginLeft: '-37.5%',
  [`@media ${breakpoints.sm}`]: {
    display: 'none',
    justifyContent: 'center',
    margin: '1rem',
    alignItems: 'center',
  },
})

export const Nav = styled.nav({
  width: '100%',
  borderBottom: 'solid 1px #ccc',
  paddingBottom: '10px',
  marginBottom: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '0px',
})

export const NavLink = styled(RouterNavLink)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '6px 12px',
  margin: '0.5rem 0px',
  fontSize: '14px',
  fontWeight: fontWeight.medium,
  lineHeight: '1.5rem',
  borderRadius: borderRadius.lg,
  columnGap: '0.5rem',
  color: '#484848',
  transition: 'all 275ms cubic-bezier(0.33, 1, 0.68, 1) 0s',
  '&:hover': {
    backgroundColor: colors.gray[200],
    color: colors.gray[600],
    fontWeight: 600,
    '#icon_health': {
      path: {
        fill: colors.blue['700'],
      },
      rect: {
        fill: colors.white,
      },
    },
    '#icon_locations': {
      path: {
        fill: colors.white,
      },
      rect: {
        fill: colors.blue['700'],
      },
    },
  },
  '&.active': {
    backgroundColor: colors.blue[400],
    color: colors.blue[700],
    '&:hover': {
      backgroundColor: colors.blue[500],
    },
  },
})

export const ManageLink = styled(RouterNavLink)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '6px 12px',
  margin: '0.5rem 0',
  fontSize: '14px',
  fontWeight: fontWeight.medium,
  lineHeight: '1.5rem',
  borderRadius: borderRadius.lg,
  color: '#484848',
})

export const Logout = styled.span({
  color: '#C13030',
})

export const NavLinkText = styled.div({
  display: 'flex',
  columnGap: '1rem',
  alignItems: 'center',
  '& span': {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '8px',
    },
  },
})

export const DownArrow = styled(DownArrowIcon)<{ $isExpanded: boolean }>(
  {
    width: '0.85rem',
    transform: 'rotate(-90deg)',
    transition: 'transform .1s ease-in',
  },
  ({ $isExpanded }) => $isExpanded && { transform: 'rotate(0deg)' },
)

export const PopOutIcon = styled(Popout)({
  height: '1.5rem',
  width: '1.5rem',
})

export const DropdownLink = styled(RouterNavLink)({
  height: '50px',
  paddingLeft: '4rem',
  display: 'flex',
  alignItems: 'center',
  fontWeight: fontWeight.medium,
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#a6c8e3',
    color: colors.primary.DEFAULT,
    fontWeight: fontWeight.bold,
  },
  '&.active': {
    backgroundColor: '#a6c8e3',
    color: colors.primary.DEFAULT,
    fontWeight: fontWeight.bold,
  },
})

export const OuterLink = styled.a({
  marginTop: '1rem',
  fontSize: '0.875rem',
  textAlign: 'center',
  textDecoration: 'underline',
  '&:active,&:hover': {
    textDecoration: 'none',
  },
})

export const ToolBarHeader = styled.div({
  display: 'none',
  alignItems: 'center',
  flexDirection: 'row',
  marginRight: 30,
  [`@media ${breakpoints.sm}`]: {
    display: 'flex',
  },
})

export const SearchWrapperHeader = styled.div({
  position: 'relative',
  width: '100%',
  border: 'none',
  padding: 0,
  margin: 0,
})

export const ToolBar = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  columnGap: '12px',
})

export const ManageAccount = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const SearchWrapper = styled.div({
  borderBottom: 'solid 1px #ccc',
  padding: '32px 0',
  margin: '50px 0px 12px 0',
  width: '100%',
  position: 'relative',
})

export const SearchResults = styled.div({
  display: 'flex',
  width: '100%',
  position: 'absolute',
  background: '#fff',
  borderRadius: '0.5rem',
  padding: '6px 5px',
  flexDirection: 'column',
  fontWeight: 'normal',
  maxHeight: '428px',
  color: colors.black,
  fontSize: 13,
  marginTop: 1,
  boxShadow: shadow.DEFAULT,
  overflowY: 'auto',
})

export const Result = styled.span({
  padding: '7px 10px',
  borderRadius: '5px',
  cursor: 'pointer',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.10)',
  },
  '&.active': {
    background: 'rgba(27, 81, 164, 0.10)',
  },
})

export const Search = styled.input({
  width: '100%',
  color: '#292929',
  border: 'solid 1px #D7D7D7',
  fontSize: 15,
  backgroundImage: `url(${SearchIcon})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '10px center',
  backgroundSize: '20px',
  position: 'relative',
  padding: '10px 40px',
  textOverflow: 'ellipsis',
  ['&::placeholder, &::-webkit-input-placeholder, &::-moz-placeholder']: {
    /* Add your placeholder styles here */
    color: '#292929',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&::-webkit-input-placeholder': {
    color: '#292929',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&::-moz-placeholder': {
    color: '#292929',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

export const Icon = styled.img({
  width: '24px',
  height: '24px',
})