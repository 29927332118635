import { OrderPlatformResource, PartnerResource, StatusResource, TypeName, getPartner } from 'api'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { PlatformName, PlatformSummary } from 'types'

import { PartnerResponse } from '../api/models'
import { retrieveMenuImportStatuses, retrievePlatforms } from '../utils/apiUtils'
import useRouteLocationId from './useRouteLocationId'

const useGetLocationPartners = (activeLocationId?: string | null) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isImportStatusesLoading, setImportStatusesLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [partner, setPartner] = useState<PartnerResource>()
  const [platforms, setPlatforms] = useState<PlatformSummary[]>()
  const [importedTime, setImportedTime] = useState<string>()
  const [importStatus, setImportStatus] = useState<boolean>()
  const [isEnqueued, setIsEnqueued] = useState<boolean>(false)
  const [enqueuedAt, setEnqueuedAt] = useState<string>()
  const [menuMappingMenuId, setMenuMappingMenuId] = useState<string>()
  const [menuImportStatuses, setMenuImportStatuses] = useState<StatusResource[]>()
  const [serviceFee, setServiceFee] = useState<number>()
  const [serviceFeeType, setServiceFeeType] = useState<string>()
  const [isReady, setIsReady] = useState<boolean>(false)
  const locationId = useRouteLocationId()

  const fetch = useCallback(async () => {
    const sortedLocationId = locationId ?? activeLocationId
    if (!sortedLocationId) {
      return
    }

    setIsLoading(true)

    let response: PartnerResponse | null = null
    try {
      response = await getPartner(sortedLocationId, {
        include:
          'order_platforms,order_platforms.menu_publish_status,order_platforms.service_fee_rate',
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        toast.error(err?.response?.data?.description)
      } else {
        toast.error('Error occured. Please contact support or try again later.')
      }
      setIsError(true)
    }

    if (response) {
      const { data, included } = response

      if (included?.length) {
        setPlatforms(retrievePlatforms(included, false))

        const parentPlatform = included.find(
          (item): item is OrderPlatformResource =>
            item.type === TypeName.OrderPlatform && item.attributes.name === PlatformName.CHOWLY,
        )

        const COOPlatform = included.find(
          (item): item is OrderPlatformResource =>
            item.type === TypeName.OrderPlatform && item.attributes.name === PlatformName.COO,
        )

        if (COOPlatform) {
          setServiceFee(COOPlatform?.attributes.service_fee_rate ?? 0)
          setServiceFeeType(COOPlatform?.attributes.service_fee_type)
        }
        if (parentPlatform) {
          setMenuMappingMenuId(parentPlatform?.attributes.menu_mapping_menu_id ?? '')
        }
      } else {
        setMenuMappingMenuId(undefined)
      }

      setIsError(false)
      setPartner(data)
    }

    if (response === null && !partner) {
      setIsError(true)
    }
    setIsLoading(false)
    setIsReady(true)
  }, [locationId])

  const fetchMenuImportStatuses = useCallback(async () => {
    if (!locationId) {
      return
    }

    setImportStatusesLoading(true)

    let response: PartnerResponse | null = null
    try {
      response = await getPartner(locationId, {
        include: 'menu_import_status',
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        toast.error(err?.response?.data?.description)
      } else {
        toast.error('Error occured. Please contact support or try again later.')
      }
      setIsError(true)
    }

    if (response) {
      const { data, included } = response

      if (included?.length) {
        setMenuImportStatuses(retrieveMenuImportStatuses(included))
        const status = included.find(
          (item): item is StatusResource =>
            item.type === 'status' && item.attributes.category === 'menu_import',
        )
        setImportStatus(status?.attributes.success)
        if (status?.attributes.enqueued_at) {
          setIsEnqueued(true)
          setEnqueuedAt(status?.attributes.enqueued_at)
        }

        setImportedTime(status?.attributes.timestamp)
      }

      setIsError(false)
      setPartner(data)
    }

    setImportStatusesLoading(false)
  }, [locationId])

  useEffect(() => {
    fetch()
    fetchMenuImportStatuses()
  }, [locationId])

  return {
    partner,
    menuImportStatuses,
    serviceFee,
    serviceFeeType,
    platforms,
    importedTime,
    isLoading,
    isImportStatusesLoading,
    isError,
    importStatus,
    menuMappingMenuId,
    isEnqueued,
    enqueuedAt,
    isReady,
    refetch: fetch,
  }
}

export default useGetLocationPartners
