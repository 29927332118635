import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { updateOrderPlatform } from 'api'
import filled_dollarSign from 'assets/icons/Filled_DollarSign.svg'
import filled_percentSign from 'assets/icons/Filled_PercentageSign.svg'
import outline_dollarSign from 'assets/icons/Outline_DollarSign.svg'
import outline_percentSign from 'assets/icons/Outline_PercentageSign.svg'
import settings from 'assets/icons/Settings.svg'
import { useState } from 'react'
import { toast } from 'react-toastify'

import * as Styled from './styles'

interface Props {
  locationId: string | null
  refetch: () => Promise<void>
  platformId: number
  serviceFee: number | undefined
  serviceFeeType: string | undefined
}

const getNumServiceFeeType = (type?: string): number => {
  if (type === 'percentage') {
    return 1
  }
  return 0
}

const ServiceFee = ({ locationId, refetch, platformId, serviceFee, serviceFeeType }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [showDollar, setShowDollar] = useState(serviceFeeType === 'percentage' ? false : true)
  const [localServiceFee, setLocalServiceFee] = useState('')
  const [localServiceType, setLocalServiceType] = useState(getNumServiceFeeType(serviceFeeType)) // 0 -> 'fixed' || 1 -> 'percentage'

  const clearModalValues = () => {
    // reset values to defaults
    setLocalServiceFee('')
  }

  const closeModal = () => {
    setShowModal(false)
    clearModalValues()
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLocalServiceFee(e.target.value)
  }

  const validateFee = () => {
    let valid = true

    if (!localServiceFee) {
      toast.error('Please enter a value', { autoClose: 5000 })
      valid = false
    }

    const numLocalServiceFee = Number(localServiceFee)

    if (
      localServiceType === 1 &&
      (isNaN(numLocalServiceFee) || numLocalServiceFee < 0 || numLocalServiceFee > 100)
    ) {
      toast.error('Please enter a percentage between 0 and 100', { autoClose: 5000 })
      valid = false
    }

    if (localServiceType === 0 && (isNaN(numLocalServiceFee) || numLocalServiceFee < 0)) {
      toast.error('Please enter a number greater than 0', { autoClose: 5000 })
      valid = false
    }

    if (
      numLocalServiceFee == serviceFee &&
      localServiceType === getNumServiceFeeType(serviceFeeType)
    ) {
      toast.error('Please enter a different value', { autoClose: 5000 })
      valid = false
    }

    return valid
  }

  const handleSubmit = async () => {
    if (!validateFee()) return
    await updateFee()
    closeModal()
  }

  const updateFee = async () => {
    if (locationId) {
      try {
        await updateOrderPlatform(platformId, locationId, {
          service_fee_rate: Number(localServiceFee),
          service_fee_type: localServiceType === 1 ? 'percentage' : 'fixed',
        })
        refetch()
        toast.success('Saved!', { autoClose: 5000 })
      } catch (e) {
        toast.error('Sorry. An error occured while saving the Platform Fee.', { autoClose: 5000 })
      }
    }
  }

  const getInputSubtitle = () => {
    return showDollar
      ? 'Upcharge amount will apply to all baskets prior to tax.'
      : 'Upcharge percentage will apply to all baskets prior to tax.'
  }

  const toggleType = () => {
    setShowDollar(!showDollar)
    return
  }

  const getInputPlaceholder = () => {
    if (serviceFeeType === 'percentage' && !showDollar) {
      return serviceFee?.toString()
    }

    if (serviceFeeType === 'fixed' && showDollar) {
      return serviceFee?.toString()
    }
    return ''
  }

  return (
    <>
      <Styled.FeeContainer>
        <Styled.HeaderContainer>
          <Styled.Header>Chowly Service Fee</Styled.Header>
          {getNumServiceFeeType(serviceFeeType) === 0 && (
            <Styled.Price>{`$${serviceFee}`}</Styled.Price>
          )}
          {getNumServiceFeeType(serviceFeeType) === 1 && (
            <Styled.Price>{`${serviceFee}%`}</Styled.Price>
          )}
        </Styled.HeaderContainer>
        <IconButton style={{ padding: 0 }} onClick={() => setShowModal(true)}>
          <Styled.Icon src={settings} alt='settings' />
        </IconButton>
      </Styled.FeeContainer>

      {showModal && (
        <Dialog
          open={showModal}
          onClose={closeModal}
          sx={{
            '& .MuiDialog-paper': {
              width: '343px',
              height: '212px',
              borderRadius: '8px',
            },
          }}
        >
          <DialogTitle style={{ paddingTop: '24px' }}>
            <Styled.ModalHeader>
              <Styled.Header>Chowly Platform Fee</Styled.Header>
              <Styled.IconContainer>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    toggleType()
                    setLocalServiceType(0)
                  }}
                >
                  <Styled.Icon
                    backGroundColor={showDollar}
                    src={showDollar ? filled_dollarSign : outline_dollarSign}
                    alt='dollarSign'
                  />
                </IconButton>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    toggleType()
                    setLocalServiceType(1)
                  }}
                >
                  <Styled.Icon
                    backGroundColor={!showDollar}
                    src={!showDollar ? filled_percentSign : outline_percentSign}
                    alt='percentSign'
                  />
                </IconButton>
              </Styled.IconContainer>
            </Styled.ModalHeader>
          </DialogTitle>
          <DialogContent sx={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <TextField
                placeholder={getInputPlaceholder()}
                onChange={(e) => handleInput(e)}
                fullWidth
                type='number'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <span style={{ color: '#1B1B1B' }}>{showDollar ? '$' : '%'}</span>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  display: 'flex',
                  padding: 0,
                  alignItems: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                  width: '100%',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#e5e7eb', // Prevent border from highlighting
                    },
                  },
                  '& .MuiInputBase-input, .MuiOutlinedInput-input': {
                    boxSizing: 'border-box',
                    boxShadow: 'none',
                    height: '2.4375em',
                  },
                }}
              />
              <Typography>
                <Styled.ModalText>{getInputSubtitle()}</Styled.ModalText>
              </Typography>
            </div>
            <DialogActions sx={{ padding: 0 }}>
              <Styled.ModalFooter>
                <Button sx={{ padding: 0 }} onClick={closeModal}>
                  <Styled.PrimaryButton data-testid={'cancel'}>Cancel</Styled.PrimaryButton>
                </Button>
                <Button sx={{ padding: 0 }} onClick={handleSubmit} variant='contained'>
                  <Styled.PrimaryButton backGroundColor={true} data-testid={'apply'}>
                    Apply
                  </Styled.PrimaryButton>
                </Button>
              </Styled.ModalFooter>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default ServiceFee
