import styled from '@emotion/styled';
import Select from '@mui/material/Select';

export const NoData = styled.div`
  text-align: center;
  padding: 12px;
  font-size: 12px;
  font-weight: 500;
  color: #777;
  border-bottom: 1px solid #ddd;
  @media (max-width: 440px) {
    padding: 8px;
    font-size: 10px;
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  padding: 8px 0;
  margin-left: 20px;
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
`;

export const Tab = styled.button<{ active?: boolean }>`
  margin-right: 16px;
  padding: 6px 8px;
  background: ${({ active }) => (active ? 'rgba(27, 81, 164, 0.10)' : 'transparent')};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #1B51A4;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  font-family: Inter;
  &:hover {
    background: #f0f0f0;
  }
`;

export const SearchInput = styled.input<{ visible: boolean }>`
  padding: 4px 8px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  font-size: 14px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

export const EmailMarketingCampaignsTable = styled.div`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
  padding: 16px;
  font-weight: 500;
  color: #777;
  font-family: Inter;
  font-size: 11px;
  line-height: 150%;
  border-bottom: 1px solid #e0e0e0;
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
  padding: 8px 16px;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
`;

export const TableRowLoading = styled.div`
  display: grid;
  padding: 8px 16px;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
`;

export const TableRowNoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  border-bottom: 1px solid #e0e0e0;
`;

export const TableRowName = styled.div`
  color: #1B51A4;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const TableCell = styled.div<{ centered?: boolean, fullWidth?: boolean }>`
  color: #292929;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  ${({ fullWidth }) => fullWidth && `flex-grow: 1;`}
  ${({ centered }) => centered && `text-align: center;`}
`;

export const TableCellStatus = styled.div`
  display: flex;
  justify-content: start;
  padding-left: 30%
`;

export const StatusBadge = styled.div<{ status: string }>`
  width: fit-content;
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background-color: ${({ status }) =>
    status === 'sent' ? 'rgba(50, 150, 93, 0.10)' :
    status === 'error' ? 'rgba(193, 48, 48, 0.10)' :
    status === 'paused' ? 'rgba(255, 190, 11, 0.10)' :
    status === 'approved' ? 'rgba(50, 150, 93, 0.10)' :
    status === 'rejected' ? 'rgba(193, 48, 48, 0.10)' :
    status === 'pending_approval' ? 'rgba(119, 119, 119, 0.10)' :
    status === 'draft' ? 'rgba(119, 119, 119, 0.10)' : 'rgba(119, 119, 119, 0.10)'
  };

  & > span {
    color: ${({ status }) =>
      status === 'sent' ? '#32965D' :
      status === 'error' ? '#C13030' :
      status === 'paused' ? '#CB9709':
      status === 'approved' ? '#32965D' :
      status === 'rejected' ? '#C13030' :
      status === 'pending_approval' ? '#292929' :
      status === 'draft' ? '#292929' : '#292929'
    };
    text-align: right;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    white-space: nowrap;
  }
`;

export const SearchIconWrapper = styled.div`
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--Global-Interaction-Inactive, #D7D7D7);
  cursor: pointer;
  
  & svg {
    width: 24px;
    height: 24px;
    fill: #1B51A4;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 16px 0 16px;
  gap: 16px;
  font-size: 13px;
`;

export const RowsPerPageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  margin-right: 0;
`;

export const CurrentPageInfo = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  margin: 0 16px;
`;

export const PaginationSelect = styled(Select)`
  .MuiInputBase-input {
    font-size: 13px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;


