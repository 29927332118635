import moment from 'moment'
import { convertClientToPartnerTimezone } from 'utils'
import { getLocationTimezone } from 'utils/dmUtils'

const getCurrentTime = (timezone?: string) => {
  const partnerTimezone = getLocationTimezone(timezone)
  const now = convertClientToPartnerTimezone(new Date().toISOString(), partnerTimezone)
  return now
}

// Get tomorrow's date in partner's timezone
const getTomorrowDate = (timezone?: string) => {
  const today = getCurrentTime(timezone)
  const tomorrow = moment(today).add(1, 'day').startOf('day').toDate()
  return tomorrow
}

export { getCurrentTime, getTomorrowDate }
