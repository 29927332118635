import { IconButton, ThemeProvider, Tooltip, createTheme } from '@mui/material'
import { HolidayHourResource, HolidayHourTimePeriod } from 'api/models'
import { deleteHolidayHours, getHolidayHours } from 'api/partners/holidayHours'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash-icon.svg'
import useGetLocationPartners from 'hooks/useGetLocationPartners'
import useRouteLocationId from 'hooks/useRouteLocationId'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { convertUTCToPartnerTimezone, getTzValueByKey } from 'utils/DateTime'

import AddHoursModal from './AddHoursModal/AddHoursModal'
import { getCurrentTime } from './helpers'
import {
  BasicHolidayHourConfig,
  Container,
  ItemActions,
  ItemContent,
  ItemDate,
  ItemTime,
  ItemWrapper,
  ItemsContainer,
  LocationPrimaryButton,
  Title,
} from './style'
import SuccessModal from './SuccessModal/SuccessModal'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1B51A4',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
})

type HolidayHourItemProps = {
  id: string
  date: string
  hours: string
  onDelete?: () => void
}

type HolidayHourWithTimePeriods = HolidayHourResource & {
  time_period?: HolidayHourTimePeriod
}

const HolidayHourItem = (props: HolidayHourItemProps) => {
  const isCurrentDate = moment(new Date(props.date)).isSame(getCurrentTime(), 'day')

  const handleDelete = () => {
    if (isCurrentDate) return
    props.onDelete?.()
  }

  return (
    <ItemWrapper>
      <ItemContent>
        <ItemDate>{props.date}</ItemDate>
        <ItemActions>
          <ItemTime>{props.hours}</ItemTime>
          <Tooltip
            title={isCurrentDate ? 'You cannot delete Holiday Hours on the same day' : ''}
            slotProps={{
              tooltip: {
                style: {
                  backgroundColor: '#FEFEFE',
                  color: '#292929',
                  fontSize: '11px',
                  fontWeight: 600,
                  fontFamily: 'Inter',
                  padding: '8px',
                  borderRadius: '10px',
                  boxShadow: '0px 4px 60px 0px rgba(0, 0, 0, 0.20)',
                },
              },
            }}
          >
            <IconButton
              color='error'
              data-testid='delete-icon'
              onClick={handleDelete}
              style={{
                ...(isCurrentDate && {
                  opacity: 0.5,
                  color: '#777777',
                }),
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ItemActions>
      </ItemContent>
    </ItemWrapper>
  )
}

/**
 * Converts a time string in the format of 'HH:MM' to a time string in the format of 'h:mmA'
 * Example: '14:30' -> '2:30PM'
 */
const formatTimeFromPeriod = (date: string, timeStr?: string) => {
  if (!timeStr) return '00:00'
  const timeParts = timeStr.split(':')
  return moment(date)
    .set('hour', parseInt(timeParts[0] || '0'))
    .set('minute', parseInt(timeParts[1] || '0'))
    .format('h:mmA')
}

const HolidayHours = () => {
  const partnerId = useRouteLocationId()
  const { partner } = useGetLocationPartners(partnerId)
  const [holidayHours, setHolidayHours] = useState<HolidayHourWithTimePeriods[]>([])
  const [showAddHoursModal, setShowAddHoursModal] = useState<boolean>(false)
  const [showSuccessModal, setShowSuccessModal] = useState<BasicHolidayHourConfig | null>(null)
  const partnerTZ = partner?.attributes.timezone || ''
  const timezone = getTzValueByKey(partnerTZ)
  // if partnerTimezone is null, use browser timezone.
  const partnerTimezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone

  const fetchHolidayHours = useCallback(async () => {
    try {
      if (!partnerId) return
      const response = await getHolidayHours(partnerId)
      // Add time periods if a relationship exists
      const datesWithPeriods = response.data.map((hour) => {
        if (hour.relationships.time_periods.data.length > 0) {
          const firstTimePeriodId = hour.relationships.time_periods.data[0].id
          const firstTimePeriod = response.included?.find(
            (period) => period.id === firstTimePeriodId,
          )
          return {
            ...hour,
            ...(firstTimePeriod && { time_period: firstTimePeriod.attributes }),
          }
        }

        return hour
      })

      // Sort by date
      datesWithPeriods.sort((a, b) =>
        moment(a.attributes.date).isBefore(moment(b.attributes.date)) ? -1 : 1,
      )
      setHolidayHours(datesWithPeriods)
    } catch (error) {
      console.error('Failed to fetch holiday hours:', error)
    }
  }, [partnerId])

  const handleHourAdded = useCallback(
    (hour: BasicHolidayHourConfig) => {
      setShowSuccessModal(hour)
      // Reload holiday hours
      fetchHolidayHours()
    },
    [setShowSuccessModal, fetchHolidayHours],
  )

  const handleDelete = async (id: string) => {
    try {
      if (!partnerId) return
      await deleteHolidayHours(partnerId, id)
      await fetchHolidayHours()
    } catch (error) {
      console.error('Failed to delete holiday hours:', error)
    }
  }

  // Check if a holiday hour exists for a given date
  const checkHolidayHourExists = useCallback(
    (date: Date) => {
      return holidayHours.some((hour) =>
        moment(convertUTCToPartnerTimezone(hour.attributes.date, partnerTimezone)).isSame(date, 'day'),
      )
    },
    [holidayHours, partnerTimezone],
  )

  const handleShowAddHoursModal = () => {
    setShowAddHoursModal(true)
  }

  // Transform API data to match the existing HolidayHour type
  const transformedHours: HolidayHourItemProps[] = holidayHours.map((hour) => ({
    id: hour.id,
    date: moment(convertUTCToPartnerTimezone(hour.attributes.date, partnerTimezone)).format(
      'MM/DD/YYYY',
    ),
    hours: hour.attributes.closed
      ? 'Closed'
      : `${formatTimeFromPeriod(
          hour.attributes.date,
          hour.time_period?.start_time,
        )} - ${formatTimeFromPeriod(hour.attributes.date, hour.time_period?.end_time)}`,
  }))

  useEffect(() => {
    if (!partnerId) return
    fetchHolidayHours()
  }, [partnerId, fetchHolidayHours])

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Title>
          Holiday Hours
          <LocationPrimaryButton onClick={handleShowAddHoursModal}>Add Hours</LocationPrimaryButton>
        </Title>
        <ItemsContainer>
          {transformedHours.map((hour) => (
            <HolidayHourItem
              key={hour.id}
              {...hour}
              onDelete={() => handleDelete(hour.id.toString())}
            />
          ))}
        </ItemsContainer>

        <SuccessModal holiday={showSuccessModal} onClose={() => setShowSuccessModal(null)} />

        {partner && (
          <AddHoursModal
            open={showAddHoursModal}
            onClose={() => setShowAddHoursModal(false)}
            partner={partner}
            onSave={handleHourAdded}
            onCheckHolidayHourExists={checkHolidayHourExists}
          />
        )}
      </Container>
    </ThemeProvider>
  )
}

export default HolidayHours
