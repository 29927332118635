import { Availabilities } from 'api/menuMapping/types'
import MinusIcon from 'assets/icons/outline_minus_button.svg'
import PlusIcon from 'assets/icons/outline_plus_button.svg'
import moment, { Moment } from 'moment'
import { useIsMobile } from 'hooks'
import TimePicker from 'rc-time-picker'
import { Fragment } from 'react'
import { FormattedAvailabilities } from 'types/Menu'
import { DAYS_OF_WEEK } from 'utils'
import { convertMomentToStringTimePeriod, convertTimePeriodToMoment } from 'utils/DateTime'

import * as Styled from '../styles'

type Props = {
  availabilities?: Availabilities[]
  newAvailabilities: FormattedAvailabilities
  setNewAvailabilities: (newData: FormattedAvailabilities) => void
  isModal?: boolean
  availabilitiesToDelete?: Availabilities['id'][]
  setAvailabilitiesToDelete?: (ids: Availabilities['id'][]) => void
}

const isError = (startValue?: string, endValue?: string) => {
  if ((!startValue && endValue) || (startValue && !endValue)) {
    return true
  }

  if (startValue && endValue) {
    return startValue >= endValue
  }

  return false
}

const TIME_TYPE = {
  START: 'start',
  END: 'end',
}

const MenuAvailabilityTable = ({
  newAvailabilities,
  setNewAvailabilities,
  isModal,
  availabilitiesToDelete,
  setAvailabilitiesToDelete,
}: Props) => {
  const { isMobile } = useIsMobile()
  const handleChangeTime = async (
    newValue: Moment,
    day: string,
    timePeriodIdx: number,
    type: string,
  ) => {
    const modifiedAvailabilityObj = { ...newAvailabilities }

    if (!modifiedAvailabilityObj[day]) {
      modifiedAvailabilityObj[day] = []
    }

    const currentTimePeriodValues = modifiedAvailabilityObj[day]?.[timePeriodIdx]

    // clear both start and end times for the period
    if (currentTimePeriodValues && !newValue) {
      currentTimePeriodValues.start_time = undefined
      currentTimePeriodValues.end_time = undefined

      // if this exists in the db, stage this time period id for deletion
      if (currentTimePeriodValues.id && availabilitiesToDelete && setAvailabilitiesToDelete) {
        setAvailabilitiesToDelete([
          ...new Set([...availabilitiesToDelete, currentTimePeriodValues.id]),
        ])
      }
    }

    const convertedTimeStr = convertMomentToStringTimePeriod(newValue)

    if (type === TIME_TYPE.START) {
      // update current value
      if (currentTimePeriodValues) {
        currentTimePeriodValues.start_time = convertedTimeStr
      } else {
        // add new time period for the day
        modifiedAvailabilityObj[day][timePeriodIdx] = {
          start_time: convertedTimeStr,
        }
      }
    }
    if (type === TIME_TYPE.END) {
      // update current value
      if (currentTimePeriodValues) {
        currentTimePeriodValues.end_time = convertedTimeStr
      } else {
        // add new time period for the day
        modifiedAvailabilityObj[day][timePeriodIdx] = {
          end_time: convertedTimeStr,
        }
      }
    }

    setNewAvailabilities(modifiedAvailabilityObj)
  }

  const addNewTimePeriodRow = (dayOfWeek: string) => {
    const updatedAvailabilities = { ...newAvailabilities }

    const currTimePeriods = updatedAvailabilities[dayOfWeek]
    // create empty time periods
    currTimePeriods.push({
      start_time: undefined,
      end_time: undefined,
    })

    updatedAvailabilities[dayOfWeek] = currTimePeriods
    setNewAvailabilities(updatedAvailabilities)
  }

  const removeTimePeriodRow = async (dayOfWeek: string, timePeriodIndex: number) => {
    if (timePeriodIndex === 0) {
      console.error('Cannot remove the first time period')
      return
    }

    const updatedAvailabilities = { ...newAvailabilities }
    const currTimePeriods = updatedAvailabilities[dayOfWeek]
    const existingTimePeriodId = currTimePeriods[timePeriodIndex]?.id

    if (existingTimePeriodId && availabilitiesToDelete && setAvailabilitiesToDelete) {
      setAvailabilitiesToDelete([...new Set([...availabilitiesToDelete, existingTimePeriodId])])
    }

    currTimePeriods.splice(timePeriodIndex, 1)
    updatedAvailabilities[dayOfWeek] = currTimePeriods
    setNewAvailabilities(updatedAvailabilities)
  }

  return (
    <Styled.MenuAvailabilityTableCtn isModal={isModal}>
      {DAYS_OF_WEEK.map((day) => {
        const firstTimePeriod = newAvailabilities[day]?.[0]
        const otherTimePeriods = newAvailabilities[day]?.slice(1)

        const shortDay = moment(day, 'dddd').format('ddd')

        return (
          <Fragment key={day}>
            <Styled.MenuAvailabilityTableRowContainer key={day}>
              <Styled.MenuAvailabilityTableDayContainer>{isMobile ? shortDay : day}</Styled.MenuAvailabilityTableDayContainer>
              <Styled.MenuAvailabilityTableTimePickerContainer>
                <TimePicker
                  value={convertTimePeriodToMoment(firstTimePeriod?.start_time)}
                  onChange={(newValue) => handleChangeTime(newValue, day, 0, TIME_TYPE.START)}
                  showSecond={false}
                  placeholder='Select'
                  className={isMobile ? 'rc-short-time-picker' : 'rc-time-picker'}
                  use12Hours
                />
                <Styled.MenuAvailabilityTableSeparatorContainer>
                  <Styled.LineSeparator />
                </Styled.MenuAvailabilityTableSeparatorContainer>
                <TimePicker
                  value={convertTimePeriodToMoment(firstTimePeriod?.end_time)}
                  onChange={(newValue) => handleChangeTime(newValue, day, 0, TIME_TYPE.END)}
                  showSecond={false}
                  placeholder='Select'
                  className={isMobile ? 'rc-short-time-picker' : 'rc-time-picker'}
                  use12Hours
                />
              </Styled.MenuAvailabilityTableTimePickerContainer>
              <Styled.IconButton
                type='button'
                onClick={() => addNewTimePeriodRow(day)}
              >
                <img src={PlusIcon} alt='expand_rows' />
              </Styled.IconButton>
            </Styled.MenuAvailabilityTableRowContainer>
            {isError(firstTimePeriod?.start_time, firstTimePeriod?.end_time) && (
              <Styled.MenuAvailabilityTableErrorText>
                Error: the end time has to be greater than the start time.
              </Styled.MenuAvailabilityTableErrorText>
            )}
        
            {otherTimePeriods?.map((timePeriod, idx) => {
              const timePeriodIdx = idx + 1;
        
              return (
                <Fragment key={`${day}-${timePeriodIdx}`}>
                  <Styled.MenuAvailabilityTableRowContainer>
                    <Styled.MenuAvailabilityTableDayContainer />
                    <Styled.MenuAvailabilityTableTimePickerContainer>
                      <TimePicker
                        value={convertTimePeriodToMoment(timePeriod?.start_time)}
                        onChange={(newValue) =>
                          handleChangeTime(newValue, day, timePeriodIdx, TIME_TYPE.START)
                        }
                        showSecond={false}
                        placeholder='Select'
                        className={isMobile ? 'rc-short-time-picker' : 'rc-time-picker'}
                        use12Hours
                      />
                      <Styled.MenuAvailabilityTableSeparatorContainer>
                        <Styled.LineSeparator />
                      </Styled.MenuAvailabilityTableSeparatorContainer>
                      <TimePicker
                        value={convertTimePeriodToMoment(timePeriod?.end_time)}
                        onChange={(newValue) =>
                          handleChangeTime(newValue, day, timePeriodIdx, TIME_TYPE.END)
                        }
                        showSecond={false}
                        placeholder='Select'
                        className={isMobile ? 'rc-short-time-picker' : 'rc-time-picker'}
                        use12Hours
                      />
                    </Styled.MenuAvailabilityTableTimePickerContainer>
                    <Styled.MenuAvailabilityTableIconButtonContainer>
                      <Styled.IconButton
                        type='button'
                        onClick={() => removeTimePeriodRow(day, timePeriodIdx)}
                      >
                        <img src={MinusIcon} alt='remove_row' />
                      </Styled.IconButton>
                    </Styled.MenuAvailabilityTableIconButtonContainer>
                  </Styled.MenuAvailabilityTableRowContainer>
                  {isError(timePeriod?.start_time, timePeriod?.end_time) && (
                    <Styled.MenuAvailabilityTableErrorText>
                      Error: the end time has to be greater than the start time.
                    </Styled.MenuAvailabilityTableErrorText>
                  )}
                  {timePeriodIdx === otherTimePeriods.length && (
                    <Styled.MenuAvailabilityTableLineSeparatorContainer>
                      <Styled.LineSeparator />
                    </Styled.MenuAvailabilityTableLineSeparatorContainer>
                  )}
                </Fragment>
              )
            })}
          </Fragment>
        )}
      )}
    </Styled.MenuAvailabilityTableCtn>
  )
}

export default MenuAvailabilityTable