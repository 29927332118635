import { MenuMappingSectionResource } from 'api'
import { getMenuMappingSections } from 'api/menuMapping/menus'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const useGetMenuMappingSections = (menuMappingMenuId?: string) => {
  const [menuMappingSections, setMenuMappingSections] = useState<MenuMappingSectionResource[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const fetch = useCallback(async () => {
    if (!menuMappingMenuId) {
      return
    }

    setIsLoading(true)

    let response: MenuMappingSectionResource[] | undefined
    try {
      response = await getMenuMappingSections(menuMappingMenuId)

      setMenuMappingSections(response)
      setIsLoading(false)
      setIsError(false)
      setIsReady(true)
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        toast.error(err?.response?.data?.description)
      } else {
        toast.error('Error occured. Please contact support or try again later.')
      }
      setIsError(true)
      setIsReady(true)
    }

    if (!response && !menuMappingSections) {
      setIsError(true)
      setIsReady(true)
    }
  }, [menuMappingMenuId])

  useEffect(() => {
    if (!menuMappingMenuId) {
      setMenuMappingSections(undefined)
    }
    fetch()
  }, [menuMappingMenuId])

  return {
    menuMappingSections,
    isLoading,
    isError,
    isReady,
    refetch: fetch,
  }
}

export default useGetMenuMappingSections
