import styled from 'styled-components'
import { Button } from 'components'
import { breakpoints, colors, fontFamily, fontWeight, typography } from 'theme'

export const SelectedAvailabilityRow = styled.div<{ active?: boolean }>(
  {
    display: 'flex',
    padding: '8px 5px 8px 5px',
    alignItems: 'center',
    gap: '8px',
    borderRadius: 0,
    width: 'fit-content',
    whiteSpace: 'nowrap',
  },
  ({ active }) =>
    active && {
      borderColor: colors.blue['700'],
    },
)

const mediumText = {
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: '13px',
  fontStyle: 'normal',
}

export const AvailableDay = styled.div<{ isHighlighted?: boolean }>(({ isHighlighted }) => ({
  ...mediumText,
  fontWeight: isHighlighted ? fontWeight.bold : fontWeight.medium,
  lineHeight: '19.5px',
}))

export const AvailableTimeContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  alignItems: 'flex-end',
})

export const AvailableTime = styled.div<{ isHighlighted?: boolean }>(({ isHighlighted }) => ({
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: typography.xxs.fontSize,
  fontStyle: 'normal',
  fontWeight: isHighlighted ? fontWeight.bold : fontWeight.normal,
  lineHeight: '19.5px',
}))

export const DropdownItem = styled.div({
  padding: 0,
  textAlign: 'left',
  background: 'transparent',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-end',
})

export const AvailabilityRow = styled.div<{ active?: boolean }>(
  {
    display: 'flex',
    padding: '8px 20px',
    alignItems: 'baseline',
    gap: '8px',
    borderRadius: 0,
    width: 'fit-content',
  },
  ({ active }) =>
    active && {
      borderColor: colors.blue['700'],
    },
)

export const AvailabilityContainer = styled.div({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  alignItems: 'flex-end',
  textAlign: 'right',
})

export const DropdownContainer = styled.div({
  position: 'relative',
  width: 'max-content',
})

export const DropdownButton = styled.button({
  color: 'white',
  fontSize: '16px',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

export const DropdownContent = styled.div<{ isLocationHours?: boolean }>(
  ({ isLocationHours }) => ({
    position: 'absolute',
    backgroundColor: 'rgb(249, 249, 249)',
    minWidth: '250px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 0,
    top: '36px',
    borderRadius: 5,
    border: '1px solid rgb(217 217 217)',
    ...(isLocationHours ? { left: 0 } : { right: 0 }),
  })
)

export const AvailabilityHeader = styled.div({
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: typography.midXS.fontSize,
  fontStyle: 'normal',
  fontWeight: fontWeight.bold,
  lineHeight: '22.5px',
  margin: '0 5px 0 0',
})

export const TodaysHours = styled.span({
  display: 'inline',
  maxWidth: '200px',
  [`@media ${breakpoints.xxs}`]: {
    display: 'none',
  },
})

export const Chevron = styled.img<{ isOpen: boolean }>(({ isOpen }) => ({
  transition: 'transform 0.3s ease',
  transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
}))

export const EditButton = styled(Button)`
  margin: 10px;  
`