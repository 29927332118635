import { Availabilities } from 'api/menuMapping/types'
import Modal from 'features/modal/Modal'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FormattedAvailabilities } from 'types/Menu'
import { DAYS_OF_WEEK } from 'utils'
import { Button } from 'components'
import * as Styled from '../styles'
import updateAvailabilitiesForMenuSection from '../util/updateAvailabilitiesForMenuSection'
import { LocationHourUpsertInput } from 'api/models'
import { upsertLocationHours } from 'api/partners/locationHours'
import validateAvailabilities from '../util/validateAvailabilities'
import MenuAvailabilityTable from './MenuAvailabilityTable'

type Props = {
  isOpen: boolean
  onClose: () => void
  availabilities: Availabilities[]
  type: 'location_hour' | 'menu_hour'
  refetchData: () => void
  menuSectionId?: string
  partnerId?: string
}
const MenuAvailabilityModal = ({
  isOpen,
  onClose,
  availabilities,
  menuSectionId,
  refetchData,
  type,
  partnerId,
}: Props) => {
  const [newAvailabilities, setNewAvailabilities] = useState<FormattedAvailabilities>({})
  const [availabilitiesToDelete, setAvailabilitiesToDelete] = useState<Availabilities['id'][] | []>(
    [],
  )

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)

  const title = {
    menu_hour: 'Menu Availability',
    location_hour: 'Location Hours',
  }

  useEffect(() => {
    const formattedAvailabilities: FormattedAvailabilities = {}

    // Prefill availabilities object with existing availabilities
    DAYS_OF_WEEK.forEach((day) => {
      const existingAvailability = availabilities.find((avail) => avail.dayOfWeek === day)

      if (existingAvailability?.enabled) {
        formattedAvailabilities[existingAvailability.dayOfWeek] =
          existingAvailability.timePeriods.map((timePeriod) => ({
            id: timePeriod.id,
            start_time: timePeriod.attributes.start_time,
            end_time: timePeriod.attributes.end_time,
          }))
      } else {
        // if we are missing time periods for this day, prefill empty time periods to display options anyways
        formattedAvailabilities[day] = []
      }
    })

    setNewAvailabilities(formattedAvailabilities)
  }, [availabilities])

  const transformAvailabilitiesToLocationHours = (
    availabilities: FormattedAvailabilities
  ): LocationHourUpsertInput => {
    const daysOfWeek = Object.keys(availabilities)
  
    const data = daysOfWeek.map((day) => ({
      type: "location_hour" as const,
      attributes: {
        day_of_week: day.toLowerCase(),
        enabled: availabilities[day].length > 0,
        time_periods: availabilities[day]
          .filter((period) => period.start_time && period.end_time)
          .map((period) => ({
            start_time: period.start_time as string,
            end_time: period.end_time as string,
          })),
      },
    }))
  
    return { data };
  }  

  const saveNewAvailabilities = async () => {
    setSaveButtonDisabled(true)

    let result
    let message
    if (type === 'menu_hour' && menuSectionId) {
      // Persist all the days and time periods of a menu section
      result = await updateAvailabilitiesForMenuSection(
        availabilities,
        newAvailabilities,
        menuSectionId,
        availabilitiesToDelete,
      )
      message = 'Success! Your availability has been updated.'
    }

    if (type === 'location_hour' && partnerId) {
        console.log("payload", transformAvailabilitiesToLocationHours(newAvailabilities))
        result = await upsertLocationHours(
          partnerId,
          transformAvailabilitiesToLocationHours(newAvailabilities),
        )
        message = 'Success! Hours have been updated.'
    }

    if (result) {
      toast.success(message, { autoClose: 5000 })
      refetchData()
      setSaveButtonDisabled(false)
      // close the modal
      onClose()
    }
  }

  const timePeriodsError = validateAvailabilities(newAvailabilities) === false

  if (!isOpen) {
    return null
  } else {
    return (
      <Modal width='600px' height='auto' isOpen={isOpen} onClose={onClose}>
        <Styled.MenuAvailabilityWrapper>
          <Styled.MenuAvailabilityTitle>{title[type]}</Styled.MenuAvailabilityTitle>
          <Styled.MenuAvailabilityTableWrapper>
            <MenuAvailabilityTable
              availabilities={availabilities}
              newAvailabilities={newAvailabilities}
              setNewAvailabilities={setNewAvailabilities}
              isModal={true}
              availabilitiesToDelete={availabilitiesToDelete}
              setAvailabilitiesToDelete={setAvailabilitiesToDelete}
            />
          </Styled.MenuAvailabilityTableWrapper>
          <Styled.MenuAvailabilityDialogActions>
            {timePeriodsError && (
              <Styled.TopRowText color='red' textAlign='end'>
                Please fix the errors above before proceeding.
              </Styled.TopRowText>
            )}
            <Button 
              onClick={onClose} 
              size='small'
              variant='textonly'
              narrow
            >
              Cancel
            </Button>
            <Button 
              onClick={saveNewAvailabilities} 
              disabled={timePeriodsError || saveButtonDisabled}
              size='small'
              narrow
            >
              Save
            </Button>
          </Styled.MenuAvailabilityDialogActions>
        </Styled.MenuAvailabilityWrapper>
      </Modal>
    )
  }
}

export default MenuAvailabilityModal
