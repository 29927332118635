export type MarketingChannelType = 'facebook' | 'google' | 'tiktok' | 'mailchimp' | 'googleBusinessProfile';
export type MarketingChannelSubType = 'location' | 'branded';
export type MailchimpIntegration = {
  apiKey: string;
}

export const enum MarketingChannelTypes {
  MailChimp = 'mailchimp',
  Google = 'google',
  Facebook = 'facebook',
  Tiktok = 'tiktok',
  Paperform = 'paperform',
  GoogleBusinessProfile = 'googleBusinessProfile',
}

export type GoogleMccDetails = {
  /** the MCC account id */
  accountId: string;
}

export interface MarketingChannelObject {
  id: string;
  businessId: string;
  name: string;
  type: MarketingChannelType;
  subType?: MarketingChannelSubType;

  // Mailchimp
  /** If provided, it will replace mailchimpAccessToken & mailchimpSever */
  mailchimpApiKey?: string;
  mailchimpAccessToken?: string;
  mailchimpServer?: string;
  /** 
   * if provided it will be used as the API's request 
   **/
  googleMccDetails?: GoogleMccDetails;
  // Google
  googleClientAccountId?: string;
  googleClientCenterId?: string;
  /** Identifies this MC as automatically generated via a auth0 process */
  googleClientOAuth?: {
    /** use it to generate an access token */
    clientRefreshToken: string;
    /** list of client accounts the user token has access to */
    clientAccounts: {
      name: string;
      id: string;
    }[];
  };
  // Tiktok
  tiktokBusinessAccountId?: string;
  tiktokPersonalAccountId?: string;
  // Facebook
  /**
   * The DDB userId of the admin member with a valid Facebook's token
   */
  userId?: string;
  fbBusinessManagerId?: string;
  fbAdAccountId?: string;
  fbAdAccountName?: string;
  fbPageId?: string;
  fbPageName?: string;
  fbAvatar?: string;
  fbPixelId?: string;
  fbVerifiedDomain?: string;
  // Instagram
  instagramAccountId?: string;
  instagramUsername?: string;
  isTestAccount?: boolean;
  /** Date in ISO format when the marketing channel was deleted */
  deletedOn?: string;
  /** [Paperform](https://paperform.co/) platform configuration */
  paperform?: {
    /** paperform space id */
    spaceId: string;
  }
  googleBusinessProfile?: {
    locationId: string;
    accountId: string;
    refreshToken: string;
  };
}
