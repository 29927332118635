import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import { marketingApi } from 'config'
import { getEmailMarketingCampaigns } from 'api/digitalMarketing'
import { Toast } from 'components'
import DigitalMarketingDetailsDrawer from 'components/DigitalMarketingDetailsDrawer/DigitalMarketingDetailsDrawer'
import { useAlgoliaMultiIndexSearch, useGetLocationPartners } from 'hooks'
import useSafeAsync from 'hooks/useSafeAsync'
import React, { useCallback, useEffect, useState } from 'react'
import { EmailMarketingCampaign, OptimizedCampaign, TargetableInsight } from 'types/digitalMarketing'
import { ToDoItem } from 'types/digitalMarketing/common'
import { GenericAd } from 'types/digitalMarketing/googleAd'
import { getPrefixedIndexName } from 'utils/algolia'
import { shouldDisplayCampaign, shouldDisplayGoogleAd, withTodoAsMetaAd, isEmailMarketingAd } from 'utils/dmUtils'

import { CampaignItem, NoData, Skeleton, getToDoContentType } from './components'
import FeedbackModal from './FeedbackModal/FeedbackModal'
import { TableHeaderCell, ToDoTable, TodoContainer } from './styles'

// NOTE: Consider moving this to the App root level.
const theme = createTheme({
  palette: {
    primary: {
      main: '#1B51A4',
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
})

const insightsIndex = getPrefixedIndexName('Insights')
const suggestedCampaignsIndex = getPrefixedIndexName('SuggestedCampaigns')
const googleAdsIndex = getPrefixedIndexName('AdCampaigns')

type FeedbackModal = {
  todoItem: ToDoItem | null
  mode: 'approve' | 'reject'
}

type Props = {
  partnerId?: string
}

const getAdDate = (item: ToDoItem) => {
  if (typeof (item as GenericAd).createdAt === 'string') {
    return new Date((item as GenericAd).createdAt).getTime()
  }

  return (item as TargetableInsight).created
}

const ToDo = ({ partnerId }: Props) => {
  const [isSearching, setIsSearching] = useState(false)
  const [toDos, setToDos] = useState<ToDoItem[]>([])
  const multiSearch = useAlgoliaMultiIndexSearch(partnerId as string)
  const { partner } = useGetLocationPartners()
  const organizationId = partner?.attributes?.organization_id
  const partnerTz = partner?.attributes?.timezone || ''

  /**
   * Since Algolia syncing is async, we need to keep track of already actioned items
   * to hide them from the list immediately.
   */
  const [alreadyProcessed, setAlreadyProcessed] = useState<string[]>([])

  const [feedbackModal, setFeedbackModal] = useState<FeedbackModal>({
    todoItem: null,
    mode: 'approve',
  })

  const [selectedItem, setSelectedItem] = useState<ToDoItem | null>(null)

  const handleDrawerClose = () => {
    setSelectedItem(null)
  }

  const safeAsync = useSafeAsync()

  const handleCloseModal = useCallback(() => {
    setFeedbackModal({ todoItem: null, mode: 'approve' })
  }, [])

  const handleAction = useCallback((item: ToDoItem, approved: boolean) => {
    setFeedbackModal({ todoItem: item, mode: approved ? 'approve' : 'reject' })
  }, [])

  const handleModalSubmit = useCallback(
    (item: ToDoItem, approved: boolean) => {
      const message = approved
        ? `${getToDoContentType(item)} has been approved and will publish soon.`
        : `${getToDoContentType(item)} has been rejected.`

      handleCloseModal()

      Toast.success({ message }, { autoClose: 5000, hideProgressBar: true })
      setAlreadyProcessed((prev) => [...prev, item.id])
    },
    [handleCloseModal],
  )

  const handleEmailMarketingShowItem = useCallback((item: EmailMarketingCampaign) => {
    const baseUrl = marketingApi.hostUrl;
    let url = `${baseUrl}/campaigns/${item.id}`;
   
    const editStatuses = ["draft", "pending_approval"];
  
    if (editStatuses.includes(item.aasm_state)) {
      url += `/edit`;
    }

    window.open(url, '_blank')
  }, []);

  const handleOnShowItem = useCallback((item: ToDoItem) => {
    if (isEmailMarketingAd(item)) {
      handleEmailMarketingShowItem(item as EmailMarketingCampaign) 
    } else {
      setSelectedItem(item)
    }
  }, [handleEmailMarketingShowItem])

  const fetchCampaigns = useCallback(async () => {
    if (!partnerId) return

    const inSmartFeed = `status:smartFeed`
    const isPublishedFilter = `status:published`
    const notDeletedFilter = `NOT deletedOn:*`
    const noGoogleGroupAdsFilter = `NOT group:*`

    const commonParams = {
      filters: `${notDeletedFilter}`,
      facetFilters: [`chowlyPartnerId:${partnerId}`],
    }

    const filterBy = (filters: string[]) => filters.join(' AND ')

    // Fetch multiple indexes at once
    const [{ hits: insights }, { hits: suggestedCampaigns }, { hits: googleAds }] =
      await multiSearch<[TargetableInsight, OptimizedCampaign, GenericAd]>([
        {
          indexName: insightsIndex,
          params: {
            ...commonParams,
            filters: filterBy([commonParams.filters, isPublishedFilter]),
          },
        },
        {
          indexName: suggestedCampaignsIndex,
          params: {
            ...commonParams,
            filters: filterBy([commonParams.filters, isPublishedFilter]),
          },
        },
        {
          indexName: googleAdsIndex,
          params: {
            ...commonParams,
            filters: filterBy([commonParams.filters, inSmartFeed, noGoogleGroupAdsFilter]),
          },
        },
      ])

    const combined = [...insights, ...suggestedCampaigns, ...googleAds].sort(
      (a, b) => getAdDate(b) - getAdDate(a),
    )

    // Filter out already processed items and multi-store campaigns
    const cleanResults = combined.filter((item) => {
      // Filter out already processed items
      const processed = alreadyProcessed.includes(item.id)
      const fbCampaign = withTodoAsMetaAd(item)

      // Filter out multi-store campaigns
      const isMultiStore = (fbCampaign?.ads?.length || 0) > 1
      const displayFbOrInsight = shouldDisplayCampaign(item, partnerTz)
      const displayGoogleAd = shouldDisplayGoogleAd(item, partnerTz)
      return !processed && !isMultiStore && displayFbOrInsight && displayGoogleAd
    })

    // Email Marketing
    let emailMarketingCampaigns: EmailMarketingCampaign[] = []
    if (organizationId) {
      try {
        emailMarketingCampaigns = await getEmailMarketingCampaigns(organizationId, 'pending_approval')
      } catch (err) {
        console.error(err)
        emailMarketingCampaigns = []
      }
    }
    setToDos([...cleanResults, ...emailMarketingCampaigns]);
  }, [multiSearch, partnerId, organizationId, alreadyProcessed, partnerTz])

  // Fetch campaigns when partnerId is updated
  useEffect(() => {
    if (!partnerId) {
      return
    }
    setIsSearching(true)
    safeAsync(fetchCampaigns()).catch((e) => {
      console.error(e)
    })
    setIsSearching(false)
  }, [partnerId, safeAsync, fetchCampaigns])

  return (
    <ThemeProvider theme={theme}>
      <FeedbackModal
        todoItem={feedbackModal.todoItem}
        mode={feedbackModal.mode}
        onClose={handleCloseModal}
        onSubmitted={handleModalSubmit}
        partnerId={partnerId}
      />
      <TodoContainer>
        <ToDoTable>
          {toDos.length > 0 ? <TableHeaderCell>Name</TableHeaderCell> : null}
          <div data-cy='table-body'>
            {isSearching && partnerId && <Skeleton />}
            {(!partnerId || toDos.length === 0) && <NoData />}

            {toDos.map((todo) => {
              return (
                <CampaignItem
                  key={todo.id}
                  item={todo}
                  onAction={handleAction}
                  onClick={handleOnShowItem}
                />
              )
            })}
          </div>
        </ToDoTable>
      </TodoContainer>
      {selectedItem && partnerId && (
        <DigitalMarketingDetailsDrawer
          open
          item={selectedItem}
          partnerId={partnerId}
          onClose={handleDrawerClose}
          onAction={handleAction}
        />
      )}
    </ThemeProvider>
  )
}

export default ToDo
