import { FormattedMenuSectionInfo, MenuMappingSectionResource } from 'api'
import backArrow from 'assets/icons/arrow-back.svg'
import { Select } from 'components'
import type { Option } from 'components/Select'
import { useEffect, useState } from 'react'
import { colors, fontFamily } from 'theme'
import AvailabilitySelector from 'components/AvailabilitySelector/AvailabilitySelector'
import { Availabilities } from 'api/menuMapping/types'

import * as Styled from './styles'

type Props = {
  menuSectionData?: FormattedMenuSectionInfo
  is86PreviewOnly: boolean
  onBackClick: () => void
  menuSections?: MenuMappingSectionResource[]
  setSelectedMenuSectionId: (id: string) => void
  selectedMenuSectionId?: string
  setIsMenuAvailabilityModalOpen: (status: boolean) => void
  isMenuEditor: boolean
  isPrinterClient?: boolean
}

const selectStyles = {
  container: {
    width: 231,
    height: 47,
  },
  control: {
    width: 231,
    height: 47,
    backgroundColor: colors.white,
    borderColor: colors.gray.medium,
  },
  menu: {
    width: 231,
  },
  valueContainer: {
    color: colors.primary.medium,
    fontFamily: fontFamily.inter,
    fontWeight: 'regular',
    fontSize: 15,
    lineHeight: '150%',
  },
  singleValue: {
    color: colors.primary.medium,
    fontFamily: fontFamily.inter,
    fontWeight: 'regular',
    fontSize: 15,
    lineHeight: '150%',
  },
}

const MenuHeader = ({
  menuSectionData,
  is86PreviewOnly,
  onBackClick,
  menuSections,
  setSelectedMenuSectionId,
  selectedMenuSectionId,
  setIsMenuAvailabilityModalOpen,
  isMenuEditor,
}: Props) => {
  const [menuSectionOptions, setMenuSectionOptions] = useState<Option[]>([])
  
  const availabilities: Availabilities[] = menuSectionData?.attributes?.availabilities ?? []

  useEffect(() => {
    if (menuSections?.length) {
      const options = menuSections.map((section) => ({
        value: section.id,
        label: section.attributes.title,
      }))

      setMenuSectionOptions(options)
    } else {
      setMenuSectionOptions([])
    }
  }, [menuSections])

  if (is86PreviewOnly) {
    return (
      <>
        <Styled.Subtitle>
          <Styled.BackButton onClick={onBackClick}>
            <img src={backArrow} alt='back icon' width='24px' height='24px' />
            Continue Editing
          </Styled.BackButton>
        </Styled.Subtitle>
        <Styled.HeaderTitle>Publish Menu with Updated Item Availability</Styled.HeaderTitle>
        <Styled.HeaderText>
          Updating item availability requires the menu to publish to all platforms. Please confirm
          the preview of your menu below and click "Publish Menu" to proceed. Publishing the menu
          may take a few minutes.
        </Styled.HeaderText>
      </>
    )
  } else {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Styled.Subtitle>
            <Styled.BackButton onClick={() => history.back()}>
              Menu {isMenuEditor ? 'Editor' : 'Preview'}
            </Styled.BackButton>
          </Styled.Subtitle>
          <AvailabilitySelector
            availabilities={availabilities}
            editButtonLabel="Edit Availability"
            selectorLabel="Hours"
            onEdit={() => setIsMenuAvailabilityModalOpen(true)}
          />
        </div>
        <Styled.Header>
          {/* {menuSectionData ? (
            <Styled.Title>{menuSectionData?.attributes.title}</Styled.Title>
          ) : (
            <LoadingSkeleton isFlex />
          )} */}
          <Select
            options={menuSectionOptions}
            name='Menu Name (required)'
            value={
              menuSectionOptions.find((option) => option.value === selectedMenuSectionId) ?? {
                value: '',
                label: '',
              }
            }
            onChange={(option) => {
              setSelectedMenuSectionId(option)
            }}
            styles={selectStyles}
          />
        </Styled.Header>
      </>
    )
  }
}

export default MenuHeader
