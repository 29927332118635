import { generatePath } from 'react-router'
import { chowlyApi } from 'config'
import client from '../chowly'
import {
  LocationHourUpsertInput, 
  LocationHourResponse, 
  LocationHoursListResponse,
} from '../models'

// GET /api/v3/partners/:partner_id/location_hours
export const getLocationHours = async (partnerId: string) => {
  const url = generatePath(chowlyApi.partnerLocationHours, { partnerId })
  const { data } = await client.get<LocationHoursListResponse>(url)
  return data
}

// POST /api/v3/partners/:partner_id/location_hours
export const upsertLocationHours = async (
  partnerId: string,
  payload: LocationHourUpsertInput
) => {
  const url = generatePath(chowlyApi.partnerLocationHours, { partnerId })
  const { data } = await client.post<LocationHourResponse>(url, payload)
  return data
}