import styled from 'styled-components'
import { colors, fontFamily, fontWeight, typography } from 'theme'
import { Section } from '../../features/manage-locations/IndividualLocation/styles'
import Tippy from '@tippyjs/react'

export const OrderThrottleContainer = styled(Section)({
    // rowGap: '12px'
    width: '600px'
})

export const OrderThrottleToggleHeader = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
})

export const OrderThrottleHeader = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
})

export const OrderThrottleSubHeader = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
})

export const ThrottleTable = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    lineHeight: '18px',
    fontFamily: fontFamily.inter,
    fontSize: typography.xs.fontSize,
    fontWeight: fontWeight.normal,
})

export const AlertCircle = styled.img({
    width: 12.5,
    height: 12.5,
    marginTop: 6.25,
    opacity: 0.5,
    transition: 'opacity 0.3s ease',
    '&:hover': {
        opacity: 1,
    }
})

export const InfoToolTip = styled(Tippy)({
    backgroundColor: '#FEFEFE',
    color: '#292929',
    borderRadius: 10,
    fontSize: 11,
    fontWeight: 600,
    padding: 8,
    boxShadow: '0px 4px 60px 0px rgba(0, 0, 0, 0.20)',
    '.tippy-arrow': {
      display: 'none'
    },
})

export const ThrottleRow = styled.div({
    display: 'flex',
    gap: '16px',
    color: colors.gray.mediumLight,

})

export const ThrottleTableLabel = styled.span({
    fontFamily: fontFamily.inter,
    fontSize: typography.semiXS.fontSize,
    fontWeight: fontWeight.bold,
})

export const EditButton = styled.div({
    display: 'flex',
    justifyContent: 'end',
    fontSize: typography.semiXS.fontSize,
    fontWeight: fontWeight.bold,
})

export const EditorHeader = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
})

export const Title = styled.div({
    fontFamily: fontFamily.inter,
    fontSize: '20px',
    fontWeight: fontWeight.semibold,
    lineHeight: '28px',
    textAlign: 'left',
    width: '536px',
    height: '28px',


})

export const EditorDescription = styled.div({
    display: 'flex',
    height: '40px',
    fontFamily: fontFamily.inter,
    fontSize: '13px',
    fontWeight: '400px',
    lineHeight: '19.5px',

})

export const EditorContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
    maxHeight: '623px',
    borderradius: '8px',
    borderLeft: '6px',
    padding: '32px',
    gap: '40px',
    position: 'relative',
})

export const EditorContent = styled.div({
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    gap: '16px',
    width: '536px',
    maxHeight: '368px',
    overflowY: 'auto',
    paddingTop: '12px'
})

export const EditorRow = styled.div({
    display: 'flex',
    alignItems: 'end',
    width: '100%',
    height: '75px',
    gap: '8px',
})

export const ContentHeader = styled.span({
    width: '88px',
    height: '46px',
    font: fontFamily.inter,
    fontWeight: fontWeight.bold,
    size: typography.semiXS.fontSize,
    lineHeight: '22.5px'
})

export const SubtotalContainer = styled.div({
    // height: '75px',
    gap: '8px',
    width: '200px',
    display: 'flex',
    flexDirection: 'column'
})

export const Text = styled.div<{ error?: boolean }>({
    textWrap: 'nowrap',
    height: '20px',
    font: fontFamily.inter,
    fontWeight: fontWeight.bold,
    fontSize: '13px',
    // lineHeight: '19.5'
},
    ({ error }) => (
        error && {
            color: '#C13030'
        }),
)

export const SubText = styled.div({
    font: fontFamily.inter,
    fontWeight: fontWeight.light,
    fontSize: '13px',
    // lineHeight: '19.5'
})

export const SubtotalInput = styled.input({
    display: 'flex',
    position: 'relative',
    height: '47px',
    // width: '100%',
    borderradius: '8px',
    border: '1px',
    padding: '12px',
    gap: '1px'
})

export const Dropdown = styled.div<{ error?: boolean }>(
    {
        display: 'flex',
        padding: '12px',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: '8px',
        borderRadius: '8px',
        border: `1px solid ${colors.gray.medium}`,
        position: 'relative',
        width: '200px',
        height: '47px',
        fontFamily: fontFamily.inter,
        fontSize: '15px',
        fontWeight: fontWeight.normal,
        lineHeight: '22.5px',
        textAlign: 'left',

    },
    ({ error }) => (
        error && {
            borderColor: '#C13030'
        }),
)

export const DropDownOptions = styled.div<{ active: boolean }>(
    {
        gap: 0,
        display: 'flex',
        padding: '4px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '8px',
        background: `var(--Global-Background-Tertiary, ${colors.gray['450']})`,
        boxShadow: '0px 4px 60px 0px rgba(0, 0, 0, 0.10)',
        width: '200px',
        position: 'sticky',
        top: '47px',
        zIndex: '1000'
    },
    ({ active }) =>
        !active && {
            display: 'none'
        }
)

export const OptionsRow = styled.div({
    display: 'flex',
    padding: '6px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    ':hover': {
        borderRadius: '5px',
        background: 'rgba(27, 81, 164, 0.10)',
    }
})

export const RowDetails = styled.div({
    color: `var(--Global-Text-Primary, ${colors.primary.medium})`,
    textAlign: 'right',
    fontFamily: fontFamily.inter,
    fontSize: typography.xxs.fontSize,
    fontStyle: 'normal',
    fontWeight: fontWeight.normal,
    lineHeight: '150%', /* 19.5px */
})

export const Icon = styled.img<{ iconSize?: string }>(
    {
        width: '24px',
        height: '24px',
        borderradius: '6px',
        border: '0.75px',
    }
)

export const Footer = styled.div({
    display: 'flex',
    width: '536px',
    height: '39px',
    gap: '24px',
    opacity: '0px',
    justifyContent: 'end',
    alignItems: 'center'
})

export const InputContainer = styled.div<{ error?: boolean }>({
    display: 'flex',
    width: '200px',
    height: '47px',
    padding: '12px 0px 0px 12px',
    // gap: '16px',
    borderRadius: '8px',
    opacity: '0px',
    border: '1px solid #D7D7D7',

},
    ({ error }) => (
        error && {
            borderColor: '#C13030'
        }),
)


export const SubtotalStart = styled.div({
    height: '23px',
    padding: '0px 16px 0px 0px',
    opacity: '0px',
    fontFamily: fontFamily.inter,
    fontSize: '15px',
    fontWeight: fontWeight.light,
    lineHeight: '22.5px',
    textAlign: 'left',
    color: '#D7D7D7',
    position: 'absolute',
    left: '10px',  // Adjust to position the "lower_price_limit" text where you want inside the input
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '-webkit-fill-available',
})

export const SubtotalEnd = styled.div({
    height: '23px',
    opacity: '0px',
    fontFamily: fontFamily.inter,
    fontSize: '15px',
    fontWeight: fontWeight.normal,
    lineHeight: '22.5px',
    textAlign: 'left',
    display: 'flex'
})

export const SubtotalHeaderContainer = styled.div<{ error?: boolean }>({
    width: '189px',
    height: '20px',
    display: 'flex',
    gap: '4px',
},
    ({ error }) => (
        error && {
            color: '#C13030'
        }),

)

export const Divider = styled.div({
    width: '536px',
    height: '1px',
    gap: '0px',
    opacity: '0px',
    background: ' #D7D7D7',
})

export const PrepTimeContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    height: '75px',
    gap: '8px'
})

export const Tiers = styled.div({
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '22.5px',
    textAlign: 'left',
    ['text-underline-position']: 'from-font',
    ['text-decoration-skip-ink']: 'none',
    width: '88px',
    paddingTop: '25px',
})

export const AddTier = styled.div({
    opacity: '0px',
    fontFamily: fontFamily.inter,
    fontSize: '15px',
    fontWeight: fontWeight.semibold,
    lineHeight: '22.5px',
    textAlign: 'left',
    color: '#1B51A4',
    textDecoration: 'underline',
    justifyContent: 'flex-end',
    display: 'flex',
    width: 'fit-content',
    left: '465px',
    position: 'relative',
})

export const Cancel = styled.div({
    width: '51px',
    height: '23px',
    gap: '0px',
    opacity: '0px',
    fontFamily: fontFamily.inter,
    fontSize: '15px',
    fontWeight: fontWeight.semibold,
    lineHeight: '22.5px',
    textAlign: 'left',
    color: '#1B51A4',

})

export const PrimaryButton = styled.div({
    fontFamily: fontFamily.inter,
    fontSize: '15px',
    fontWeight: fontWeight.semibold,
    lineHeight: '22.5px',
    textAlign: 'left',
    color: '#FEFEFE',
    height: '39px',
    padding: '8px 16px 8px 16px',
    gap: '8px',
    borderRadius: '6px',
    opacity: '0px',
    backgroundColor: '#1B51A4',
})

export const ErrorMessage = styled.span({
    width: '190px',
    height: '15px',
    gap: '0px',
    opacity: '0px',
    fontFamily: fontFamily.inter,
    fontSize: '10px',
    fontWeight: fontWeight.semibold,
    lineHeight: '15px',
    textAlign: 'left',
    color: '#C13030',

})

export const LowerPriceLimit = styled.div({
    width: '115px'
})