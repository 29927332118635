import {
  StatusResource,
  generateConsentLink,
  getProvisioningStatus,
  provision,
  requestMenuImport,
  triggerMenuUpdate,
  updatePartner,
  updatePlatformOpenStatus,
} from 'api'
import { Availabilities } from 'api/menuMapping/types'
import { LocationHourResource, ProvisioningStatusResponse } from 'api/models'
import { getLocationHours } from 'api/partners/locationHours'
import ellipse_2_green from 'assets/icons/ellipse-2-green.svg'
import ellipse_2_red from 'assets/icons/ellipse-2-red.svg'
import { AxiosError, isAxiosError } from 'axios'
import { ErrorMessage, LoadingSkeleton, MenuImport, Table, TableMobile } from 'components'
import AvailabilitySelector from 'components/AvailabilitySelector/AvailabilitySelector'
import ConnectionModal from 'components/ConnectionModal/ConnectionModal'
import { LocationUpcharge } from 'components/LocationUpcharge/LocationUpcharge'
import OrderThrottleToggle from 'components/OrderThrottling/OrderThrottleToggle'
import ServiceFee from 'components/ServiceFee/ServiceFee'
import { Experiments, appSettings, appUrl } from 'config'
import {
  useGetLocationPartners,
  useGetMarketingChannelByPartnerId,
  useGetMenuMappingSections,
  useIsMobile,
  useLocationInfo,
  useOpenModal,
  useRouteLocationId,
} from 'hooks'
import useHasAuthorizedFeature from 'hooks/useHasAuthorizedFeature'
import { useAuth0 } from 'libs/auth0-react'
import { isEmpty, sortBy, startCase } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  menuPlatformsState,
  onboardingStatusWebHookCall,
  partnersMetadataQuery,
  uberPublishSuccessfulWebHookCall,
  upchargeState,
} from 'state/atoms'
import { PlatformName, PlatformSummary } from 'types'
import {
  MarketingChannelObject,
  MarketingChannelTypes,
} from 'types/digitalMarketing/marketing-channel'
import { showError } from 'utils'
import { isPosFeatureDisabled } from 'utils/PosConfig'
import { v4 as uuidv4 } from 'uuid'

import { TypeName } from '../../../api/models'
import HolidayHours from '../HolidayHours/HolidayHours'
import MenuAvailabilityModal from '../IndividualMenu/MenuAvailability/MenuAvailabilityModal'
import GoogleBusinessProfileDetailsDrawer from '../GoogleBusinessProfileDrawer/GoogleBusinessProfileDrawer'
import PlatformCloseReopenModal from '../PlatformCloseReopenModal'
import { ActivatePlatformComponent } from './ActivatePlatformComponent'
import { ChilipiperSection } from './ChilipiperSection'
import {
  getConnectionColumns,
  getMenuColumns,
  getMobileMenuColumns,
  getMobilePlatformColumns,
  getPlatformColumns,
} from './columns'
import { DoordashFeedbackSection } from './doordashFeedbackSection'
import * as Styled from './styles'

type FormValueTypes = {
  upcharge: number | string
}

const LOCATION_STATUS_STATE = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
}

type ConnectionStatus = 'NOT_INTEGRATED' | 'SUCCESS'

// Todo : will use in future with added doordash features

// type MenuErrorTypes = {
//   details: string,
//   doordash_store_uuid: string,
//   exclusion_code: string,
//   location_id: string,
//   menus: [],
//   onboarding_id: string,
//   status: string
// }

const IndividualLocation = () => {
  const { user, authorizedFeatures } = useAuth0()
  const locationId = useRouteLocationId()
  const { isMobile } = useIsMobile()
  const [searchParams] = useSearchParams()

  const marketingChannelIdParam = searchParams.get('marketingChannelId')
  const errorCallback = searchParams.get('error')
  useEffect(() => {
    if (errorCallback) {
      toast.error('Error while integrating google business profile.')
    }
  }, [])

  const {
    partner,
    platforms,
    isLoading,
    isError, // permanent error on request
    menuMappingMenuId,
    serviceFee,
    serviceFeeType,
    refetch,
  } = useGetLocationPartners(locationId)
  const locationInfo = useLocationInfo(locationId)

  const { menuMappingSections: menuSections, isLoading: menuSectionsLoading } =
    useGetMenuMappingSections(menuMappingMenuId)

  const { marketingChannels } = useGetMarketingChannelByPartnerId(locationId)

  const hasLocationHours = useHasAuthorizedFeature(Experiments.LOCATION_HOURS)
  const [menuPlatforms, setMenuPlatforms] = useRecoilState(menuPlatformsState)
  const doorDashonboardingStatusWebHook = useRecoilValue(onboardingStatusWebHookCall)
  const [upCharge, setUpcharge] = useRecoilState(upchargeState)
  const UberPublishSuccessfulWebHookResponse = useRecoilValue(uberPublishSuccessfulWebHookCall)
  const [isCloseReopenModalOpen, setIsCloseReopenModalOpen] = useState(false)
  const [selectedPlatforms, setSelectedPlatforms] = useState<PlatformSummary[]>([])
  const [isUpdating, setIsUpdating] = useState(false)
  const [doordashStatus, setDoordashStatus] = useState<string>()
  // Todo : will use in future with added doordash features
  // const [doordashMenuError, setDoordashMenuError] = useState<MenuErrorTypes>()
  const [doordashExclusionCode, setDoordashExclusionCode] = useState('')
  const [uberEatsStatus, setUberEatsStatus] = useState<boolean>()
  const [requestedMenuImport, setRequestedMenuImport] = useState(false)
  const [upchargeReadOnly, setUpchargeReadOnly] = useState(false)
  const [uberMenuPublish, setUberMenuPublish] = useState(false)
  const [isUberPlatformOnboardingStatus, setisUberPlatformOnboardingStatus] = useState(false)
  const [connectionModalOPen, setConnectionModalOPen] = useState(Boolean(marketingChannelIdParam))
  const [locationHours, setLocationHours] = useState<Availabilities[]>([])
  const [isMenuAvailabilityModalOpen, setIsMenuAvailabilityModalOpen] = useState(false)
  const posName = partner?.attributes?.pos?.toLowerCase() || ''
  const isPrinterClient = posName.includes('epson') // We don't want to show printer clients the menu resync option
  const ignoreStatus = ['unknown', 'pending', 'removed', 'requested', 'confirmed', 'menu_qualified']
  const isAdminUser = user?.email?.includes('@chowlyinc.com')
  // This "platform" check pertains to the customer's foor ordering platforms (i.e. Doordash, UberEats), not the Platform user
  const addPlatformUserCheck = isAdminUser || appSettings.isDevelopment || appSettings.isStaging
  const isMmrEnabled = partner?.attributes?.use_mmr
  const [successfulDoordashActivateRequest, setSuccessfulDoordashActivateRequest] = useState(false)
  const [successfulUberEatsActivateRequest, setSuccessfulUberEatsActivateRequest] = useState(false)
  const [selectedGBPMarketingChannelId, setSelectedGBPMarketingChannelId] = useState('')
  const locationCount = useRecoilValue(partnersMetadataQuery)

  // This "platform" check pertains to users who are subscribed to Chowly's RCC Platform offerings
  const rccPlatformEnabled = authorizedFeatures?.find(
    (feature) => feature[Experiments.RCC_PLATFORM],
  )

  const showMenuResync = !isPrinterClient || (isPrinterClient && isMmrEnabled)

  const uberEatsNeedsActivationCheck =
    !successfulUberEatsActivateRequest &&
    uberMenuPublish &&
    isUberPlatformOnboardingStatus &&
    !uberEatsStatus

  const doordashNeedsActivationCheck =
    doordashStatus === 'menu_qualified' && !successfulDoordashActivateRequest

  const showPlatformActivation =
    addPlatformUserCheck && (doordashStatus === 'menu_qualified' || uberEatsNeedsActivationCheck)

  const showDoordashFeedbackSection =
    addPlatformUserCheck && doordashStatus && !ignoreStatus.includes(doordashStatus)

  const timezone = partner?.attributes.timezone ?? 'UTC'

  useEffect(() => {
    setMenuPlatforms(platforms)
  }, [platforms])

  useEffect(() => {
    const doordashPlatform = menuPlatforms?.filter(
      (platform) => platform.internalName === PlatformName.DOORDASH,
    )
    const uberEatsPlatform = menuPlatforms?.filter(
      (platform) => platform.internalName === PlatformName.UBEREATS,
    )
    const uberMenuPublishStatus =
      uberEatsPlatform && uberEatsPlatform.length > 0
        ? uberEatsPlatform[0].menuPublishStatus
          ? uberEatsPlatform[0].menuPublishStatus?.attributes.success
          : null
        : null
    if (uberMenuPublishStatus && uberEatsPlatform && uberEatsPlatform[0]?.status === 'Onboarding') {
      setUberEatsStatus(uberEatsPlatform[0]?.provisioned)
      setUberMenuPublish(uberMenuPublishStatus)
      setisUberPlatformOnboardingStatus(true)
    }

    if (doordashPlatform?.length && doordashPlatform?.[0]?.aasmState === 'onboarding') {
      const fetchData = async () => {
        let provisionStatusResponse: ProvisioningStatusResponse | null = null

        try {
          provisionStatusResponse = await getProvisioningStatus(doordashPlatform[0].id)
        } catch (err) {
          console.log('Error getting provisioning status: ', (err as AxiosError).message)
        }

        if (provisionStatusResponse) {
          const { data: provisioningStatusData } = provisionStatusResponse
          setDoordashStatus(provisioningStatusData?.attributes.aasm_state)
          setDoordashExclusionCode(
            provisioningStatusData?.attributes?.error_details?.exclusion_code,
          )
        }
      }

      fetchData()
    }
  }, [menuPlatforms, doorDashonboardingStatusWebHook, UberPublishSuccessfulWebHookResponse])

  useEffect(() => {
    const readOnly: boolean = isPosFeatureDisabled('upcharge', posName)
    setUpchargeReadOnly(readOnly)
  }, [posName])

  useEffect(() => {
    if (partner?.attributes.upcharge === null) {
      setUpcharge(0)
    } else {
      // @ts-ignore
      setUpcharge(partner?.attributes.upcharge)
    }
  }, [partner])

  const transformLocationHours = (data: LocationHourResource[]): Availabilities[] => {
    data.forEach((item) => {
      if (item.attributes.time_periods.length > 1) {
        item.attributes.time_periods = sortBy(item.attributes.time_periods, (timePeriod) =>
          moment(timePeriod.start_time, 'HH:mm').toDate(),
        )
      }
    })

    return data.map((item) => ({
      id: item.id,
      dayOfWeek: startCase(item.attributes.day_of_week),
      timePeriods: item.attributes.time_periods.map((period, index) => ({
        id: item.relationships.time_periods.data[index]?.id || '',
        attributes: {
          start_time: period.start_time,
          end_time: period.end_time,
        },
      })),
      hours: item.attributes.time_periods
        .map(
          (period) =>
            `${moment(period.start_time, 'HH:mm').format('h:mmA')} - ${moment(
              period.end_time,
              'HH:mm',
            ).format('h:mmA')}`,
        )
        .join(', ') as string,
      enabled: item.attributes.enabled,
    })) as Availabilities[]
  }

  const fetchLocationHours = useCallback(async () => {
    try {
      if (!locationId) return
      const response = await getLocationHours(locationId)
      setLocationHours(transformLocationHours(response?.data))
    } catch (error) {
      console.error('Failed to fetch store hours:', error)
    }
  }, [locationId])

  useEffect(() => {
    if (!locationId) return
    fetchLocationHours()
  }, [locationId, fetchLocationHours])

  const handleSubmit = async (value: FormValueTypes) => {
    if (locationId) {
      try {
        await updatePartner(locationId, { attributes: value })
        toast.success('Saved!')
        refetch()
      } catch (err) {
        toast.error(
          'Failed in saving the changes. Try again. For additional help please contact Chowly Support using the “C” icon in the lower right corner of your screen.',
        )
      }
    }
  }

  const reSyncDoordash = async () => {
    if (locationId) {
      try {
        if (doordashStatus) {
          if (
            !(partner?.attributes?.pos === 'Epson') &&
            !(partner?.attributes?.pos === 'ChowlySdk')
          ) {
            await requestMenuImport(locationId, false, false)
          }
          const doordashPlatform = menuPlatforms?.filter(
            (platform) => platform.internalName === 'Doordash',
          )
          if (doordashPlatform && doordashPlatform[0].id) {
            await triggerMenuUpdate(Number(doordashPlatform[0].id))
          }
        }
        toast.success('Menu Sync requested successfully')
        setRequestedMenuImport(true)
      } catch (err) {
        toast.error(
          'Failed in saving the changes. Try again. For additional help please contact Chowly Support using the “C” icon in the lower right corner of your screen.',
        )
      }
    }
  }

  const reSyncSubmit = useCallback(async () => {
    if (menuMappingMenuId) {
      if (locationId) {
        try {
          const { included } = await requestMenuImport(locationId, false, true)
          if (included) {
            const status = included.find(
              (item): item is StatusResource =>
                item.type === 'status' && item.attributes.category === 'menu_import',
            )
            if (status) {
              // setMenuImportTimestamp(status.attributes.enqueued_at)
              // setEnqueued(status.attributes.enqueued_at ? true : false)
            }
          }
        } catch (err) {
          if (isAxiosError(err)) {
            if (err.response?.status === 429) {
              toast.error(
                'A menu resync was enqueued less than 5 minutes ago. Please wait at least 5 minutes before resyncing again. For additional help please contact Chowly Support using the “C” icon in the lower right corner of your screen.',
              )
            } else {
              toast.error(`Resync action couldn’t start due to ${(err as AxiosError).message}`)
            }
          } else {
            toast.error(`Unknown error: ${String(err)}`)
          }
        }
      }
    }
  }, [menuMappingMenuId, locationId])

  const handlePause = useCallback(
    (row?: PlatformSummary) => {
      if (platforms?.length) {
        setSelectedPlatforms(
          row
            ? [row]
            : platforms.filter(
                ({ availability, pausable }) => pausable && availability === 'Not Paused',
              ),
        )
        setIsCloseReopenModalOpen(true)
      }
    },
    [platforms],
  )

  const handleGoToCOO = useCallback((url: string) => {
    if (url) {
      window.open(url, '_blank')
    }
  }, [])

  const isPauseAllEnabled = (platforms ?? [])?.some(
    ({ availability, pausable }) => pausable && availability === 'Not Paused',
  )

  const platformsColumns = useMemo(() => {
    if (!locationId) {
      return []
    }
    return getPlatformColumns(handlePause, refetch, handleGoToCOO, locationId, isAdminUser)
  }, [handlePause, isPauseAllEnabled])

  const mobilePlatformsColumns = useMemo(() => {
    if (!locationId) {
      return []
    }
    return getMobilePlatformColumns(handlePause, refetch, handleGoToCOO, locationId, isAdminUser)
  }, [handlePause, isPauseAllEnabled])

  const mobileMenuColumns = useMemo(() => {
    if (!locationId) {
      return []
    }
    return getMobileMenuColumns(locationId)
  }, [locationId, reSyncSubmit])

  const menusColumns = useMemo(() => {
    if (!locationId) {
      return []
    }
    return getMenuColumns(locationId)
  }, [locationId, reSyncSubmit])

  const connectToGoogleBusinessProfile = useCallback(async () => {
    if (locationId) {
      const url = await generateConsentLink(
        `${appUrl}/${locationId}/menu-management/gbp/profile-selector`,
        locationId,
      )
      const width = 500
      const height = 600
      const left = (window.innerWidth - width) / 2
      const top = (window.innerHeight - height) / 2
      if (url)
        window.open(
          url,
          'GoogleConsentPopup',
          `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,status=no`,
        )
    }
  }, [locationId])

  const openGoogleBusinessProfile = useCallback(async (marketingChannelId: string) => {
    setSelectedGBPMarketingChannelId(marketingChannelId)
  }, [])

  const connectionColumns = useMemo(() => {
    if (!locationId) {
      return []
    }
    let status: ConnectionStatus = 'NOT_INTEGRATED'
    const marketingChannel = marketingChannels?.find(
      (mc: MarketingChannelObject) => mc.type === MarketingChannelTypes.GoogleBusinessProfile,
    )
    if (
      !isEmpty(marketingChannel?.googleBusinessProfile?.accountId) &&
      !isEmpty(marketingChannel?.googleBusinessProfile?.locationId) &&
      !isEmpty(marketingChannel?.googleBusinessProfile?.refreshToken)
    ) {
      status = 'SUCCESS'
    }
    return getConnectionColumns(
      connectToGoogleBusinessProfile,
      openGoogleBusinessProfile,
      marketingChannel?.id || '',
      status,
    )
  }, [marketingChannels, locationId, connectToGoogleBusinessProfile])

  const handleCloseReopen = async (temporary_closure?: string) => {
    setIsCloseReopenModalOpen(false)
    setIsUpdating(true)
    await Promise.all([
      selectedPlatforms.map(async ({ id, availability }) => {
        try {
          await updatePlatformOpenStatus(id, {
            // if its currently paused reopen, else close
            open_availability: availability === 'Paused' ? true : false,
            ...(availability !== 'Paused' && {
              temporary_closure,
            }),
          })
          refetch()
        } catch (e) {
          showError(e)
        }
      }),
    ])
    setIsUpdating(false)
  }

  const handleActivateDoordash = async () => {
    const doordashPlatform = menuPlatforms?.filter(
      (platform) => platform.internalName === 'Doordash',
    )
    if (!doordashPlatform) return
    else if (doordashPlatform && !doordashPlatform[0].id) return
    else {
      try {
        await provision(Number(doordashPlatform[0].id))
        toast.success('Requested Doordash activation!')
        setSuccessfulDoordashActivateRequest(true)
      } catch (err) {
        toast.error(
          'Failed in requesting Doordash activation. Try again. For additional help please contact Chowly Support using the “C” icon in the lower right corner of your screen.',
        )
      }
    }
  }

  const handleActivateUberEats = async () => {
    const uberEatsPlatform = menuPlatforms?.filter(
      (platform) => platform.internalName === PlatformName.UBEREATS,
    )
    if (!uberEatsPlatform) return
    else if (uberEatsPlatform && !uberEatsPlatform[0].id) return
    else {
      try {
        await provision(Number(uberEatsPlatform[0].id))
        toast.success('Requested Uber Eats activation!')
        setSuccessfulUberEatsActivateRequest(true)
        setisUberPlatformOnboardingStatus(false)
      } catch (err) {
        toast.error(
          'Failed in requesting Uber Eats activation. Try again. For additional help please contact Chowly Support using the “C” icon in the lower right corner of your screen.',
        )
      }
    }
  }

  const openModal = useOpenModal()
  const openManageAccount = () => openModal('AddPlatform')

  const existingCOOPlatform = platforms?.find((p) => p.name === 'Chowly Online Ordering')
  const isOrderThrottlingEnabled = partner?.attributes.price_based_prep_times

  return (
    <Styled.LocationContainer width={isMobile ? '342px' : '668px'}>
      {isError && !partner && <ErrorMessage />}
      {(partner || isLoading) && (
        <>
          <Styled.LocationHeader>
            <Styled.LocationName
              data-testid='location-name'
              width={isMobile ? '100%' : '90%'}
              singleLocation={locationCount?.total}
            >
              {isLoading ? <LoadingSkeleton isFlex /> : partner?.attributes?.name}
            </Styled.LocationName>
            <Styled.StatusContainer width={'8%'}>
              {isLoading ? (
                <LoadingSkeleton isFlex />
              ) : (
                <>
                  <Styled.Icon
                    src={
                      partner?.attributes?.aasm_state === LOCATION_STATUS_STATE.ENABLED
                        ? ellipse_2_green
                        : ellipse_2_red
                    }
                    alt='ellipseIcon'
                  />
                  <Styled.StatusText
                    isSuccess={partner?.attributes?.aasm_state === LOCATION_STATUS_STATE.ENABLED}
                    data-testid='location-status'
                  >
                    {partner?.attributes?.aasm_state}
                  </Styled.StatusText>
                </>
              )}
            </Styled.StatusContainer>
          </Styled.LocationHeader>

          {hasLocationHours && (
            <Styled.LocationHoursContainer>
              <AvailabilitySelector
                availabilities={locationHours}
                editButtonLabel='Edit Location Hours'
                selectorLabel='Location Hours'
                onEdit={() => setIsMenuAvailabilityModalOpen(true)}
              />
            </Styled.LocationHoursContainer>
          )}

          <Styled.Grid>
            {!upchargeReadOnly && (
              <Styled.LocationFormItem width={isMobile ? '100%' : null}>
                <Styled.LocationConnectionContainer>
                  <LocationUpcharge
                    onSubmit={handleSubmit}
                    currentUpcharge={upCharge}
                    isLoading={isLoading}
                  />
                </Styled.LocationConnectionContainer>
              </Styled.LocationFormItem>
            )}
            {showMenuResync && (
              <Styled.LocationFormItem
                width={isMobile ? '342px' : null}
                upchargeReadOnly={upchargeReadOnly}
              >
                <Styled.LocationConnectionContainer>
                  <MenuImport
                    upCharge={upCharge}
                    resyncSumbit={reSyncSubmit}
                    onSubmit={handleSubmit}
                    locationId={parseInt(locationId || '0')}
                    isPrinterClient={isPrinterClient}
                  />
                </Styled.LocationConnectionContainer>
              </Styled.LocationFormItem>
            )}

            {existingCOOPlatform && isAdminUser && rccPlatformEnabled && (
              <Styled.LocationFormItem style={{ width: '322px', height: '71px' }}>
                <Styled.LocationConnectionContainer>
                  <ServiceFee
                    locationId={locationId}
                    platformId={existingCOOPlatform.id}
                    refetch={refetch}
                    serviceFee={serviceFee}
                    serviceFeeType={serviceFeeType}
                  />
                </Styled.LocationConnectionContainer>
              </Styled.LocationFormItem>
            )}
          </Styled.Grid>

          {/* Tools Section */}
          {existingCOOPlatform && isAdminUser ? (
            <OrderThrottleToggle
              partnerId={partner?.id}
              attributes={partner?.attributes}
              isOrderThrottlingEnabled={isOrderThrottlingEnabled}
            />
          ) : null}

          {/* Platforms section */}
          <Styled.Section>
            <Styled.SectionHeader>
              <Styled.SectionTitle>Platforms</Styled.SectionTitle>
              <Styled.SectionHeaderRight>
                {addPlatformUserCheck && (
                  <Styled.BaseButton
                    onClick={() => openManageAccount()}
                    variant='solid'
                    disabled={isLoading}
                  >
                    Add Platform
                  </Styled.BaseButton>
                )}
                <Styled.BaseButton size='small' disabled={isLoading} onClick={() => handlePause()}>
                  Pause All
                </Styled.BaseButton>
              </Styled.SectionHeaderRight>
            </Styled.SectionHeader>
            {isMobile ? (
              <TableMobile
                width={'364px'}
                path={'./styles.ts'}
                columns={mobilePlatformsColumns}
                rowKey='id'
                data={menuPlatforms ?? []}
                isLoading={isLoading}
              />
            ) : (
              <Table
                // width={'620px'}
                path={'./styles.ts'}
                columns={platformsColumns}
                rowKey='id'
                data={menuPlatforms ?? []}
                isLoading={isLoading}
              />
            )}
            {/* {(isLoading || isUpdating) && <SkeletonLoader />} */}
            {!(isLoading || isUpdating) && <ChilipiperSection />}
          </Styled.Section>
          {/* Doordash error section */}
          {showDoordashFeedbackSection && (
            <DoordashFeedbackSection
              errorType={doordashStatus}
              doordashExclusionCode={doordashExclusionCode}
              requestedMenuImport={requestedMenuImport}
              reSyncDoordash={reSyncDoordash}
            />
          )}
          {showPlatformActivation && (
            <ActivatePlatformComponent
              handleActivateDoordash={handleActivateDoordash}
              uberEatsNeedsActivationCheck={uberEatsNeedsActivationCheck}
              doordashNeedsActivationCheck={doordashNeedsActivationCheck}
              handleActivateUberEats={handleActivateUberEats}
            />
          )}
        </>
      )}
      <Styled.Section>
        <Styled.SectionHeader>
          <Styled.SectionTitle>Connections</Styled.SectionTitle>
        </Styled.SectionHeader>
        {window.screen.width < 480 ? (
          <TableMobile
            width={'364px'}
            path={'./styles.ts'}
            columns={connectionColumns}
            rowKey='id'
            data={[
              {
                id: uuidv4(),
                type: 'gbp' as TypeName.GBPConnection,
                attributes: {
                  title: 'Google Business Profile',
                  subtitle: '',
                },
              },
            ]}
          />
        ) : (
          <Table
            width={'620px'}
            path={'./styles.ts'}
            columns={connectionColumns}
            rowKey='id'
            data={[
              {
                id: uuidv4(),
                type: 'gbp' as TypeName.GBPConnection,
                attributes: {
                  title: 'Google Business Profile',
                  subtitle: '',
                },
              },
            ]}
          />
        )}
      </Styled.Section>

      {(menuSections || menuSectionsLoading) && (
        <Styled.Section>
          <Styled.SectionHeader>
            <Styled.SectionTitle>Menus</Styled.SectionTitle>
            {isPrinterClient && isAdminUser && (
              <Styled.SectionHeaderRight>
                <Styled.BaseButton to={`/${locationId}/menu-management/menus/new`}>
                  Add Menu
                </Styled.BaseButton>
              </Styled.SectionHeaderRight>
            )}
          </Styled.SectionHeader>
          {window.screen.width < 480 ? (
            <TableMobile
              width={'364px'}
              path={'./styles.ts'}
              columns={mobileMenuColumns}
              rowKey='id'
              data={menuSections ?? []}
              isLoading={menuSectionsLoading}
            />
          ) : (
            <Table
              width={'620px'}
              path={'./styles.ts'}
              columns={menusColumns}
              rowKey='id'
              data={menuSections ?? []}
              isLoading={menuSectionsLoading}
            />
          )}
        </Styled.Section>
      )}

      <HolidayHours />

      <PlatformCloseReopenModal
        isOpen={isCloseReopenModalOpen}
        platforms={selectedPlatforms}
        timezone={timezone}
        onClose={() => setIsCloseReopenModalOpen(false)}
        onSubmit={handleCloseReopen}
      />

      {marketingChannelIdParam && locationId && (
        <ConnectionModal
          isOpen={connectionModalOPen}
          locationName={locationInfo?.name || ''}
          onClose={() => setConnectionModalOPen(false)}
          showInfoIcon={false}
          marketingChannelId={marketingChannelIdParam}
          locationId={locationId}
        />
      )}

      {/* <MenuResyncRequiredBanner /> */}
      {isMenuAvailabilityModalOpen && hasLocationHours && locationHours && (
        <MenuAvailabilityModal
          isOpen={isMenuAvailabilityModalOpen}
          onClose={() => setIsMenuAvailabilityModalOpen(false)}
          availabilities={locationHours}
          refetchData={fetchLocationHours}
          type='location_hour'
          partnerId={locationId || ''}
        />
      )}

      {selectedGBPMarketingChannelId && (
        <GoogleBusinessProfileDetailsDrawer
          open
          onClose={() => setSelectedGBPMarketingChannelId('')}
          marketingChannelId={selectedGBPMarketingChannelId}
          partnerId={locationId || ''}
        />
      )}
    </Styled.LocationContainer>
  )
}

export default IndividualLocation
