// https://jsonapi.org/format/#document-top-level
import ServiceAvailability, { TimePeriod } from '../utils/ServiceAvailability'

export enum TypeName {
  OrderPlatform = 'order_platform',
  Partner = 'partner',
  User = 'user',
  OnboardingLocation = 'onboarding_location',
  MenuMappingMenu = 'menu_mapping_menu',
  MenuMappingSection = 'menu_mapping_section',
  MenuMappingSectionCollection = 'menu_mapping_section_collection',
  MenuMappingSubsection = 'menu_mapping_subsection',
  MenuMappingSubsectionItem = 'menu_mapping_subsection_item',
  MenuMappingOverrideSection = 'menu_mapping_override_section',
  MenuMappingCustomization = 'menu_mapping_customization',
  MenuMappingCustomizationOption = 'menu_mapping_customization_option',
  MenuMappingNestedCustomization = 'menu_mapping_nested_customization',
  MenuMappingCustomizationCustomizationOption = 'menu_mapping_customization_customization_option',
  MenuMappingSchedule = 'menu_mapping_schedule',
  MenuMappingServiceAvailability = 'menu_mapping_service_availability',
  MenuMappingItem = 'menu_mapping_item',
  MenuMappingItemCustomization = 'menu_mapping_item_customization',
  MenuMappingDefaultCustomizationOption = 'menu_mapping_default_customization_option',
  ProvisioningStatus = 'provisioning_status',
  MenuMappingTimePeriod = 'menu_mapping_time_period',
  Status = 'status',
  ActivationCode = 'activation_code',
  ImportMenuStatus = 'import_menu_status',
  Items = 'items',
  Item = 'item',
  CustomizationOptions = 'customization_options',
  CustomizationOption = 'customization_option',
  PrepTime = 'prep_times',
  ServiceFeeRate = 'service_fee_rate',
  ServiceFeeType = 'service_fee_type',
  GBPConnection = 'gbp',
}

export type JsonApiResponse<P, I = unknown, M = unknown> = {
  data: P
  included?: Array<I>
  meta?: M
  links?: PaginationLinks
}

export type JsonApiResource<T, A, R = unknown> = {
  id: string
  type: T
  attributes: A
  relationships: R
}

export type PaginationLinks = {
  first?: string | null
  last?: string | null
  prev?: string | null
  next?: string | null
  self?: string | null
}

export type ResourceIdentifier<T = string> = { id: string; type: T }

/**
 * /order_platforms/:id API response types
 */
export type OrderPlatformResponse = JsonApiResponse<OrderPlatformResource, PartnerResource>

export type OrderPlatformResource = JsonApiResource<
  TypeName.OrderPlatform,
  OrderPlatform,
  OrderPlatformRelationships
>

export type OrderPlatformRelationships = {
  partner: { data: ResourceIdentifier | null }
  menu_mapping_menu: { data: ResourceIdentifier | null }
  menu_publish_status: { data: ResourceIdentifier | null }
  online_status: { data: ResourceIdentifier | null }
  menu_mapping_override_sections: { data: ResourceIdentifier[] | null }
}

export type OrderPlatform = {
  provisioned: boolean
  id: number
  name: string
  menu_mapping_menu_id: string | null
  aasm_state: string
  timestamp?: string
  success?: boolean
  pausable: boolean
  temporary_closure: string | null
  internal_name: string
  pickup_time_estimate: number | null
  delivery_time_estimate: number | null
  upcharge: number | null
  external_id: string
  has_sauce_integration: boolean
  needs_to_publish: boolean
  has_access_token: boolean
  has_onboarding_id: boolean
  buydown?: boolean | null
  buydown_fee?: number | null
  minimum_delivery_subtotal?: number | null
  partner_id: number
  coo_url?: string | null
  service_fee_rate?: number
  service_fee_type?: string
}

export type OrderPlatformErrorResponse = {
  errors: Array<{ detail: string }>
}

/**
 * /partners/:id API response types
 */
export type PartnerResponse = JsonApiResponse<
  PartnerResource,
  OrderPlatformResource | StatusResource | OnboardingLocationResource | ServiceFeeResource
>

export type ServiceFeeResource = JsonApiResource<
  TypeName.ServiceFeeRate | TypeName.ServiceFeeType,
  ServiceFee
>

export type PrepTimesResource = JsonApiResource<'prep_times', PartnerPrepTimes>

export type PartnerRelationships = {
  delivery_areas: { data: ResourceIdentifier[] }
  order_platforms: { data: ResourceIdentifier[] }
  menu_import_status: { data: ResourceIdentifier | null }
  onboarding_location: { data: ResourceIdentifier | null }
  recent_import_menu_statuses: { data: StatusResource[] | null }
  prep_times: { data: ResourceIdentifier[] | null }
}

export type PartnerResource = JsonApiResource<'partner', Partner, PartnerRelationships>

/**
 * /partners API response type
 */
export type PartnersResponse = JsonApiResponse<
  Array<PartnerResource>,
  OrderPlatformResource | OnboardingLocationResource,
  PartnerMetadata
>

export type PartnerUpdateInput = Partial<JsonApiResource<'partner', unknown>> & {
  attributes: {
    sync_pos_menu_ids?: string[][]
    upcharge: number | string
  }
}

export type Partner = {
  id: number
  name: string
  pos: string
  store_id: string
  aasm_state: string
  upcharge: number | string
  categories?: Menu[]
  default_hours?: DefaultHours[]
  timezone: string
  city: string
  state: string
  pos_sync: boolean
  price_based_prep_times: boolean
  use_mmr: boolean
  organization_id?: string
}

export type PosLocation = {
  id: string
  name: string
  address: Address
  timezone: string
  capabilities: string[]
  status: 'ACTIVE' | 'INACTIVE'
  created_at: string
  merchant_id: string
  country: string
  language_code: string
  currency: string
  business_name: string
  type: 'MOBILE' | 'PHYSICAL'
  business_hours: {
    periods: Array<BusinessHoursPeriod>
  }
}

export type BusinessHoursPeriod = {
  day_of_week: 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN'
  start_local_time: string
  end_local_time: string
}

export type Address = {
  address_line_1: string
  locality: string
  administrative_district_level_1: string
  postal_code: string
  country: string
}

// TBD:
export type Menu = {
  id: string
  name: string
}

export type DefaultHours = {
  day_of_week: string
  start_local_time: string
  end_local_time: string
}

// TBD:
export type Customization = {
  id: string
  name: string
}

export type PartnerMetadata = {
  total: number
  self_onboarded: number
}

/**
 * /users API response types
 */

export type UserResponse = JsonApiResponse<JsonApiResource<'user', User>>

export type PrepTimeResponse = JsonApiResponse<Array<PrepTimesResource>>

export type ServiceFeeResponse = JsonApiResponse<ServiceFeeResource>

export type User = {
  id: number
  type: UserType
  email: string
  confirmed: boolean
  name?: string
}

export enum UserType {
  SuperAdminUser = 'SuperAdminUser',
  AdminUser = 'AdminUser',
  PowerUser = 'PowerUser',
  User = 'User',
}

export type EmailVerificationInput = {
  data: {
    attributes: {
      email: string
      user_id: string
    }
  }
}

export type UserInput = {
  name: string
  phone: string
  email: string
  restaurantName: string
  password: string
  passwordConfirmation: string
}

/**
 * /dashboard/onboarding_locations/:id API response and request types
 */

export type OnboardingLocationResponse = JsonApiResponse<OnboardingLocationResource>

export type OnboardingLocationsResponse = JsonApiResponse<Array<OnboardingLocationResource>>

export type OnboardingLocationResource = JsonApiResource<
  TypeName.OnboardingLocation,
  OnboardingLocation
>

export type OnboardingLocation = {
  id: string
  name: string
  phone_number: string
  address: string
  city: string
  state: string
  zipcode: string
  currency: string
  timezone?: string
  google_place_id: string
  partner_id: number
  completed?: boolean
  current_step?: string
  finished?: boolean
}

export type OnboardingLocationCreateInput = Pick<
  OnboardingLocation,
  | 'name'
  | 'phone_number'
  | 'address'
  | 'city'
  | 'state'
  | 'zipcode'
  | 'currency'
  | 'timezone'
  | 'google_place_id'
  | 'current_step'
>

export type OnboardingLocationUpdateInput = Partial<OnboardingLocation> & {
  finalize?: boolean
  completed?: boolean
}

/**
 * /menu_mapping/menus/:id API response
 */

export type MenuMappingMenuResponse = JsonApiResponse<
  MenuMappingMenuResource,
  MenuMappingSectionResource
>

export type MenuMappingMenuRelationships = {
  sections: { data: ResourceIdentifier<TypeName.MenuMappingSection>[] }
}

export type MenuMappingMenuResource = JsonApiResource<
  TypeName.MenuMappingMenu,
  MenuMappingMenu,
  MenuMappingMenuRelationships
>

export type ConnectionResource = {
  id: string
  type: TypeName.GBPConnection
  attributes: {
    title: string
    subtitle: string
  }
}

export type MenuMappingMenu = {
  errors: ErrorObject[]
}

export type ErrorObject = {
  error: string
  object_type: 'MenuMapping::Section' | 'MenuMapping::Item' // maybe more types
  object_id: string
}

/**
 * /override_section API response type
 */

export type MenuMappingOverrideSectionResponse = JsonApiResponse<
  MenuMappingOverrideSectionResource,
  MenuMappingOverrideSectionResponseIncluded
>

export type MenuMappingOverrideSectionsResponse = JsonApiResponse<
  Array<MenuMappingOverrideSectionResource>,
  MenuMappingOverrideSectionResponseIncluded
>

export type MenuMappingOverrideSectionResource = JsonApiResource<
  TypeName.MenuMappingOverrideSection,
  MenuMappingOverrideSection,
  MenuMappingOverrideSectionRelationships
>

export type MenuMappingOverrideSectionResponseIncluded =
  | MenuMappingScheduleResource
  | MenuMappingSubsectionResource
  | MenuMappingSectionCollectionResource
  | MenuMappingServiceAvailabilityResource
  | MenuMappingTimePeriodResource

export type MenuMappingOverrideSectionRelationships = {
  order_platform: { data: ResourceIdentifier | null }
  sections: { data: ResourceIdentifier | null }
  override_section_collections: { data: Array<ResourceIdentifier> | null }
  subsections: { data: Array<ResourceIdentifier> | null }
  schedule: { data: ResourceIdentifier | null }
}

/**
 * @deprecated
 */
export type MenuMappingOverrideSectionInput = {
  data: {
    id?: string
    type: string
    attributes: MenuMappingOverrideSection
    relationships?: {
      order_platform: {
        data: {
          type: TypeName.OrderPlatform
          id: number
        }
      }
    }
  }
}

export type MenuMappingSectionInput = {
  data: {
    id?: string
    type: TypeName.MenuMappingSection
    attributes: MenuMappingSection
    relationships?: {
      menu: {
        data: {
          type: TypeName.MenuMappingMenu
          id: string
        }
      }
      subsections?: {
        data: {
          type: TypeName.MenuMappingSubsection
          id: string
        }
      }
      service_availabilities?: {
        type: TypeName.MenuMappingServiceAvailability
        id: string
      }
    }
  }
}

/**
 * @deprecated
 */
export type MenuMappingOverrideSection = {
  title?: string
  subtitle?: string
  sort_position?: number
  external_section_ids_to_import?: string[]
}

/**
 * /menu_mapping/sections/:id API response type
 */

export type MenuMappingSectionResponse = JsonApiResponse<
  MenuMappingSectionResource,
  MenuMappingSectionResponseIncluded
>

export type MenuMappingSectionResponseIncluded =
  | MenuMappingMenuResource
  | MenuMappingSubsectionResource
  | MenuMappingItemResource
  | MenuMappingSubsectionItemResource
  | MenuMappingServiceAvailabilityResource
  | OrderPlatformResource
  | MenuMappingCustomizationOptionResource

export type MenuMappingSectionResource = JsonApiResource<
  TypeName.MenuMappingSection,
  MenuMappingSection,
  MenuMappingSectionRelationships
>

export type MenuMappingSectionRelationships = {
  service_availabilities: {
    data: Array<ResourceIdentifier<TypeName.MenuMappingServiceAvailability>>
  }
  subsections: { data: Array<ResourceIdentifier<TypeName.MenuMappingSubsection>> }
  menu: { data: ResourceIdentifier<TypeName.MenuMappingMenu> }
}

export type MenuMappingSection = {
  title: string
  subtitle?: string
}

/**
 * /menu_mapping/subsections/:id API response type
 */

export type MenuMappingSubsectionResponse = JsonApiResponse<
  MenuMappingSubsectionResource,
  MenuMappingItemResource
>

export type MenuMappingSubsectionResource = JsonApiResource<
  TypeName.MenuMappingSubsection,
  MenuMappingSubsection,
  MenuMappingSubsectionRelationships
>

export type MenuMappingSubsection = {
  title: string
  id: string
  external_id: string
  sort_position: number
}

export type MenuMappingSubsectionPayloadAttributes = {
  title?: string
  id?: string
  external_id?: string
  sort_position?: number
}

export type MenuMappingSubsectionInput = {
  data: {
    id?: string
    type: TypeName.MenuMappingSubsection
    attributes: MenuMappingSubsectionPayloadAttributes
    relationships?: MenuMappingSubsectionRelationships
  }
}

export type MenuMappingSubsectionRelationships = {
  section?: { data: ResourceIdentifier<TypeName.MenuMappingSection> }
  items?: { data: ResourceIdentifier<TypeName.MenuMappingItem>[] }
  subsection_items?: { data: ResourceIdentifier<TypeName.MenuMappingSubsectionItem>[] }
}

export type MenuMappingSectionCollectionResource = JsonApiResource<
  TypeName.MenuMappingSectionCollection,
  MenuMappingSectionCollection,
  MenuMappingSectionCollectionRelationships
>

export type MenuMappingSectionCollection = {
  sort_position: number
}

export type MenuMappingSectionCollectionRelationships = {
  section: { data: ResourceIdentifier }
}
/**
 * /menu_mapping/items/:id API response type
 */

export type MenuMappingItemResponse = JsonApiResponse<
  MenuMappingItemResource,
  MenuMappingSubsectionItemResource | MenuMappingCustomizationResource
>

export type MenuMappingItem = {
  title?: string
  description?: string
  plu?: string
  nutritional_info?: unknown
  suspend_until?: string | null
  currency_code?: string
  external_id?: string | null
  is_alcohol?: false
  vat_rate_percentage?: number
  tax_rate?: number
  image_url?: string
  missing_customization_options?: boolean
  price?: number
  subsection_items?: { subsection_id: string; item_price?: number | null }
}

export type MenuMappingSubsectionItem = {
  item_sort_position?: number
  item_price?: number
  item_pickup_price?: number
}

export type MenuMappingItemResource = JsonApiResource<
  TypeName.MenuMappingItem,
  MenuMappingItem,
  MenuMappingItemRelationships
>

export type MenuMappingSubsectionItemResource = JsonApiResource<
  TypeName.MenuMappingSubsectionItem,
  MenuMappingSubsectionItem
>

export type MenuMappingItemRelationships = {
  subsection_items: { data: Array<MenuMappingSubsectionItemResource> }
  item_customizations: { data: Array<MenuMappingCustomization> }
  customizations: { data: Array<MenuMappingCustomization> }
}

export type MenuMappingItemDetails = MenuMappingItem &
  Partial<MenuMappingSubsectionItem> & {
    id: string
    type: string
    item_customizations?: { data: Array<ResourceIdentifier<TypeName.MenuMappingItemCustomization>> }
  }

export type MenuMappingItemInput = {
  data: {
    id?: string
    type: TypeName.MenuMappingItem
    attributes: MenuMappingItem
    relationships?: {
      menu_id?: {
        data: { id: string; type: TypeName.MenuMappingMenu }
      }
      subsection_items?: {
        data: Array<{
          id: string
          type: TypeName.MenuMappingSubsectionItem
        }>
      }
      item_customizations?: {
        data: Array<{
          type: TypeName.MenuMappingCustomization
          id: string
        }>
      }
      customizations?: {
        data: Array<{
          type: TypeName.MenuMappingCustomization
          id: string
        }>
      }
    }
  }
}

/**
 * /menu_mapping/customizations?:id API response type
 */
export type MenuMappingCustomizationResponse = JsonApiResponse<
  MenuMappingCustomizationResource,
  MenuMappingCustomizationOptionResource,
  MenuMappingNestedCustomizationResource
>

export type MenuMappingCustomizationResource = JsonApiResource<
  TypeName.MenuMappingCustomization,
  MenuMappingCustomization,
  MenuMappingCustomizationRelationships
>

export type MenuMappingDefaultCustomizationOption = {
  id: string
  type: string
  title: string
  external_id: string
  suspend_until: string | null
  sort_position?: number
  price?: number
}

export type MenuMappingDefaultCustomizationOptionResource = JsonApiResource<
  TypeName.MenuMappingDefaultCustomizationOption,
  MenuMappingDefaultCustomizationOption
>

type MenuMappingCustomizationRelationships = {
  customization_customization_options?: { data: Array<MenuMappingCustomizationCustomizationOption> }
  customization_options?: { data: Array<MenuMappingCustomizationOption> }
  default_customization_options?: { data: Array<any> }
  item_customizations?: { data: Array<ResourceIdentifier<TypeName.MenuMappingItemCustomization>> }
  items?: { data: Array<ResourceIdentifier<TypeName.Item>> }
  nested_customizations?: {
    data: Array<ResourceIdentifier<TypeName.MenuMappingNestedCustomization>>
  }
}

type MenuMappingCustomizationOptionsRelationships = {
  customizations?: { data: Array<ResourceIdentifier<TypeName.MenuMappingCustomization>> }
  nested_customizations?: { data: Array<MenuMappingNestedCustomization> }
  customization?: { data: MenuMappingCustomization }
  parent_option?: { data: MenuMappingCustomizationOption }
  customization_customization_options?: { data: Array<MenuMappingCustomizationCustomizationOption> }
}

export type MenuMappingCustomizationOptionResource = JsonApiResource<
  TypeName.MenuMappingCustomizationOption,
  MenuMappingCustomizationOption,
  MenuMappingCustomizationOptionsRelationships
>

export type MenuMappingNestedCustomizationResource = JsonApiResponse<
  TypeName.MenuMappingNestedCustomization,
  MenuMappingNestedCustomization,
  MenuMappingNestedCustomizationRelationships
>

type MenuMappingNestedCustomizationRelationships = {
  customization: { data: MenuMappingCustomizationResource }
  parent_option: { data: MenuMappingCustomizationOptionResource }
}

export type MenuMappingCustomization = {
  id: string
  type: string
  title?: string
  max_permitted?: number
  min_permitted?: number
  external_id?: string
}

export type MenuMappingCustomizationAttributes = {
  title?: string
  max_permitted?: number
  min_permitted?: number
  external_id?: string
}

export type MenuMappingNestedCustomization = {
  id: string
  type: TypeName.MenuMappingNestedCustomization
  attributes: {
    sort_position?: number
  }
  relationships: MenuMappingNestedCustomizationRelationships
}

export type MenuMappingCustomizationOption = {
  id: string
  type: string
  title: string
  external_id: string
  suspend_until: string | null
  sort_position?: number
  price?: number
}

export type MenuMappingCustomizationOptionAttributes = {
  title?: string
  external_id?: string
  suspend_until?: string | null
  sort_position?: number
  price?: number
  customization_customization_options?: {
    customization_id: string
    customization_option_price?: number
  }
}

type MenuMappingCustomizationCustomizationOptionRelationships = {
  customization: { data: MenuMappingCustomizationResource }
  customization_option: { data: MenuMappingCustomizationOptionResource }
}

export type MenuMappingCustomizationCustomizationOption = {
  id: string
  type: string
  attributes: {
    customization_option_pickup_price: number
    customization_option_price: number
    customization_option_sort_position: number
  }
  relationships: MenuMappingCustomizationCustomizationOptionRelationships
}

export type MenuMappingItemCustomization = {
  id: string
  customization_sort_position: number
  min_permitted: number
  max_permitted: number
}

type MenuMappingItemCustomizationRelationships = {
  customization: { data: MenuMappingCustomizationResource }
  item: { data: MenuMappingItemResource }
}

export type MenuMappingItemCustomizationResource = JsonApiResource<
  TypeName.MenuMappingItemCustomization,
  MenuMappingItemCustomization,
  MenuMappingItemCustomizationRelationships
>

export type ProvisioningStatusResponse = JsonApiResponse<
  JsonApiResource<TypeName.ProvisioningStatus, ProvisioningStatus>
>

export type ProvisioningStatus = {
  error_details: {
    details: string
    doordash_store_uuid: string
    exclusion_code: string
    location_id: string
    onboarding_id: string
    status: string
    menus: []
  }
  aasm_state: string
  pos_integration_enabled: boolean
  online_status: string
  order_release_enabled: boolean
  auto_accept_enabled: boolean
  order_manager_client_id: 'OTHER_REDACTED'
  integration_enabled: boolean
  show_status: boolean
}

export type MenuMappingScheduleResponse = JsonApiResponse<
  MenuMappingScheduleResource,
  MenuMappingServiceAvailabilityResource | MenuMappingTimePeriodResource
>

export type MenuMappingScheduleResource = JsonApiResource<
  TypeName.MenuMappingSchedule,
  MenuMappingSchedule
>

export type MenuMappingScheduleCreateInput = {
  data: {
    type: TypeName.MenuMappingSchedule
    attributes: Record<string, string>
    relationships: {
      schedulable: {
        data: { id: string; type: TypeName.MenuMappingOverrideSection }
      }
      service_availabilities: {
        data: Array<{
          id?: string
          temp_id?: number
          type: TypeName.MenuMappingServiceAvailability
        }>
      }
    }
  }
  included: Array<ServiceAvailability | TimePeriod>
}

export type MenuMappingScheduleUpdateInput = {
  id: string
  data: {
    id: string
    type: TypeName.MenuMappingSchedule
    attributes: Record<string, string>
    relationships: {
      service_availabilities: {
        data: Array<{ temp_id: number; type: TypeName.MenuMappingServiceAvailability }>
      }
    }
  }
  included: Array<
    | {
        type: TypeName.MenuMappingServiceAvailability
        id: string
        attributes: {
          enabled: boolean
          day_of_week: string
        }
        relationships: {
          time_periods: {
            data: Array<{ id: number; type: TypeName.MenuMappingTimePeriod }>
          }
        }
      }
    | {
        type: 'time_period'
        id: string
        attributes: {
          start_time: string // 24-hour time string
          end_time: string // 24-hour time string
        }
        relationships: {
          service_availability: {
            data: { id: number; type: TypeName.MenuMappingServiceAvailability }
          }
        }
      }
  >
}

export type MenuMappingSchedule = {
  kind: string
  title: string
}

export type MenuMappingServiceAvailabilityResource = JsonApiResource<
  TypeName.MenuMappingServiceAvailability,
  MenuMappingServiceAvailability,
  MenuMappingServiceAvailabilityRelationships
>

export type MenuMappingServiceAvailabilityRelationships = {
  time_periods?: {
    data: ResourceIdentifier[]
  }
  service_availabilityable?: {
    data: ResourceIdentifier // type: MenuMappingSection or MenuMappingSchedule
  }
}

export type MenuMappingServiceAvailability = {
  id?: string
  day_of_week: string
  enabled: boolean
}

export type MenuMappingServiceAvailabilityInput = {
  data: {
    id?: string
    type: TypeName.MenuMappingServiceAvailability
    attributes: MenuMappingServiceAvailability
    relationships: MenuMappingServiceAvailabilityRelationships
  }
}

export type MenuMappingTimePeriodResource = JsonApiResource<
  TypeName.MenuMappingTimePeriod,
  MenuMappingTimePeriod,
  MenuMappingTimePeriodRelationships
>

export type MenuMappingTimePeriodInput = {
  data: {
    type: TypeName.MenuMappingTimePeriod
    id?: string
    attributes: {
      start_time?: string
      end_time?: string
    }
    relationships: {
      service_availability?: {
        data: { id: string; type: TypeName.MenuMappingServiceAvailability }
      }
    }
  }
}

export type MenuMappingTimePeriodRelationships = {
  service_availability: {
    data: ResourceIdentifier | null
  }
}

export type MenuMappingTimePeriod = {
  id?: string
  start_time?: string
  end_time?: string
}

export type MenuImportStatusResponse = JsonApiResponse<Array<StatusResource>>

export type MenuItemSuspensionUpdateInput = {
  data: Array<
    | {
        type: TypeName.Items
        actions: {
          suspend: Array<{ id: MenuMappingItemDetails['id']; suspend_until: string | null }>
          unsuspend: Array<MenuMappingItemDetails['id']>
        }
      }
    | {
        type: TypeName.CustomizationOptions
        actions: {
          suspend: Array<{
            id: ItemModifier['id']
            suspend_until: string | null
          }>
          unsuspend: Array<MenuMappingCustomizationOptionResource['id']>
        }
      }
  >
}

interface FailedItems {
  id: MenuMappingItemDetails['id'] | ItemModifier['id']
  type: TypeName.Item | TypeName.CustomizationOption
  error: string
}

interface ModifiedItems {
  id: MenuMappingItemDetails['id']
  type: TypeName.Item
  suspend_until: string | null
}

export type MenuItemSuspensionUpdateResponse = {
  status: 'success' | 'failure' | 'partial_success'
  message: string
  data: {
    modified_items?: Array<ModifiedItems>
    failed_items?: Array<FailedItems>
  }
}

export type Status = {
  success: boolean
  timestamp: string
  category: string
  enqueued_at: string
  created_at: string
}

export type StatusResource = JsonApiResource<TypeName.Status | TypeName.ImportMenuStatus, Status>

/**
 * Square API models
 */
export type SquareUrlResponse = {
  squareUrl: string
}

export type SquareLocation = {
  id: string
  name: string
  merchant_id: string
}

export type SquareLocationsResponse = {
  locations: SquareLocation[]
}

/**
 * ActivationCodes API response
 */
export type ActivationCodeResponse = JsonApiResponse<ActivationCodeResource>

export type ActivationCodeResource = JsonApiResource<TypeName.ActivationCode, ActivationCode>

export type ActivationCode = {
  id: string
  code: string
  url: string
  kind: string
  aasm_state: string
}

export type ItemModifierHeader = {
  customizationHeader: {
    id: string
    title: string
  }
}

export type ItemModifier = {
  id: string
  type: string
  attributes: {
    external_id: string
    suspend_until: string | null
    title: string
    price?: number
  }
  children?: {
    customizations?: ItemModifierGroup[]
  }
  relationships?: ItemModifierHeader
}

export type ItemModifierGroup = {
  id: string
  type: TypeName.MenuMappingCustomization | TypeName.MenuMappingNestedCustomization
  attributes: {
    title?: string
    sort_position?: number
    max_permitted?: number
    min_permitted?: number
  }
  children: {
    customization_options: SectionModifier[]
  }
}

export type ExtendedMenuMappingCustomization = MenuMappingCustomization & {
  customizationOptions?: Array<ItemModifier>
}

export type ExtendedMenuMappingItemDetails = MenuMappingItemDetails & {
  itemCustomizations: Array<MenuMappingCustomization>
}

export type SectionModifier = ItemModifier & {
  items?: Array<ExtendedMenuMappingItemDetails>
}

export type CustomizationOptionsByCustomizationId = {
  [customizationId: string]: Array<ItemModifier>
}

export type CustomizationGroupByCustomizationId = {
  [customizationId: string]: ItemModifierGroup
}

export type MenuPublishResponse = JsonApiResponse<{
  message?: string
  details?: string
}>

export type PartnerPrepTimes = {
  id?: number
  lower_price_limit: number
  upper_price_limit: number
  prep_minutes: number
  kind: string
  _destroy?: boolean
}

export type ServiceFee = {
  service_fee_rate: number
  service_fee_type: string
}

export type HolidayHourAttributes = {
  date: string
  closed: boolean
}

export type HolidayHourTimePeriod = {
  start_time: string
  end_time: string
}

export type HolidayHourCreateInput = {
  data: {
    attributes: HolidayHourAttributes
    time_periods_attributes?: HolidayHourTimePeriod[]
  }
}

export type HolidayHourResource = JsonApiResource<
  TypeName.MenuMappingServiceAvailability,
  HolidayHourAttributes,
  {
    time_periods: {
      data: ResourceIdentifier[]
    }
    service_availabilityable: {
      data: ResourceIdentifier
    }
  }
>

export type HolidayHourResponse = JsonApiResponse<HolidayHourResource>

export type HolidayHoursListResponse = JsonApiResponse<
  HolidayHourResource[],
  MenuMappingTimePeriodResource
>

export type LocationHourTimePeriod = {
  start_time: string
  end_time: string
}

export type LocationHourAttributes = {
  day_of_week: string
  enabled: boolean
  time_periods: LocationHourTimePeriod[]
}

export type LocationHourUpsertInput = {
  data: Array<{
    type: 'location_hour'
    attributes: LocationHourAttributes
  }>
}

export type LocationHourResource = JsonApiResource<
  TypeName.MenuMappingServiceAvailability,
  LocationHourAttributes,
  {
    time_periods: {
      data: ResourceIdentifier[]
    }
    service_availabilityable: {
      data: ResourceIdentifier
    }
  }
>

export type LocationHourResponse = JsonApiResponse<LocationHourResource>

export type LocationHoursListResponse = JsonApiResponse<
  LocationHourResource[],
  MenuMappingTimePeriodResource
>
