import { getGoogleBusinssProfileLocations } from 'api';
import { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { AccountLocationItem } from 'api/digitalMarketing/types';

type UseGetLocationsConfig = (
  partnerId: string,
  marketingChannelId: string,
) => {
  isLoading: boolean
  isError: boolean
  locations?: AccountLocationItem[]
}

const useGetGoogleBusinessLocations: UseGetLocationsConfig = (
  partnerId: string,
  marketingChannelId: string,
) => {
  const [isLoading, setIsLoading] = useState(isEmpty(partnerId))
  const [isError, setIsError] = useState(false)
  const [locations, setLocations] = useState<AccountLocationItem[] | undefined>(undefined)

  const getGBPLocations = useCallback(async () => {
    setIsLoading(true)
    try {
      const googleLocations = await getGoogleBusinssProfileLocations(partnerId, marketingChannelId)
      setLocations(googleLocations)
      setIsLoading(false)
    } catch (err) {
      console.error(err)
      setLocations(undefined)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [marketingChannelId, partnerId])

  useEffect(() => {
    getGBPLocations()
  }, [getGBPLocations])

  return {
    isLoading,
    isError,
    locations,
  }
}

export default useGetGoogleBusinessLocations