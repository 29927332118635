import { getAllPartnerLocations } from 'api'
import { PartnerResource } from 'api/models'
import { Spinner } from 'components'
import { debounce } from 'lodash'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { LocationSummary } from 'types'
import { showError } from 'utils'
import formatLocations from 'utils/formatLocations'
import { v4 as uuidv4 } from 'uuid'

import Chevron_Down from '../../assets/icons/Chevron-Down.svg'
import clearIcon from '../../assets/icons/clear-icon.svg'
import * as Styled from './styles'

type SearchLocationsFieldProps = {
  onSelect: (selected: LocationSummary) => void
  locationId?: string | null
  placeholder?: string
  resetOnSelect?: boolean // Optional: Whether to reset the search term upon selection
  isAdminUser?: boolean
}

const SearchLocationsField: React.FC<SearchLocationsFieldProps> = ({
  onSelect,
  locationId = '',
  placeholder = 'Search for locations',
  resetOnSelect = false,
  isAdminUser = false,
}) => {
  const [filteredLocations, setFilteredLocations] = useState<LocationSummary[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [showDropDown, setShowDropDown] = useState(false)
  const [showSelectedOption, setShowSelectedOption] = useState(true)
  const containerRef = useRef<HTMLDivElement>(null)
  const searchRef = useRef<HTMLInputElement | null>(null)
  const activeRequestId = useRef<string | null>(null)

  const debounceFetch = useCallback(
    debounce(async (term: string, currentPage: number, requestId: string) => {
      setLoading(true)
      try {
        const resultsPerPage = 25
        const response = await getAllPartnerLocations(currentPage, term, {
          'fields[partner]': 'id,name',
        })

        // Ensure this is the latest request
        if (requestId !== activeRequestId.current) return

        const data: PartnerResource[] = response?.data || []

        if (data.length) {
          const newLocations = formatLocations(data)
          setFilteredLocations((prev) =>
            currentPage === 1 ? newLocations : [...prev, ...newLocations],
          )
          setHasMore(currentPage * resultsPerPage < (response?.meta?.total || 0))
        } else {
          setHasMore(false)
        }
      } catch (error) {
        if (requestId === activeRequestId.current) showError(error)
      } finally {
        if (requestId === activeRequestId.current) setLoading(false)
      }
      setLoading(false)
    }, 300),
    [],
  )

  const debounceWithRequestId = (term: string, nextPage: number) => {
    const requestId = uuidv4()
    activeRequestId.current = requestId
    debounceFetch(term, nextPage, requestId)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value
    setFilteredLocations([])
    setSearchTerm(term)
    setPage(1)
    debounceWithRequestId(term, 1)
    setShowDropDown(true)
  }

  const handleDropDownClick = (toggleDropDown: boolean) => {
    toggleDropDown && setShowDropDown(!showDropDown)
    debounceWithRequestId(searchTerm, page)

    setLoading(true)
  }

  const handleScroll = () => {
    if (
      searchRef.current &&
      searchRef.current.scrollTop + searchRef.current.clientHeight >=
        searchRef.current.scrollHeight - 20 &&
      hasMore &&
      !loading
    ) {
      const nextPage = page + 1
      setPage(nextPage)
      debounceWithRequestId(searchTerm, nextPage)
    }
  }

  useEffect(() => {
    const container = searchRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [hasMore, loading])

  const resetSearch = () => {
    setSearchTerm('')
    setShowDropDown(false)
    setShowSelectedOption(true)
    setFilteredLocations([])
    setPage(1)
  }

  const handleOptionSelect = (location: LocationSummary) => {
    onSelect(location)
    setShowDropDown(false)
    setShowSelectedOption(true)
    setSearchTerm('')
    if (resetOnSelect) resetSearch()
  }

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowDropDown(false)
        setShowSelectedOption(true)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={containerRef}>
      <Styled.Search
        type='text'
        value={searchTerm}
        onChange={handleInputChange}
        onClick={() => {
          setShowSelectedOption(false)
          if (!isAdminUser && !searchTerm.length) {
            setShowDropDown(true)
            handleDropDownClick(false)
          }
        }}
        placeholder={showSelectedOption ? placeholder || 'Location Name' : ''}
      />
      {!!searchTerm.length && (
        <Styled.ClearButton alt='clear' src={clearIcon} onClick={resetSearch} />
      )}
      {!isAdminUser && !searchTerm.length && (
        <Styled.ClearButton
          src={Chevron_Down}
          alt='closeIcon'
          onClick={() => handleDropDownClick(true)}
        />
      )}

      {showDropDown && (
        <Styled.SearchResults ref={searchRef} data-testid='search-results'>
          {filteredLocations.map((location) => (
            <Styled.Result
              key={`${location.id}-${uuidv4()}`}
              onClick={() => handleOptionSelect(location)}
              className={location.id.toString() === locationId ? 'active' : ''}
            >
              {location.name}
            </Styled.Result>
          ))}
          {loading && (
            <Styled.Result>
              <Spinner />
            </Styled.Result>
          )}
          {!loading && !filteredLocations.length && searchTerm.length > 0 && (
            <Styled.Result>No results found.</Styled.Result>
          )}
        </Styled.SearchResults>
      )}
    </div>
  )
}

export default SearchLocationsField
