import { Button, Chip, Typography } from '@mui/material'
import styled from 'styled-components'
import { fontFamily, fontWeight } from 'theme'

import { OrderDisplayStatus } from './OrderManagement'

const getStatusStyles = (status: OrderDisplayStatus) => {
  switch (status) {
    case OrderDisplayStatus.COMPLETED:
      return {
        backgroundColor: 'rgba(50, 150, 93, 0.1)',
        color: '#32965D',
      }
    case OrderDisplayStatus.CANCELLED:
      return {
        backgroundColor: 'rgba(193, 48, 48, 0.1)',
        color: '#C13030',
      }
    default:
      return {
        backgroundColor: 'transparent',
        color: '#000',
      }
  }
}

export const StyledChip = styled(Chip)<{ status: OrderDisplayStatus }>(({ status }) => ({
  '&&': {
    fontFamily: fontFamily.inter,
    fontSize: '13px',
    fontWeight: fontWeight.medium,
    borderRadius: '5px',
    padding: 0,
    height: '23px',
    backgroundColor: getStatusStyles(status).backgroundColor,
    color: getStatusStyles(status).color,
    textTransform: 'capitalize',
  },
  '& .MuiChip-icon': {
    color: `${getStatusStyles(status).color} !important`,
    marginLeft: 0,
    marginRight: 0,
  },
}))

export const DrawerHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px',
  borderBottom: 'solid 1px #ccc',

  '& h2': {
    fontFamily: fontFamily.inter,
    fontSize: '20px',
    fontWeight: fontWeight.semibold,
    margin: 0,
  },
})

export const DrawerContent = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column' as const,
  padding: 15,
}))

export const Row = styled.div<{ full?: boolean }>(({ full }) => ({
  display: 'flex',
  padding: '8px 0',
  flexDirection: 'column' as const,
  fontFamily: fontFamily.inter,
  fontSize: 13,
  color: '#292929',
  width: full ? '100%' : '50%',
  fontWeight: fontWeight.medium,
}))

export const RowSingle = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0',
})

export const RowPair = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
})

export const Label = styled.div({
  fontFamily: 'Inter, sans-serif',
  fontSize: '11px',
  marginBottom: 10,
  color: '#777777',
  fontWeight: fontWeight.medium,
})

export const Value = styled.div<{ horizontal?: boolean }>(({ horizontal }) => ({
  textAlign: 'left',
  color: '#292929',
  display: 'flex',
  flexDirection: horizontal ? 'row' : 'column',
  alignItems: horizontal ? 'center' : 'flex-start',
  justifyContent: 'flex-start',
  gap: horizontal ? '8px' : '0',
  marginBottom: 10,
}))

export const WrappedValue = styled(Value)({
  wordBreak: 'break-all',
});

export const PlatformTicket = styled.div({
  textAlign: 'left',
  color: '#292929',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '0',
});

export const CustomLink = styled.a({
  color: '#1B51A4',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
})

export const InfoItem = styled.div({
  marginBottom: '10px',
  textAlign: 'left',
  color: '#292929',
  '& a': {
    color: '#1B51A4',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '&:last-child': {
    marginBottom: '0',
  },
})

export const SubmissionTime = styled(Typography)({
  '&&': {
    fontFamily: fontFamily.inter,
    fontSize: '13px',
    fontWeight: fontWeight.medium,
    color: '#000',
  },
})

export const SubmissionDate = styled(Typography)({
  '&&': {
    fontFamily: fontFamily.inter,
    fontSize: '13px',
    color: '#777777',
  },
})

export const ButtonContainer = styled.div(() => ({
  '&&': {
    display: 'flex',
    marginTop: 30,
  },
}))

export const StyledButton = styled(Button)({
  '&&': {
    backgroundColor: '#fff',
    border: '1px solid #1B51A4',
    borderRadius: '6px',
    color: '#1B51A4',
    boxShadow: 'none',
    fontFamily: fontFamily.inter,
    fontSize: 15,
    fontWeight: fontWeight.semibold,
    marginRight: 30,
    textTransform: 'capitalize',
    '&:hover': {
      boxShadow: 'none',
    },
  },
})
