import RadioGroup from '@mui/material/RadioGroup'
import { PartnerResource } from 'api/models'
import { createHolidayHours } from 'api/partners/holidayHours'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/ellipse-checked.svg'
import { Toast } from 'components'
import PrimaryButton from 'components/modern/PrimaryButton'
import moment, { Moment } from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { InsensitiveBackDropModal } from '../components'
import { getCurrentTime, getTomorrowDate } from '../helpers'
import { BasicHolidayHourConfig } from '../style'
import {
  ClearIcon,
  CustomTimeSectionWrapper,
  DateAlreadySetLabel,
  DateInput,
  DatePickerWrapper,
  DialogActions,
  DialogContent,
  DialogTitle,
  ExpandMoreIcon,
  FormElement,
  ModalContainer,
  SectionLabel,
  StyledRadio,
  StyledTimePicker,
  TimePickersWrapper,
  WarningTimeLabel,
} from './style'

type Props = {
  open: boolean
  partner: PartnerResource
  onSave?: (hour: BasicHolidayHourConfig) => void
  onClose?: () => void
  onCheckHolidayHourExists?: (date: Date) => boolean
}

const disabledStartMinutes = (hour: number) => {
  if (hour === 23) return Array.from({ length: 29 }, (_, i) => i + 31) // Disable minutes 31-59 for 11:00 PM
  if (hour === 0) return [0] // Disable minute 0 for 12:00 AM
  return []
}

const disabledEndMinutes = (hour: number) => {
  if (hour === 0) return [0] // Disable minute 0 for 12:00 AM
  return []
}

enum OperatingMode {
  CLOSED = 'closed',
  CUSTOM = 'custom',
}

const defaultOperationMode = OperatingMode.CLOSED

const AddHoursModal = (props: Props) => {
  const { open, partner, onClose, onSave, onCheckHolidayHourExists } = props
  const partnerId = partner.id;
  const partnerTz = partner.attributes.timezone || ''
  const [selectedDate, setSelectedDate] = useState<Date | null>(getTomorrowDate(partnerTz))
  const [startTime, setStartTime] = useState<Moment | null>(null)
  const [endTime, setEndTime] = useState<Moment | null>(null)
  const [operatingMode, setOperatingMode] = useState<OperatingMode>(defaultOperationMode)
  const [dateAlreadySet, setDateAlreadySet] = useState(false)
  const timePickerPopupRef = useRef<HTMLElement>(null)
  const startTimeAfterEndTime = startTime && endTime && startTime.isAfter(endTime)

  const resetDefaults = () => {
    setStartTime(null)
    setEndTime(null)
    setSelectedDate(getTomorrowDate(partnerTz))
    setOperatingMode(defaultOperationMode)
  }

  const handleClose = () => {
    resetDefaults()
    onClose && onClose()
  }

  const handleSave = async () => {
    if (!isValid()) return

    try {
      const payload = {
        data: {
          attributes: {
            date: moment(selectedDate).format('YYYY-MM-DD'),
            closed: operatingMode === OperatingMode.CLOSED,
          },
          ...(operatingMode === OperatingMode.CUSTOM &&
            startTime &&
            endTime && {
              time_periods_attributes: [
                {
                  start_time: startTime.format('HH:mm'),
                  end_time: endTime.format('HH:mm'),
                },
              ],
            }),
        },
      }

      await createHolidayHours(partnerId, payload)
      onSave &&
        onSave({
          ...payload.data.attributes,
          ...(operatingMode === OperatingMode.CUSTOM &&
            startTime &&
            endTime && {
              timePeriod: {
                startTime: startTime.format('h:mmA'),
                endTime: endTime.format('h:mmA'),
              },
            }),
        })

      handleClose()
    } catch (err: any) {
      console.error(err)
      Toast.error({
        message: 'An error occurred while saving your holiday hours. Please try again.',
      })
    }
  }

  const isValid = () => {
    if (operatingMode === OperatingMode.CUSTOM) {
      return startTime && endTime && !startTimeAfterEndTime && !dateAlreadySet
    }

    return !dateAlreadySet
  }

  const getModalContainer = () => {
    return timePickerPopupRef.current?.parentElement
  }

  const handleTimeChange = (isStart: boolean, time: Moment) => {
    const timeA = isStart ? time : startTime
    const timeB = !isStart ? time : endTime
    if (open && timeA && timeB && timeA.isAfter(timeB)) {
      Toast.error({
        message:
          'Operating hours for your location’s holiday hours values are invalid. please select an end time value later than the start time value.',
      })
    }

    if (isStart) {
      setStartTime(time)
    } else {
      setEndTime(time)
    }
  }

  useEffect(() => {
    if (open && onCheckHolidayHourExists && selectedDate) {
      const alreadyExists = onCheckHolidayHourExists(selectedDate)
      if (alreadyExists) {
        Toast.error({
          message:
            'The date for your location’s holiday hours already exists. Please select a different date or delete the existing holiday hours for that date.',
        })
      }
      setDateAlreadySet(alreadyExists)
    }
  }, [selectedDate, onCheckHolidayHourExists, open])

  return (
    <InsensitiveBackDropModal
      open={open}
      onClose={handleClose}
      slotProps={{
        backdrop: {
          // this reference will be used to mount the time picker popup next to the backdrop
          ref: timePickerPopupRef,
        },
      }}
    >
      <ModalContainer>
        <DialogTitle>What day would you like to adjust your location's holiday hours?</DialogTitle>

        <DialogContent>
          <DatePickerWrapper data-testid='date-picker-wrapper'>
            <DateInput
              selected={selectedDate}
              onChange={(date: Date) => setSelectedDate(date)}
              dateFormat='MMM d yyyy'
              popperPlacement='right-start'
              excludeDateIntervals={[
                {
                  start: new Date(0),
                  end: getCurrentTime(partnerTz),
                },
              ]}
              showIcon
              icon={<CalendarIcon />}
              error={dateAlreadySet}
            />
          </DatePickerWrapper>

          {dateAlreadySet && (
            <DateAlreadySetLabel>
              This date already has <br />
              holiday hours set
            </DateAlreadySetLabel>
          )}

          <SectionLabel>Operating Hours</SectionLabel>
          <RadioGroup
            value={operatingMode}
            onChange={(e) => setOperatingMode(e.target.value as OperatingMode)}
          >
            <FormElement
              value={OperatingMode.CLOSED}
              control={<StyledRadio checkedIcon={<CheckIcon />} />}
              label='Closed all day'
            />

            <CustomTimeSectionWrapper>
              <FormElement
                value={OperatingMode.CUSTOM}
                control={<StyledRadio checkedIcon={<CheckIcon />} />}
                label='Open during a custom time'
              />

              <TimePickersWrapper data-testid='time-pickers-wrapper'>
                <StyledTimePicker
                  showSecond={false}
                  use12Hours
                  placeholder='9:00am'
                  format='h:mmA'
                  onChange={(time) => handleTimeChange(true, time)}
                  // @ts-ignore we target the modal's container to allow the popup to be above the backdrop
                  getPopupContainer={getModalContainer}
                  value={startTime || undefined}
                  inputIcon={!startTime ? <ExpandMoreIcon /> : null}
                  clearIcon={<ClearIcon />}
                  disabled={operatingMode !== OperatingMode.CUSTOM}
                  error={!!startTimeAfterEndTime}
                  disabledMinutes={disabledStartMinutes}
                  hideDisabledOptions
                />

                <span>-</span>

                <StyledTimePicker
                  showSecond={false}
                  use12Hours
                  placeholder='5:00pm'
                  format='h:mmA'
                  onChange={(time) => handleTimeChange(false, time)}
                  value={endTime || undefined}
                  inputIcon={!endTime ? <ExpandMoreIcon /> : null}
                  clearIcon={<ClearIcon />}
                  disabled={operatingMode !== OperatingMode.CUSTOM}
                  error={!!startTimeAfterEndTime}
                  // @ts-ignore we target the modal's container to allow the popup to be above the backdrop
                  getPopupContainer={getModalContainer}
                  disabledMinutes={disabledEndMinutes}
                  hideDisabledOptions
                />
              </TimePickersWrapper>

              {startTimeAfterEndTime && (
                <WarningTimeLabel>Start time cannot be after the end time</WarningTimeLabel>
              )}
            </CustomTimeSectionWrapper>
          </RadioGroup>
        </DialogContent>

        <DialogActions>
          <PrimaryButton onClick={handleClose} variant='text'>
            Cancel
          </PrimaryButton>

          <PrimaryButton onClick={handleSave} disabled={!isValid()}>
            Save
          </PrimaryButton>
        </DialogActions>
      </ModalContainer>
    </InsensitiveBackDropModal>
  )
}

export default AddHoursModal
