import {
  getMarketingChannelByPartnerId,
} from 'api/digitalMarketing'
import { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { MarketingChannelObject } from 'types/digitalMarketing/marketing-channel'

type UseGetMarketingChannelConfig = (
  partnerId: string | null,
) => {
  isLoading: boolean
  isError: boolean
  marketingChannels?: MarketingChannelObject[]
}

const useGetMarketingChannelByPartnerId: UseGetMarketingChannelConfig = (
  partnerId: string | null,
) => {
  const [isLoading, setIsLoading] = useState(isEmpty(partnerId))
  const [isError, setIsError] = useState(false)
  const [marketingChannels, setMarketingChannels] = useState<MarketingChannelObject[] | undefined>(undefined)

  const getMarketingChannel = useCallback(async () => {
    setIsLoading(true)
    try {
      if (!partnerId) return {
        isLoading: false,
        isError: false,
        marketingChannel: undefined,
      }
      const mc = await getMarketingChannelByPartnerId(partnerId)
      setMarketingChannels(mc)
      setIsLoading(false)
    } catch (err) {
      console.error(err)
      setMarketingChannels(undefined)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [partnerId])

  useEffect(() => {
    getMarketingChannel()
  }, [getMarketingChannel])

  return {
    isLoading,
    isError,
    marketingChannels,
  }
}

export default useGetMarketingChannelByPartnerId