import { useLocations } from 'components/MainLayout/MainLayout'
import { useEffect, useState } from 'react'
import { LocationSummary } from 'types'

const useLocationInfo = (locationId?: string | null) => {
  const { locationsData } = useLocations()
  const { locations } = locationsData
  const [locationSummary, setLocationSummary] = useState<LocationSummary>()

  useEffect(() => {
    if (locations.length > 0 && locationId && String(locationSummary?.id) !== locationId) {
      setLocationSummary(locations.find((location) => location.id.toString() === locationId))
    }
  }, [locations, locationSummary, locationId])

  return locationSummary
}

export default useLocationInfo
