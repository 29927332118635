import { Typography } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 16px;
`

export const Label = styled(Typography)`
  line-height: 18px;
  color: #777777;
  font-size: 11px;
  font-family: Inter;
`

export const Value = styled(Typography)`
  font-size: 0.875rem;
  margin-bottom: 8px;
`
export const Time = styled(Typography)`
  font-size: 0.875rem;
  margin-bottom: 8px;
  text-align: right;
`

export const Link = styled.a`
  font-size: 0.95rem;
  color: #1b51a4;
  text-decoration: none;
  display: block;

  &:hover {
    text-decoration: underline;
  }
`

export const Spacer = styled.div`
  height: 16px;
`
