import { algolia } from "config"
import algoliasearch, {SearchClient, SearchIndex} from 'algoliasearch/lite'
import { SearchResponse } from '@algolia/client-search'
import { useCallback, useState, useEffect } from "react"
import { getPrefixedIndexName } from "utils/algolia"
import { getAlgoliaSecuredKey } from "api"

const initializeSearchClient = async (indexName: string, partnerId: string): Promise<SearchClient> => {
  const securedApiKey = await getAlgoliaSecuredKey(indexName, partnerId)
  const searchClient: SearchClient = algoliasearch(algolia.appId, securedApiKey)
  return searchClient
};

/**
 * @param indexName - The name of the Algolia index to search without the environment prefix.
 * e.g: 'Businesses' instead of 'dev_Businesses'
*/
const useSearchAlgoliaIndex = <T>(indexName: string, partnerId: string) => {
  const [index, setIndex] = useState<SearchIndex | null>(null)
  const [isIndexReady, setIsIndexReady] = useState<boolean>(false)

  useEffect(() => {
    if (partnerId) {
      const setupIndex = async () => {
        const client = await initializeSearchClient(getPrefixedIndexName(indexName), partnerId)
        setIndex(client.initIndex(getPrefixedIndexName(indexName)))
        setIsIndexReady(true)
      };
      setupIndex()
    }
  }, [indexName, partnerId])

  /**
   * Searches the configured Algolia index with the given query and options.
   * This function is memoized so it's safe to use it as a dependency in other hooks.
   */
  const search = useCallback(async (
    query: string, 
    options?: Parameters<SearchIndex['search']>[1]
  ): Promise<SearchResponse<T> | undefined> => {
    return await index?.search<T>(query, options)
  }, [index])

  return { search, isIndexReady }
}

export default useSearchAlgoliaIndex