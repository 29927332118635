import { marketingApi } from 'config'
import { isArray } from 'lodash'
import { generatePath } from 'react-router'
import {
  EmailMarketingCampaign,
  EmailMarketingCampaignInput,
  FacebookCampaignInfo,
  FacebookSuggestedCampaign,
  InsightFeedback,
  PauseMetaCampaignResponse,
  TargetableInsight,
} from 'types/digitalMarketing'
import { PauseGoogleCampaignResponse, UserFeedback } from 'types/digitalMarketing/googleAd'
import { GetAssetsResponse } from 'types/digitalMarketing/googleAssets'
import { MarketingChannelObject } from 'types/digitalMarketing/marketing-channel'
import { Questionnaire } from 'types/digitalMarketing/questionnaire'

import client from '../chowly'
import marketingClient from '../marketing'
import { targetableApi } from './routes'
import {
  AccountLocationItem,
  CreateMetaCampaignResponse,
  GetSuggestedCampaignResponse,
  TgtApiResponse,
  UpdateInsightResponse,
  UpdateSuggestedCampaignResponse,
} from './types'

/**
 * A helper function to append the partner id to the url. This is required because
 * all TSA requests must include the partner id in the query params.
 */
const addPartnerId = ({ url, partnerId }: { url: string; partnerId: string }) => {
  const separator = url.includes('?') ? '&' : '?'
  return `${url}${separator}partner_id=${partnerId}`
}

/**
 * Fetches a questionnaire by id
 * @param partnerId the id of the partner producing the request
 * @param id the id of the questionnaire to fetch
 * @returns the questionnaire
 */
const getQuestionnaireById = async (partnerId: string, id: string) => {
  const { data } = await client.get<TgtApiResponse<Questionnaire>>(
    addPartnerId({ url: targetableApi.questionnaireByIdRoute(id), partnerId }),
  )

  return data.result
}

/**
 * Submits feedback for a suggested campaign or insight
 * @param campaignId the id of the campaign or insight
 * @param feedback the feedback to submit
 */
const submitInsightFeedback = async (
  partnerId: string,
  campaignId: string,
  feedback: InsightFeedback,
) => {
  const { data } = await client.post<TgtApiResponse<InsightFeedback>>(
    addPartnerId({ url: targetableApi.submitInsightFeedbackRoute(campaignId), partnerId }),
    feedback,
  )

  return data.result
}

/**
 * Replaces the insight with the data
 * @param id the id of the insight to update
 * @param insight the new insight data
 * @returns the updated insight
 */
const updateInsight = async (partnerId: string, id: string, insight: TargetableInsight) => {
  const { data } = await client.put<UpdateInsightResponse>(
    addPartnerId({ url: targetableApi.updateInsightRoute(id), partnerId }),
    insight,
  )

  return data.result
}

/**
 * Submits feedback for a google ad
 * @param id the id of the google ad to submit feedback for
 * @param feedback the user feedback to submit
 * @param approved whether the ad was approved
 * @returns the updated google ad
 */
const submitGoogleAdFeedback = async (
  partnerId: string,
  id: string,
  feedback: UserFeedback[],
  approved: boolean,
) => {
  const { data } = await client.post<TgtApiResponse<InsightFeedback>>(
    addPartnerId({ url: targetableApi.submitGoogleAdFeedbackRoute(id), partnerId }),
    { feedback, decision: approved ? 'approved' : 'rejected' },
  )

  return data.result
}

/**
 * Updates a suggested campaign
 * @param id the id of the suggested campaign to update
 * @param campaign the new suggested campaign data
 * @returns the updated suggested campaign
 */
const updateSuggestedCampaign = async (
  partnerId: string,
  id: string,
  campaign: FacebookSuggestedCampaign,
) => {
  const { data } = await client.put<UpdateSuggestedCampaignResponse>(
    addPartnerId({ url: targetableApi.updateSuggestedCampaignRoute(id), partnerId }),
    campaign,
  )

  return data.result
}

/**
 * Fetches a suggested campaign by id
 * @param id the id of the suggested campaign to fetch
 * @returns the suggested campaign
 */
const getSuggestedCampaignById = async (partnerId: string, id: string) => {
  const { data } = await client.get<GetSuggestedCampaignResponse>(
    addPartnerId({ url: targetableApi.getSuggestedCampaignRoute(id), partnerId }),
  )

  return data.result.campaigns[0]
}

/**
 * Schedules a google ad for publishing
 * @param partnerId the id of the partner producing the request
 * @param adId the id of the ad to publish
 * @returns a message indicating the ad was scheduled for publishing
 */
const publishGoogleAd = async (partnerId: string, adId: string) => {
  const { data } = await client.post<string>(
    addPartnerId({ url: targetableApi.publishGoogleAdRoute(adId), partnerId }),
  )

  return data
}

/**
 * Takes a suggested campaign and creates a new meta campaign from it with
 * status set to `draft`.
 * @param partnerId the id of the partner producing the request
 * @param campaign the suggested campaign to publish
 * @returns a message indicating the campaign was scheduled for publishing
 */
const createMetaCampaignDraftFromSc = async (
  partnerId: string,
  suggestedCampaign: FacebookSuggestedCampaign,
) => {
  // Remove some invalid properties not required by Meta Campaigns
  const {
    id: scId,
    feedback,
    displayDate,
    displayTime,
    questionnaireId,
    ...cleanCampaign
  } = suggestedCampaign
  const { data } = await client.post<CreateMetaCampaignResponse>(
    addPartnerId({ url: targetableApi.createMetaCampaignRoute(), partnerId }),
    {
      ...cleanCampaign,
      suggestedCampaignId: scId,
      status: 'draft',
    } as Omit<FacebookCampaignInfo, 'id'>,
  )

  return data.result
}

/**
 * Submits a meta campaign for publishing.
 * NOTE: The campaign needs to be in `draft` status.
 * @param partnerId the id of the partner producing the request
 * @param campaign the id of the existing meta campaign to publish
 * @returns the updated meta campaign
 */
const publishMetaCampaign = async (partnerId: string, campaignId: string) => {
  const { data } = await client.put<TgtApiResponse<FacebookSuggestedCampaign>>(
    addPartnerId({ url: targetableApi.publishMetaCampaignRoute(campaignId), partnerId }),
  )

  return data.result
}

/**
 * Fetches Google Search campaign assets for a specified marketing channel and asset type.
 *
 * @param {string} marketingChannelId - The ID of the marketing channel for which to fetch the assets.
 * @param {string} assetType - The type of assets to retrieve (e.g., 'SITELINKS', 'PROMOTIONS').
 * @returns A promise that resolves to the assets data.
 */
const getGoogleSearchCampaignAssets = async (
  partnerId: string,
  marketingChannelId: string,
  assetType: string,
) => {
  const { data } = await client.get<GetAssetsResponse>(
    addPartnerId({
      url: targetableApi.getGoogleSearchCampaignAssetsRoute(marketingChannelId, assetType),
      partnerId,
    }),
  )
  return data
}

/**
 * Pauses or resumes a Google Ads campaign.
 * @param {string} partnerId - The ID of the partner producing the request.
 * @param {string} adId - The ID of the Google Ads campaign to pause or resume.
 * @param {boolean} pause - Whether the campaign should be paused (`true`) or resumed (`false`).
 * @returns A promise that resolves to the response data.
 */
const pauseOrResumeGoogleAd = async (partnerId: string, adId: string, pause: boolean) => {
  const { data } = await client.post<TgtApiResponse<PauseGoogleCampaignResponse>>(
    addPartnerId({ url: targetableApi.pauseOrResumeGoogleAdRoute(adId, pause), partnerId }),
  )
  return data
}

/**
 * Pauses or resumes a Meta Ad campaign.
 * @param {string} partnerId - The ID of the partner producing the request.
 * @param {string} adId - The ID of the Meta Ad campaign to pause or resume.
 * @returns A promise that resolves to the response data.
 */
const pauseOrResumeMetaAd = async (partnerId: string, adId: string) => {
  const { data } = await client.post<TgtApiResponse<PauseMetaCampaignResponse>>(
    addPartnerId({ url: targetableApi.pauseOrResumeMetaAdRoute(adId), partnerId }),
  )
  return data
}

/**
 * Gets GBP locations for a given marketing channel id.
 * @param {string} partnerId - The ID of the partner producing the request.
 * @param {string} marketingChannelId - The ID of the Marketing Channel.
 * @returns A promise that resolves to the response data.
 */

type GBPLocationsResponse<T> = {
  locations: T
}

const getGoogleBusinssProfileLocations = async (partnerId: string, marketingChannelId: string) => {
  const { data } = await client.get<GBPLocationsResponse<AccountLocationItem[]>>(
    addPartnerId({ url: targetableApi.getGoogleBgpLocationsRoute(marketingChannelId), partnerId }),
  )
  return data?.locations
}

/**
 * Gets GBP profile for a given marketing channel id.
 * @param {string} partnerId - The ID of the partner producing the request.
 * @param {string} marketingChannelId - The ID of the Marketing Channel.
 * @returns A promise that resolves to the GBP profile data.
 */
const getGoogleBusinessProfile = async (partnerId: string, marketingChannelId: string) => {
  const { data } = await client.get(
    addPartnerId({
      url: targetableApi.getGoogleBusinessProfileRoute(marketingChannelId),
      partnerId,
    }),
  )
  return data
}

/**
 * Gets Marketing channel integrated with GBP.
 * @param {string} partnerId - The ID of the partner producing the request.
 * @returns A promise that resolves to the response data.
 */
type MarketingChannelsResponse<T> = {
  marketingChannels: T
}
const getMarketingChannelByPartnerId = async (partnerId: string) => {
  const { data } = await client.get<
    TgtApiResponse<MarketingChannelsResponse<MarketingChannelObject[] | undefined>>
  >(addPartnerId({ url: targetableApi.getMarketingChannelByPartnerIdRoute(), partnerId }))
  return data?.result?.marketingChannels
}

/**
 * Generates a link where it displays a consent screen for GBP.
 * @param {string} partnerId - The ID of the partner producing the request.
 * @param {string} rccCallbackUrl - the callback url where the google consent screen is going to redirect to.
 * @returns A promise that resolves to the response data.
 */
const generateConsentLink = async (rccCallbackUrl: string, partnerId: string) => {
  const { data } = await client.post<{ url: string }>(
    addPartnerId({ url: targetableApi.getGenerateConsentLinkRoute(), partnerId }),
    { rccCallbackUrl },
  )
  return data.url
}

/**
 * Updates a marketing channel.
 * @param {string} partnerId - The ID of the partner producing the request.
 * @param {string} marketingChannelId - the id of the marketing channel.
 * @returns response data.
 */
type GBPProps = {
  googleBusinessProfile: {
    accountId: string
    locationId: string
  }
}
const updateMarketingChannel = async (
  marketingChannelId: string,
  props: GBPProps,
  partnerId: string,
) => {
  const { data } = await client.put<TgtApiResponse<MarketingChannelObject>>(
    addPartnerId({ url: targetableApi.updateMarketingChannelRoute(marketingChannelId), partnerId }),
    props,
  )
  return data?.result
}

const getEmailMarketingCampaigns = async (organizationId: string, status?: string) => {
  const url = generatePath(marketingApi.campaigns, { organizationId })
  const params = {
    ...(status ? { 'q[aasm_state_eq]': status } : {}),
  }
  const { data } = await marketingClient.get<EmailMarketingCampaign[]>(url, { params })
  return data
}

const updateEmailMarketingCampaign = async (
  organizationId: string,
  id: string,
  body: EmailMarketingCampaignInput,
) => {
  const url = generatePath(marketingApi.updateCampaign, { organizationId, id })
  const { data } = await marketingClient.put<EmailMarketingCampaign>(url, body)

  return data
}

/**
 * Fetches a secured kery
 * @returns the secured key
 */
type AlgoliaResponse<T> = {
  securedApiKey: T
}
const getAlgoliaSecuredKey = async (indexName: string | string[], partnerId: string) => {
  const { data } = await client.get<TgtApiResponse<AlgoliaResponse<string>>>(
    addPartnerId({ url: targetableApi.getAlgoliaSecuredKey(), partnerId}),
    {
      params: { indexName: isArray(indexName) ? indexName.join(',') : indexName },
    },
  )

  return data.result?.securedApiKey
}

export {
  getQuestionnaireById,
  submitInsightFeedback,
  updateInsight,
  submitGoogleAdFeedback,
  updateSuggestedCampaign,
  getSuggestedCampaignById,
  publishGoogleAd,
  createMetaCampaignDraftFromSc,
  publishMetaCampaign,
  getGoogleSearchCampaignAssets,
  pauseOrResumeGoogleAd,
  pauseOrResumeMetaAd,
  getGoogleBusinssProfileLocations,
  getGoogleBusinessProfile,
  getMarketingChannelByPartnerId,
  generateConsentLink,
  updateMarketingChannel,
  getEmailMarketingCampaigns,
  updateEmailMarketingCampaign,
  getAlgoliaSecuredKey,
}
