import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  Menu,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  Tabs,
} from '@mui/material'
import { cancelOrder, getOrderReport, refundOrder } from 'api/orderManagement'
import {
  Link,
  Meta,
  ModalConfig,
  OrderReportResponse,
  PaymentProvider,
  ReportingOrder,
} from 'api/orderManagement/types'
import Truncated from 'components/Truncated/Truncated'
import { aperitifUrl } from 'config'
import { useRouteLocationId } from 'hooks'
import { useAuth0 } from 'libs/auth0-react'
import React, { useEffect, useState } from 'react'
import { fontFamily } from 'theme'
import { PlatformName } from 'types'
import { formatSubmissionDate, formatSubmissionTime } from 'utils'

import OrderModal from './Modals/OrderModal'
import OrderDetailsDrawer from './OrderDetailsDrawer'
import * as Styled from './OrderManagementStyles'

export enum OrderDisplayStatus {
  ALL = '',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export const statusConfig: Record<OrderDisplayStatus, { color: string; icon: JSX.Element }> = {
  [OrderDisplayStatus.COMPLETED]: { color: 'green', icon: <CheckIcon fontSize='small' /> },
  [OrderDisplayStatus.CANCELLED]: { color: 'red', icon: <CloseIcon fontSize='small' /> },
  [OrderDisplayStatus.ALL]: { color: 'gray', icon: <></> },
}

export const mapOrderStatus = (status: string): OrderDisplayStatus => {
  switch (status) {
    case 'completed':
      return OrderDisplayStatus.COMPLETED
    case 'cancelled':
      return OrderDisplayStatus.CANCELLED
    default:
      return OrderDisplayStatus.ALL
  }
}

const TableComponent = () => {
  const partnerId = useRouteLocationId()
  const { user } = useAuth0()

  const [statusTab, setStatusTab] = useState<OrderDisplayStatus>(OrderDisplayStatus.ALL)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [page, setPage] = useState(0)
  const [data, setData] = useState<ReportingOrder[]>([])
  const [meta, setMeta] = useState<Meta>({
    limit: `${rowsPerPage}`,
    page: 1,
    page_total: 1,
    total: 0,
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedRow, setSelectedRow] = useState<number | null>(null)
  const [link, setLink] = useState<Link>({
    first: null,
    prev: null,
    self: null,
    next: null,
    last: null,
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedModalOrder, setSelectedModalOrder] = useState<ReportingOrder | null>(null)
  const [modalType, setModalType] = useState<keyof typeof modalConfigMap | ''>('')

  const isAdminUser = user?.email?.includes('@chowlyinc.com')

  const modalConfigMap: Record<string, ModalConfig> = {
    cancel: {
      title: (orderId: string) => `Cancel Order #${orderId}`,
      description: 'Are you sure you want to cancel this order? This action cannot be undone.',
      submitText: 'Cancel Order',
      action: cancelOrder,
    },
    refund: {
      title: (orderId: string) => `Refund Order #${orderId}`,
      description:
        'Are you sure you want to refund this order? Once refunded, this action cannot be undone.',
      submitText: 'Refund Order',
      action: refundOrder,
    },
  }

  const handleModalOpen = (order: ReportingOrder, type: keyof typeof modalConfigMap) => {
    setSelectedModalOrder(order)
    setModalType(type)
    setIsModalOpen(true)
  }

  const handleModalClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation()
    setIsModalOpen(false)
    setSelectedModalOrder(null)
    setModalType('')
  }

  const handleModalAction = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (selectedModalOrder && modalType) {
      await modalConfigMap[modalType].action(selectedModalOrder.attributes.internal_order_id)
      setIsModalOpen(false)
    }
  }

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState<ReportingOrder | null>(null)

  const fetchOrderReport = async (link: string | null = null) => {
    if (!partnerId) {
      return
    }

    try {
      const response: OrderReportResponse = await getOrderReport({
        partnerId,
        link,
        limit: rowsPerPage,
        filter: statusTab,
      })
      const { data: orders, links, meta } = response

      setLink(links)
      setData(orders)
      setMeta(meta)
    } catch (error) {
      console.error('Error fetching order report:', error)
    }
  }

  useEffect(() => {
    fetchOrderReport()
  }, [statusTab, rowsPerPage, partnerId])

  const handleTabChange = (event: React.SyntheticEvent, newValue: OrderDisplayStatus) => {
    setStatusTab(newValue)
    setPage(0)
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, rowIndex: number) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setSelectedRow(rowIndex)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSelectedRow(null)
  }

  const handleRowClick = (_event: React.MouseEvent<HTMLTableRowElement>, order: ReportingOrder) => {
    setSelectedOrder(order)
    setIsDrawerOpen(true)
  }

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false)
    setSelectedOrder(null)
  }

  const handleChangePage = (event: any, newPage: number) => {
    if (newPage > page && link.next) {
      fetchOrderReport(link.next)
    } else if (newPage < page && link.prev) {
      fetchOrderReport(link.prev)
    } else {
      fetchOrderReport(link.self)
    }
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Styled.Container>
      <Tabs
        value={statusTab}
        onChange={handleTabChange}
        TabIndicatorProps={{ style: { display: 'none' } }}
        textColor='primary'
      >
        <Styled.StyledTab
          label='All'
          value={OrderDisplayStatus.ALL}
          active={statusTab === OrderDisplayStatus.ALL}
        />
        <Styled.StyledTab
          label='Completed'
          value={OrderDisplayStatus.COMPLETED}
          active={statusTab === OrderDisplayStatus.COMPLETED}
        />
        <Styled.StyledTab
          label='Cancelled'
          value={OrderDisplayStatus.CANCELLED}
          active={statusTab === OrderDisplayStatus.CANCELLED}
        />
      </Tabs>

      <TableContainer>
        <Table>
          <TableHead>
            <Styled.StyledTableRow>
              <Styled.MobileVisibleTableHeader>Platform Ticket ID</Styled.MobileVisibleTableHeader>
              <Styled.CustomTableCell></Styled.CustomTableCell>
              <Styled.CustomTableCell>Platform</Styled.CustomTableCell>
              <Styled.MobileVisibleTableHeader>Submission Time</Styled.MobileVisibleTableHeader>
              <Styled.CustomTableCell>POS Ticket ID </Styled.CustomTableCell>
              <Styled.MobileVisibleTableHeader>Customer Name</Styled.MobileVisibleTableHeader>
              <Styled.CustomTableCell>Delivery Address</Styled.CustomTableCell>
              <Styled.CustomTableCell>Actions</Styled.CustomTableCell>
            </Styled.StyledTableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row, index) => {
                const {
                  order_platform_order_id,
                  order_status,
                  platform_name,
                  submission_time,
                  pos_order_id,
                  customer_first_name,
                  customer_last_name,
                  delivery_street,
                  internal_order_id,
                } = row.attributes

                const [streetAddress, ...remainingAddress] = delivery_street.split(',')

                return (
                  <Styled.StyledTableRow
                    key={row.id}
                    onClick={(event) => handleRowClick(event, row)}
                  >
                    <Styled.MobileVisibleTableCell>
                      {order_platform_order_id ? (
                        <Truncated text={order_platform_order_id} />
                      ) : null}
                      <Styled.StyledChip
                        label={mapOrderStatus(order_status)}
                        icon={statusConfig[mapOrderStatus(order_status) as OrderDisplayStatus].icon}
                        status={mapOrderStatus(order_status)}
                        size='small'
                        shouldHideOnDesktop
                      />
                    </Styled.MobileVisibleTableCell>

                    <Styled.CustomTableCell>
                      {statusConfig[mapOrderStatus(order_status)] && (
                        <Styled.StyledChip
                          label={mapOrderStatus(order_status)}
                          icon={
                            statusConfig[mapOrderStatus(order_status) as OrderDisplayStatus].icon
                          }
                          status={mapOrderStatus(order_status)}
                          size='small'
                        />
                      )}
                    </Styled.CustomTableCell>

                    {isAdminUser ? (
                      <Styled.CustomTableCell>
                        <Styled.CustomLink
                          href={`${aperitifUrl}/orders/${internal_order_id}`}
                          target='_blank'
                          rel='noreferrer'
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                        >
                          {platform_name}
                        </Styled.CustomLink>
                      </Styled.CustomTableCell>
                    ) : (
                      <Styled.CustomTableCell>{platform_name}</Styled.CustomTableCell>
                    )}

                    <Styled.MobileVisibleTableCell>
                      <Styled.SubmissionTime>
                        {formatSubmissionTime(submission_time)}
                      </Styled.SubmissionTime>
                      <Styled.SubmissionDate variant='body2'>
                        {formatSubmissionDate(submission_time)}
                      </Styled.SubmissionDate>
                    </Styled.MobileVisibleTableCell>

                    <Styled.CustomTableCell>{pos_order_id}</Styled.CustomTableCell>

                    <Styled.MobileVisibleTableCell>
                      {`${customer_first_name} ${customer_last_name}`}
                    </Styled.MobileVisibleTableCell>

                    <Styled.CustomTableCell>
                      {streetAddress.length ? (
                        <Styled.Address>{streetAddress}</Styled.Address>
                      ) : (
                        <Styled.EmptyAddress>N/A</Styled.EmptyAddress>
                      )}
                      {remainingAddress.length ? (
                        <Styled.Address>{remainingAddress}</Styled.Address>
                      ) : null}
                    </Styled.CustomTableCell>

                    {row.attributes.platform_name === PlatformName.COO && (
                      <Styled.CenterTableCell>
                        <Styled.CenteredIconButton
                          onClick={(e) => handleMenuOpen(e, index)}
                          data-testid='more-button'
                        >
                          <MoreHorizIcon sx={{ fontSize: 13 }} />
                        </Styled.CenteredIconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={selectedRow === index}
                          onClose={handleMenuClose}
                        >
                          <Styled.MenuItems
                            onClick={(event) => {
                              event.stopPropagation()
                              handleModalOpen(row, 'cancel')
                              handleMenuClose()
                            }}
                          >
                            Cancel Order
                          </Styled.MenuItems>
                          {row.attributes.payment_provider === PaymentProvider.stripe && (
                            <Styled.MenuItems
                              onClick={(event) => {
                                event.stopPropagation()
                                handleModalOpen(row, 'refund')
                                handleMenuClose()
                              }}
                            >
                              Refund Order
                            </Styled.MenuItems>
                          )}
                        </Menu>
                      </Styled.CenterTableCell>
                    )}
                  </Styled.StyledTableRow>
                )
              })
            ) : (
              <Styled.StyledTableRow>
                <Styled.CustomTableCell colSpan={7} align='center'>
                  No data available
                </Styled.CustomTableCell>
              </Styled.StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <OrderDetailsDrawer
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
        order={selectedOrder}
        onModalOpen={handleModalOpen}
      />
      <Styled.PaginationContainer>
        <TablePagination
          component='div'
          count={meta.total}
          page={page}
          onPageChange={(event, newPage) => {
            handleChangePage(event, newPage)
          }}
          slotProps={{
            actions: {
              previousButton: {
                disabled: !link?.prev,
              },
              nextButton: {
                disabled: !link?.next,
              },
            },
          }}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Rows per page'
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          sx={{
            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-caption, & .MuiTablePagination-input, & .MuiTablePagination-select':
              {
                fontFamily: fontFamily.inter,
                fontSize: 13,
              },
          }}
        />
      </Styled.PaginationContainer>
      {selectedModalOrder && modalType && (
        <OrderModal
          isOpen={isModalOpen}
          title={modalConfigMap[modalType].title(
            selectedModalOrder.attributes.order_platform_order_id,
          )}
          description={modalConfigMap[modalType].description}
          submitText={modalConfigMap[modalType].submitText}
          onClose={handleModalClose}
          onRefund={handleModalAction}
          order={selectedModalOrder}
        />
      )}
    </Styled.Container>
  )
}

export default TableComponent
