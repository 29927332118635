import { useState } from 'react'
import { useRouteLocationId } from 'hooks'
import PublishedCampaigns from './PublishedCampaigns/PublishedCampaigns'
import EmailMarketingCampaigns from './EmailMarketingCampaigns/EmailMarketingCampaigns'
import {
  PageContainer,
  CampaignsContainer,
  Tab,
  Tabs,
  TabsContainer,
  ToDoContainer,
  ToDoTitle,
} from './styles'
import ToDo from './ToDo/ToDo'

const DigitalMarketing = () => {
  const partnerId = useRouteLocationId()
  const [currentTabFilter, setCurrentTabFilter] = useState('paid_ads');

  const isActive = (scope: string) => {
    if (currentTabFilter === scope) return true;
    return false;
  }

  return (
    <PageContainer>
      <ToDoContainer>
        <ToDoTitle>Digital Marketing To-Do</ToDoTitle>
        {partnerId && <ToDo partnerId={partnerId} />}
      </ToDoContainer>

      <TabsContainer>
        <Tabs>
          <Tab active={isActive('paid_ads')} onClick={() => setCurrentTabFilter('paid_ads')}>Paid Ads</Tab>
        </Tabs>
        <Tabs>
          <Tab active={isActive('email')} onClick={() => setCurrentTabFilter('email')}>Email</Tab>
        </Tabs>
      </TabsContainer>

      <CampaignsContainer>
        {partnerId && currentTabFilter === 'paid_ads' && <PublishedCampaigns partnerId={partnerId} />}
        {partnerId && currentTabFilter === 'email' && <EmailMarketingCampaigns partnerId={partnerId} />}
      </CampaignsContainer>
    </PageContainer>
  )
}

export default DigitalMarketing
