import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material'
import { PrepTimesResource, updatePrepTimes } from 'api'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import outline_minus_button from 'assets/icons/outline_minus_button.svg'
import Chevron_Down from 'assets/icons/Chevron-Down.svg'
import InfoCircle from 'assets/icons/info-circle.svg'
import { AlertCircle, InfoToolTip, ErrorMessage, Text, SubText, AddTier, Cancel, EditorDescription, Footer, Icon, InputContainer, OrderThrottleHeader, OrderThrottleSubHeader, PrimaryButton, SubtotalHeaderContainer, SubtotalStart, Tiers, Title, SubtotalContainer, Divider } from './styles'

type Tier = {
    lower_price_limit: number
    upper_price_limit: number
    prep_minutes: number // prep time for each tier must increment by at least 5 minutes. And should be a min of 25
    id?: number // tiers with out an `id` are created (and given an id) when sent to the BE
    _destroy?: boolean // tiers with `_destroy` are deleted when sent to the BE
    subtotalError: string
    prepTimeError: string
    tempId: number // newly added tiers are given a tempId for ref. This will not be sent to the BE
}

interface Tiers {
    [id: string]: Tier
}

type Props = {
    partnerId: string | undefined
    platformPrepTimes: PrepTimesResource[] | []
    showModal: boolean
    toggleModal: () => void
}

export const OrderThrottleEditor = ({
    partnerId,
    platformPrepTimes,
    showModal,
    toggleModal,
}: Props) => {
    const [tiers, setTiers] = useState<Tiers>({}) // stores all tiers incl. tiers pending delete (`_destroy: true`)
    const [displayTiers, setDisplayTiers] = useState<Tier[]>([])
    let userInput: number

    /* Ensure uuid is always a number */
    const numericUuid = () => {
        const numericString = uuidv4().replace(/[^0-9]/g, '')
        const trimmedString = numericString.slice(0, 15)
        return parseInt(trimmedString, 10)
    }

    useMemo(() => {
        if (platformPrepTimes.length > 0) {
            // set prep times pulled from DB
            const setInitalTiers: { [key: number]: any } = {}
            platformPrepTimes.forEach((p) => {
                const tempId = numericUuid() // new tiers wont have DB id's, so create a tempId.
                setInitalTiers[parseInt(p.id)] = {
                    id: p.id,
                    lower_price_limit: p.attributes.lower_price_limit,
                    upper_price_limit: p.attributes.upper_price_limit,
                    prep_minutes: p.attributes.prep_minutes,
                    kind: 'price_based',
                    _destroy: p.attributes._destroy,
                    tempId
                }
                return
            })
            setTiers(setInitalTiers)
        } else {
            // if no prep times returned from DB, the first tier displayed will have default values
            const tempId = numericUuid() // new tiers wont have DB id's, so create a tempId.
            setTiers({
                [tempId]: {
                    lower_price_limit: 0,
                    upper_price_limit: 0,
                    prep_minutes: 25,
                    subtotalError: '',
                    prepTimeError: '',
                    tempId,
                },
            })
            return
        }
    }, [platformPrepTimes, updatePrepTimes])

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        tierId: number,
    ) => {
        if (!tierId) return

        e.preventDefault()
        userInput = parseFloat(e.target.value) || 0
        const currentTierIndex = displayTiers.indexOf(tiers[tierId])
        let tierToUpdateId: number | undefined = undefined
        if (displayTiers[currentTierIndex + 1]?.id || displayTiers[currentTierIndex + 1]?.tempId) {
            tierToUpdateId = displayTiers[currentTierIndex + 1]?.id || displayTiers[currentTierIndex + 1]?.tempId
        }

        /** increment the total for the next tier by $1 to account for the entire range. Users must input whole numbers */
        const newTotal = userInput + 1

        const validationError =
            userInput <= tiers[tierId].lower_price_limit
                ? 'Upper price limit must be greater than lower price limit.'
                : displayTiers[currentTierIndex - 1] &&
                    userInput <= displayTiers[currentTierIndex - 1].upper_price_limit
                    ? 'Upper price limit must be greater than the previous tier.'
                    : ''

        setTiers((prevTiers) => ({
            ...prevTiers,
            [tierId]: {
                ...prevTiers[tierId],
                upper_price_limit: userInput,
                subtotalError: validationError,
            },
            ...(tierToUpdateId &&
                prevTiers[tierToUpdateId] && {
                [tierToUpdateId]: {
                    ...prevTiers[tierToUpdateId],
                    lower_price_limit: validationError
                        ? prevTiers[tierToUpdateId].lower_price_limit
                        : newTotal,
                },
            }),
        }))
    }

    const handlePrepTimeChange = (e: SelectChangeEvent<number>, tierId: number) => {
        if (!tierId) return
        e.preventDefault()
        const currentTierIndex = displayTiers.indexOf(tiers[tierId])
        const prevTierPrepTime = displayTiers[currentTierIndex - 1]?.prep_minutes || 0

        const validationError =
            parseInt(e.target.value.toString()) <= prevTierPrepTime
                ? 'Prep time must be greater than the previous tier.'
                : ''

        /* Update selected value for dropdown */
        setTiers((prevTiers) => ({
            ...prevTiers,
            [tierId]: {
                ...prevTiers[tierId],
                prep_minutes: parseInt(e.target.value.toString()),
                prepTimeError: validationError,
            },
        }))
    }

    const addTier = () => {
        const lastTier = Object.values(tiers)[Object.values(tiers).length - 1]
        const tempId = lastTier?.id
            ? lastTier.id + 1
            : lastTier?.tempId
                ? lastTier.tempId + 1
                : numericUuid()
        const startPrepTime = lastTier ? lastTier.prep_minutes + 5 : 25
        const startPrice = lastTier?.upper_price_limit > 0 ? lastTier?.upper_price_limit + 1 : 0

        setTiers((prevTiers) => ({
            ...prevTiers,
            [tempId]: {
                lower_price_limit: startPrice,
                upper_price_limit: 0,
                prep_minutes: startPrepTime,
                subtotalError: '',
                prepTimeError: '',
                tempId,
            },
        }))
    }

    const removeTier = (tierId: number | undefined, tempId: number) => {
        if (tierId) {
            // if tier has an id, it exists in the DB and needs to be removed via `_destroy: true`
            setTiers((prevTiers) => ({
                ...prevTiers,
                [tierId]: {
                    ...prevTiers[tierId],
                    _destroy: true,
                },
            }))
        } else {
            // if tier has no id, it can be filtered out of the payload
            setTiers((prevTiers) => {
                // Filter out the tier to remove
                const newTiersArray = Object.entries(prevTiers).filter(
                    ([key]) => key !== tempId?.toString(),
                )
                // Create a new object with updated indexes
                const newTiers: Tiers = {}
                newTiersArray.forEach(([_, value]) => {
                    const currentId = value.id || value.tempId
                    newTiers[currentId] = value
                })
                return newTiers // Return the new tiers object
            })
        }
        return
    }

    useEffect(() => {
        const displayArray: Tier[] = []
        Object.values(tiers).map((t) => {
            if (!t._destroy) {
                displayArray.push(t)
            }
        })
        setDisplayTiers(displayArray)
    }, [tiers])

    const formatTiers = () => {
        const formattedTiers = Object.keys(tiers).map((t) => {
            const currentTier = tiers[parseInt(t)]
            return {
                id: currentTier?.id,
                lower_price_limit: currentTier.lower_price_limit,
                upper_price_limit: currentTier.upper_price_limit,
                prep_minutes: currentTier.prep_minutes,
                kind: 'price_based',
                _destroy: currentTier._destroy,
            }
        })
        return {
            prep_times_attributes: [...formattedTiers],
        }
    }

    // If any validation fails, set the updated tiers with errors and show a message
    const validateOnSave = () => {
        let isValid = true
        const updatedTiers = { ...tiers } // Create a copy of the tiers object to avoid directly mutating state

        // Perform validation for each tier
        Object.keys(updatedTiers).forEach((key, index) => {
            const tier = updatedTiers[key]
            const prevTier = updatedTiers[Object.keys(updatedTiers)[index - 1]] // Get the previous tier

            // Reset error messages before validation
            tier.subtotalError = ''
            tier.prepTimeError = ''

            // Validate that the upper price limit is greater than the lower price limit
            if (tier.upper_price_limit <= tier.lower_price_limit) {
                tier.subtotalError = `Upper price limit must be greater than the lower price limit.`
                isValid = false
            }

            // Validate that the upper price limit for this tier is greater than the previous tier's upper price limit
            if (prevTier && tier.upper_price_limit <= prevTier.upper_price_limit) {
                tier.subtotalError = `Upper price limit must be greater than the upper price limit for the previous tier.`
                isValid = false
            }

            // Validate that the prep time for this tier is greater than the previous tier's prep time
            if (prevTier && tier.prep_minutes <= prevTier.prep_minutes) {
                tier.prepTimeError = `Prep time must be greater than the prep time for the previous tier.`
                isValid = false
            }

            // Validate that upper and lower price limits are not empty or NaN
            if (isNaN(tier.upper_price_limit)) {
                tier.subtotalError = `Upper and lower price limits must be valid numbers.`
                isValid = false
            }
        })
        return {
            isValid,
            updatedTiers,
        }
    }

    const handleSave = async () => {
        if (!partnerId) return

        const { isValid, updatedTiers } = validateOnSave()
        if (!isValid) {
            setTiers(updatedTiers)
            toast.error(`Error updating prep times`, { autoClose: 5000 })
            return
        }

        // If all validations pass, format & save the data
        const formattedTiers = formatTiers()
        try {
            await updatePrepTimes(parseInt(partnerId), formattedTiers)
            toggleModal()
        } catch (e) {
            toast.error(`Error updating prep times: ${e}`, { autoClose: 5000 })
        }
    }

    return (
        <Dialog
            data-testid={'orderThrottleEditor'}
            open={showModal}
            onClose={toggleModal}
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    gap: '40px',
                    padding: '32px',
                    maxWidth: '600px',
                    maxHeight: '623px'
                },
            }}
        >
            <DialogTitle sx={{ padding: 0 }}>
                <OrderThrottleHeader>
                    <OrderThrottleSubHeader>
                        <Title>Order Throttling</Title>
                        <IconButton
                            aria-label='close'
                            onClick={toggleModal}
                            sx={{ color: '#000000' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </OrderThrottleSubHeader>

                    <EditorDescription>
                        Order Throttling enables dynamic preparation time adjustments based on order subtotals.
                    </EditorDescription>
                </OrderThrottleHeader>

            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    border: 'none',
                    padding: 0
                }}
            >
                <Box sx={{
                    mt: 2, gap: '16px',
                    flexDirection: 'column',
                    display: 'flex'
                }}>
                    {displayTiers.map((tier, index) => (
                        <div key={tier.id}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: '8px' }}>
                                <Tiers>{`Tier ${index + 1}`}</Tiers>

                                <div>
                                    <SubtotalContainer>
                                        <SubtotalHeaderContainer>
                                            <Text>
                                                Order Subtotal
                                            </Text>
                                            <SubText>
                                                (required)
                                            </SubText>
                                        </SubtotalHeaderContainer>
                                        <InputContainer
                                            data-testid={'upper_price_limit'}
                                            // overlay the lower_price_limit above the text field component
                                            style={{ position: 'relative', display: 'inline-block' }}
                                        >
                                            <SubtotalStart>
                                                {`$${tier.lower_price_limit} - $`}
                                                <InfoToolTip content="Each maximum order subtotal value includes all cents up to the next whole dollar amount (e.g., a tier ending at $35 covers up to $35.99)." placement='top'>
                                                    <AlertCircle src={InfoCircle} />
                                                </InfoToolTip>
                                            </SubtotalStart>
                                            <TextField
                                                label=""
                                                type="number"
                                                value={tier.upper_price_limit || ""}
                                                onChange={(e) => handleInputChange(e, tier.id || tier.tempId)}
                                                sx={{
                                                    ['z-index']: '2',
                                                    width: '155px',
                                                    border: 'none',
                                                    mr: 2,
                                                    "& input[type='number']::-webkit-outer-spin-button, & input[type='number']::-webkit-inner-spin-button": {
                                                        WebkitAppearance: 'none',
                                                        margin: 0,
                                                    },
                                                    "& input[type='number']": {
                                                        MozAppearance: 'textfield',
                                                    },
                                                    "& input": {
                                                        padding: '0px 0px 3px 45px',
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                            border: "none",
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            border: "none",
                                                        },
                                                    },
                                                    "& .MuiInputBase-input, .MuiOutlinedInput-input": {
                                                        boxSizing: "border-box",
                                                        boxShadow: "none",
                                                    },
                                                }}
                                            />

                                        </InputContainer>
                                    </SubtotalContainer>
                                    {tier.subtotalError && (
                                        <Typography color='error' variant='caption'>
                                            <ErrorMessage>
                                                {tier.subtotalError}
                                            </ErrorMessage>
                                        </Typography>
                                    )}
                                </div>

                                <div>
                                    <SubtotalContainer>
                                        <Text>
                                            Prep Time
                                        </Text>
                                        <Select
                                            value={tier.prep_minutes}
                                            onChange={(e) => handlePrepTimeChange(e, tier.id || tier.tempId)}
                                            displayEmpty
                                            IconComponent={() => (<Icon src={Chevron_Down} style={{ width: '24px', height: '24px', paddingRight: '8px' }} alt='outline_minus_button' />)} // Set the custom icon component here
                                            sx={{
                                                mr: 2,
                                                width: '200px',
                                                height: '47px',
                                                gap: '8px',
                                                borderRadius: '8px',
                                                border: '1px 0px 0px 0px',
                                                opacity: '0px', // Adjust opacity if you want it visible
                                            }}
                                        >
                                            {Array.from({ length: 20 }, (_, i) => 25 + i * 5).map((min) => (
                                                <MenuItem key={min} value={min}>
                                                    {min} minutes
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </SubtotalContainer>
                                    {tier.prepTimeError && (
                                        <Typography color='error' variant='caption'>
                                            <ErrorMessage>
                                                {tier.prepTimeError}
                                            </ErrorMessage>
                                        </Typography>
                                    )}
                                </div>

                                <IconButton style={{ paddingTop: '28px' }} data-testid={'removeTier'} onClick={() => removeTier(tier.id, tier.tempId)}>
                                    <Icon src={outline_minus_button} iconSize='20' alt='outline_minus_button' />
                                </IconButton>
                            </Box>
                            {displayTiers[index + 1] && <Divider />}
                        </div>
                    ))}
                </Box>
                <AddTier onClick={addTier} >Add Tier</AddTier>
            </DialogContent>
            <DialogActions sx={{ padding: 0 }}>
                <Footer>
                    <Button onClick={toggleModal}><Cancel>Cancel</Cancel> </Button>
                    <Button sx={{ padding: 0 }} onClick={handleSave} variant='contained'>
                        <PrimaryButton data-testid={'save'}>Save</PrimaryButton>
                    </Button>
                </Footer>
            </DialogActions>
        </Dialog>
    )
}

export default OrderThrottleEditor
