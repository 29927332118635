import React, { useState } from 'react';
import downChevron from 'assets/icons/Chevron-Down.svg'
import { Availabilities } from 'api/menuMapping/types';
import { LoadingSkeleton } from 'components'
import { useAuth0 } from 'libs/auth0-react'
import { startCase, sortBy } from 'lodash'
import { DAYS_OF_WEEK } from 'utils'
import * as Styled from './styles'

type Props = {
  availabilities: Availabilities[]
  editButtonLabel: 'Edit Availability' | 'Edit Location Hours'
  selectorLabel: 'Hours' | 'Location Hours'
  onEdit: () => void
};

const AvailabilitySelector = ({ availabilities, editButtonLabel, selectorLabel, onEdit }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const today = new Date().toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
  const { user } = useAuth0()
  const isAdminUser = user?.email?.includes('@chowlyinc.com')
  const isLocationHours = selectorLabel === 'Location Hours'

  const selectedDay = availabilities.find(
    (availability) => availability.dayOfWeek.toLowerCase() === today
  )

  const getButtonText = () => {
    return selectedDay ? (
      <Styled.SelectedAvailabilityRow>
        <Styled.AvailableDay>{selectedDay.dayOfWeek}:</Styled.AvailableDay>
        <Styled.AvailableTimeContainer>
           {selectedDay.hours.split(',').map((hour, index) => {
              return (
                <Styled.AvailableTime key={index}>
                  {hour}
                </Styled.AvailableTime>
              )
          })}
        </Styled.AvailableTimeContainer>
      </Styled.SelectedAvailabilityRow>
    ) : (
      <Styled.AvailableTime>Not Open Today</Styled.AvailableTime>
    )
  }
  
  const filteredEnabledAvailabilities = () => {
    const enabledAvailabilities = availabilities.filter((availability) => availability.enabled);
    const enabledAvailabilitiesSorted = sortBy(enabledAvailabilities, item => DAYS_OF_WEEK.indexOf(startCase(item.dayOfWeek)))
  
    return enabledAvailabilitiesSorted.map(({ id, dayOfWeek, hours }) => (
      <Styled.DropdownItem key={id}>
        <Styled.AvailabilityRow>
          <Styled.AvailableDay isHighlighted={dayOfWeek.toLowerCase() === today}>
            {dayOfWeek}:
          </Styled.AvailableDay>
          <Styled.AvailableTimeContainer>
            {hours.split(',').map((hour, index) => {
                return (
                  <Styled.AvailableTime 
                    key={index}
                    isHighlighted={dayOfWeek.toLowerCase() === today}
                  >
                    {hour}
                  </Styled.AvailableTime>
                )
            })}
          </Styled.AvailableTimeContainer>
        </Styled.AvailabilityRow>
      </Styled.DropdownItem>
    ))
  }  
  
  return (
    <Styled.AvailabilityContainer>
      <Styled.DropdownContainer>
        <Styled.DropdownButton onClick={() => setIsOpen(!isOpen)}>
          <Styled.AvailabilityHeader>{selectorLabel}</Styled.AvailabilityHeader>
          {availabilities ? (
            <Styled.TodaysHours>{getButtonText()}</Styled.TodaysHours>
          ) : (
            <LoadingSkeleton isFlex />
          )}
          <Styled.Chevron
            src={downChevron}
            alt="chevron icon"
            isOpen={isOpen}
            width="16px"
            height="16px"
          />
        </Styled.DropdownButton>
        {isOpen && (
          <Styled.DropdownContent isLocationHours={isLocationHours}>
            {availabilities ? (
              filteredEnabledAvailabilities()
            ) : (
              <LoadingSkeleton isFlex />
            )}
            {(isLocationHours || isAdminUser) && (
              <Styled.EditButton
                onClick={onEdit}
                size='small'
                narrow
              >
                {editButtonLabel}
              </Styled.EditButton>
            )}
          </Styled.DropdownContent>
        )}
      </Styled.DropdownContainer>
    </Styled.AvailabilityContainer>
  );
};

export default AvailabilitySelector