import { useState, Children } from 'react';
import { Button, Modal } from 'components'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { updateMarketingChannel } from 'api';
import CircularProgress from '@mui/material/CircularProgress';

import * as Styled from './styles'
import { useGetGoogleBusinessLocations } from 'hooks';

type Props = {
  isOpen: boolean
  locationName: string
  showInfoIcon: boolean
  onClose: () => void
  marketingChannelId: string
  locationId: string
}

const validationSchema = Yup.object({
  selectedProfile: Yup.string().required("Please select a business profile"),
});

const ConnectionModal = ({ isOpen, locationName, onClose, marketingChannelId, locationId }: Props) => {
  const { isLoading, locations } = useGetGoogleBusinessLocations(locationId, marketingChannelId)
  const [selectedLocation, setSelectedLocation] = useState<string>();

  const handleSubmit = async () => {
    const googleBusinessProfile = locations?.find((profile) => profile?.location?.name === selectedLocation)
    await updateMarketingChannel(marketingChannelId, {
      googleBusinessProfile: {
        accountId: googleBusinessProfile?.accountId as string,
        locationId: googleBusinessProfile?.location?.name as string,
      }
    } ,locationId)
    onClose()
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedLocation(event.target.value as string);
  };

  return (
    <Modal isOpen={isOpen} cardProps={{ borderRadius: 8 }}>
      <Styled.ModalBox>
        {isLoading ? <CircularProgress /> : (
          <Styled.TextContainer>
          <Formik
            initialValues={{ selectedProfile: "" }}
            validationSchema={validationSchema}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
          >
            {({ isSubmitting }) => (
              <Form>
                <div style={{ marginBottom: "1rem" }}>
                  <label htmlFor="selectedProfile" style={{ display: "block", marginBottom: ".5rem" }}>
                    {`Which Google Business Profile do you want to associate to ${locationName}`}
                  </label>
                  <FormControl fullWidth>
                    <InputLabel id="business-profile-selection" style={{ fontSize: '14px' }}>Select a business profile</InputLabel>
                    <Select
                      labelId="business-profile-select-label"
                      id="business-profile-selection-select"
                      value={selectedLocation}
                      label="Select a business profile"
                      onChange={handleChange}
                      style={{ fontSize: '14px' }}
                    >
                      {Children.toArray(locations?.map((profile) => (<MenuItem value={profile?.location?.name || ''} style={{ fontSize: '14px' }}>{profile?.location?.title}</MenuItem>)))}
                    </Select>
                  </FormControl>
                </div>
                <Styled.LineRow />
                <Styled.ButtonContainer>
                  <Button variant='textonly' size='small' onClick={onClose} disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button size='small' onClick={handleSubmit} disabled={isSubmitting}>
                    Save
                  </Button>
                </Styled.ButtonContainer>
              </Form>
            )}
          </Formik>
        </Styled.TextContainer>
        )}
      </Styled.ModalBox>
    </Modal>
  )
}

export default ConnectionModal
