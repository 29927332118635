import * as Sentry from '@sentry/react'
import axios, { HttpStatusCode, InternalAxiosRequestConfig } from 'axios'
import * as config from 'config'
import { auth0Client } from 'libs/auth0-react'

const isTestMode = process.env.NODE_ENV === 'test'

interface RequestConfig extends InternalAxiosRequestConfig {
  metadata?: any
  retried?: number
}

const marketingApiClient = axios.create({
  baseURL: config.marketingApi.hostUrl,
})

if (marketingApiClient) {
  marketingApiClient.defaults.headers.common['Accept'] = 'application/json'
  marketingApiClient.defaults.headers.common['Content-Type'] = 'application/json'

  marketingApiClient.interceptors.request.use(async (requestConfig: RequestConfig) => {
    if (!isTestMode && requestConfig.url && config.isAuthRequiredApi(requestConfig.url)) {
      if (!(await auth0Client.isAuthenticated())) {
        window.location.href = '/login'
        return new Promise((_, reject) =>
          setTimeout(() => reject(new Error('User is not authenticated')), 500),
        )
      }
    }

    requestConfig.metadata = { startTime: new Date() }

    return requestConfig
  })

  marketingApiClient.interceptors.response.use(
    (response) => {
      const config = response.config as RequestConfig
      config.metadata.endTime = new Date()
      config.metadata.duration = config.metadata.endTime - config.metadata.startTime

      return response
    },
    async (error) => {
      if (axios.isAxiosError(error)) {
        const config = error.config as RequestConfig

        if (error.response?.status === HttpStatusCode.Unauthorized) {
          if (!config.retried) {
            try {
              config.retried = 1
              const authenticated = await auth0Client.isAuthenticated()
              if (!authenticated) {
                throw new Error('User is not authenticated')
              }
              const accessToken = await auth0Client.getTokenSilently()
              authorize(accessToken)
              config.headers.Authorization = `Bearer ${accessToken}`
              return marketingApiClient(config)
            } catch (err) {
              console.error('auth0.getTokenSilently() error:', err)
            }
          }
          window.location.href = '/login'
          return new Promise((_, reject) =>
            setTimeout(() => reject(new Error('User is not authenticated')), 500),
          )
        }

        config.metadata.endTime = new Date()
        config.metadata.duration = config.metadata.endTime - config.metadata.startTime
      }
      Sentry.captureException(error)
      return Promise.reject(error)
    },
  )
}

export const authorize = (accessToken?: string) => {
  if (accessToken) {
    marketingApiClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  }
}

export const unauthorize = () => {
  delete marketingApiClient.defaults.headers.common['Authorization']
}

export default marketingApiClient
