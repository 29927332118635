import { ReactComponent as SaleIcon } from 'assets/icons/sale-icon.svg'
import Disclaimer from 'components/AdPreviews/Disclaimer/Disclaimer'
import GoogleFeed, { GoogleFeedAd } from 'components/AdPreviews/GoogleFeed/GoogleFeed'
import useSearchAlgoliaIndex from 'hooks/useAlgoliaSearch'
import useSafeAsync from 'hooks/useSafeAsync'
import _ from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import {
  GoogleLocationSettings,
  InsightCampaignUpdateTypes,
  InsightPromotionType,
  TargetableInsight,
} from 'types/digitalMarketing'
import { AdvancedLocationTargeting, RadiusLocationUnits } from 'types/digitalMarketing/common'

import {
  AdTypeSpan,
  FinalUrl,
  PromoCode,
  PromotionDescriptionContent,
  PromotionDescriptionText,
  PromotionDescriptionWrapper,
  PromotionWrapper,
  SellIcon,
  StyledBulletTypography,
  StyledGreenLabel,
  StyledLabel,
  StyledRedLabel,
  StyledTypography,
} from './styles'

interface LiveMarketingUpdateDetailsProps {
  insight: TargetableInsight
  partnerId: string
}

const getUpdatedKeywords = (insight: TargetableInsight) => {
  const newKeywords = insight?.campaignUpdate?.group?.fields?.keywords || []
  const oldKeywords = insight?.campaignUpdate?.originalGroup?.fields?.keywords || []
  const removedKeywords = _.difference(oldKeywords, newKeywords)
  const addedKeywords = _.difference(newKeywords, oldKeywords)
  return (
    <>
      <StyledRedLabel variant='caption' marginTop='24px'>
        Keyword(s) Removed
      </StyledRedLabel>
      {removedKeywords?.length ? (
        removedKeywords.map((keyword, index) => (
          <StyledBulletTypography variant='body2' key={index}>
            {keyword}
          </StyledBulletTypography>
        ))
      ) : (
        <StyledTypography variant='body2'>N/A</StyledTypography>
      )}
      <StyledGreenLabel variant='caption' marginTop='24px'>
        Keyword(s) Added
      </StyledGreenLabel>
      {addedKeywords?.length ? (
        addedKeywords.map((keyword, index) => (
          <StyledBulletTypography variant='body2' key={index}>
            {keyword}
          </StyledBulletTypography>
        ))
      ) : (
        <StyledTypography variant='body2'>N/A</StyledTypography>
      )}
    </>
  )
}

const getUpdatedHeadlinesAndDescriptions = (insight: TargetableInsight) => {
  const newDescriptions = insight?.campaignUpdate?.ad?.descriptions || []
  const oldDescriptions = insight?.campaignUpdate?.originalAd?.descriptions || []
  const removedDescriptions = _.difference(oldDescriptions, newDescriptions)
  const addedDescriptions = _.difference(newDescriptions, oldDescriptions)

  const newHeadlines = insight?.campaignUpdate?.ad?.headlines || []
  const oldHeadlines = insight?.campaignUpdate?.originalAd?.headlines || []
  const removedHeadlines = _.difference(oldHeadlines, newHeadlines)
  const addedHeadlines = _.difference(newHeadlines, oldHeadlines)

  return (
    <>
      <StyledRedLabel variant='caption' marginTop='24px'>
        Headlines(s) Removed
      </StyledRedLabel>
      {removedHeadlines?.length ? (
        removedHeadlines.map((headline, index) => (
          <StyledBulletTypography variant='body2' key={index}>
            {headline}
          </StyledBulletTypography>
        ))
      ) : (
        <StyledTypography variant='body2'>N/A</StyledTypography>
      )}
      <StyledRedLabel variant='caption' marginTop='24px'>
        Descriptions(s) Removed
      </StyledRedLabel>
      {removedDescriptions?.length ? (
        removedDescriptions.map((description, index) => (
          <StyledBulletTypography variant='body2' key={index}>
            {description}
          </StyledBulletTypography>
        ))
      ) : (
        <StyledTypography variant='body2'>N/A</StyledTypography>
      )}
      <StyledGreenLabel variant='caption' marginTop='24px'>
        Headlines(s) Added
      </StyledGreenLabel>
      {addedHeadlines?.length ? (
        addedHeadlines.map((headline, index) => (
          <StyledBulletTypography variant='body2' key={index}>
            {headline}
          </StyledBulletTypography>
        ))
      ) : (
        <StyledTypography variant='body2'>N/A</StyledTypography>
      )}
      <StyledGreenLabel variant='caption' marginTop='24px'>
        Descriptions(s) Added
      </StyledGreenLabel>
      {addedDescriptions?.length ? (
        addedDescriptions.map((description, index) => (
          <StyledBulletTypography variant='body2' key={index}>
            {description}
          </StyledBulletTypography>
        ))
      ) : (
        <StyledTypography variant='body2'>N/A</StyledTypography>
      )}
    </>
  )
}

const getUpdatedBudget = (insight: TargetableInsight) => {
  const getBudget = (budget: number) => (
    <StyledTypography variant='body2'>
      {'$'}
      {budget.toFixed(2) || 0}
      {' daily budget'}
    </StyledTypography>
  )
  return (
    <>
      <StyledRedLabel variant='caption' marginTop='24px'>
        Current Budget
      </StyledRedLabel>
      {getBudget(insight?.campaignUpdate?.budget?.currentBudget || 0)}
      <StyledGreenLabel variant='caption' marginTop='24px'>
        New Budget
      </StyledGreenLabel>
      {getBudget(insight?.campaignUpdate?.budget?.nextBudget || 0)}
    </>
  )
}

const getUpdatedEndDate = (insight: TargetableInsight) => {
  const isEvergreen = !insight?.campaignUpdate?.endDate?.currentEndDate
  const willBeEvergreen = !insight?.campaignUpdate?.endDate?.nextEndDate
  const currentEndDate = !isEvergreen
    ? moment(insight?.campaignUpdate?.endDate?.currentEndDate).format('MM/DD/YYYY')
    : 'Never'
  const nextEndDate = !willBeEvergreen
    ? moment(insight?.campaignUpdate?.endDate?.nextEndDate)?.format('MM/DD/YYYY')
    : 'Never'
  return (
    <>
      <StyledRedLabel variant='caption' marginTop='24px'>
        Current End Date
      </StyledRedLabel>
      <StyledTypography variant='body2'>{currentEndDate}</StyledTypography>

      <StyledGreenLabel variant='caption' marginTop='24px'>
        New End Date
      </StyledGreenLabel>
      <StyledTypography variant='body2'>{nextEndDate}</StyledTypography>
    </>
  )
}

const getUpdatedLocationTargeting = (insight: TargetableInsight) => {
  const getRadiusItems = (radiusLocations: AdvancedLocationTargeting[]) => (
    <>
      {radiusLocations.map(({ radius }, index) => (
        <StyledBulletTypography variant='body2' key={index}>
          {radius?.address?.streetAddress}
          <AdTypeSpan>
            {': Radius '}
            {radius?.distance}
            {`${radius?.unit === RadiusLocationUnits.Kilometers ? ' km' : ' mi'}.`}
          </AdTypeSpan>
        </StyledBulletTypography>
      ))}
    </>
  )
  const getLocationItems = (locations: GoogleLocationSettings[]) => (
    <>
      {locations.map(({ canonicalName, targetType }, index) => (
        <StyledBulletTypography variant='body2' key={index}>
          {canonicalName}
          <AdTypeSpan>{` (${targetType})`}</AdTypeSpan>
        </StyledBulletTypography>
      ))}
    </>
  )

  const differenceByAddress = (
    arr1: AdvancedLocationTargeting[],
    arr2: AdvancedLocationTargeting[],
  ) =>
    _.differenceWith(
      arr1,
      arr2,
      (a, b) => a?.radius?.address?.streetAddress === b?.radius?.address?.streetAddress,
    )

  const newLocations = insight?.campaignUpdate?.locationSettings?.newLocationSettings || []
  const oldLocations = insight?.campaignUpdate?.locationSettings?.oldLocationSettings || []
  const newRadius = insight?.campaignUpdate?.advancedLocations?.newLocationSettings || []
  const oldRadius = insight?.campaignUpdate?.advancedLocations?.oldLocationSettings || []

  const removedLocations = _.differenceBy(oldLocations, newLocations, 'resourceName')
  const addedLocations = _.differenceBy(newLocations, oldLocations, 'resourceName')
  const removedRadiusLocations = differenceByAddress(oldRadius, newRadius)
  const addedRadiusLocations = differenceByAddress(newRadius, oldRadius)

  return (
    <>
      <StyledRedLabel variant='caption' marginTop='24px'>
        Location Targeting Removed
      </StyledRedLabel>
      {getLocationItems(removedLocations)}
      {getRadiusItems(removedRadiusLocations)}

      <StyledGreenLabel variant='caption' marginTop='24px'>
        Location Targeting Added
      </StyledGreenLabel>
      {getLocationItems(addedLocations)}
      {getRadiusItems(addedRadiusLocations)}
    </>
  )
}

const getUpdatedPromotions = (insight: TargetableInsight) => {
  const promotions = insight?.campaignUpdate?.promotions?.newPromotions

  const formatPromotionValue = (value: string, isMonetary: boolean, includeUpTo = false) => {
    const formattedValue = parseFloat(value)
    if (Number.isNaN(formattedValue)) {
      return ''
    }
    const decimalPart = formattedValue % 1
    let formattedString = ''
    if (isMonetary) {
      formattedString = `$${
        decimalPart !== 0 ? formattedValue.toFixed(2) : formattedValue.toFixed(0)
      }`
    } else {
      formattedString = `${
        decimalPart !== 0 ? formattedValue.toFixed(2) : formattedValue.toFixed(0)
      }%`
    }
    if (includeUpTo) {
      formattedString = `Up to ${formattedString}`
    }
    return formattedString
  }

  const getPromotionScheduleText = (start: string, end: string): string => {
    const getScheduleText = (startDate: string, endDate: string) =>
      `This promotion will start on: ${startDate} and end on: ${endDate}`
    const isEvergreen = !end
    const startDate = moment(start).format('MM/DD/YYYY')
    const endDate = moment(end)?.format('MM/DD/YYYY')
    return getScheduleText(startDate, isEvergreen ? 'Never' : endDate)
  }

  const promotionMonetaryLogic = {
    monetary_discount: formatPromotionValue(promotions?.promotionTypeValue || '', true),
    up_monetary_discount: formatPromotionValue(promotions?.promotionTypeValue || '', true, true),
    percent_discount: formatPromotionValue(promotions?.promotionTypeValue || '', false),
    up_percent_discount: formatPromotionValue(promotions?.promotionTypeValue || '', false, true),
  }

  const promotionType: InsightPromotionType =
    promotions?.promotionType || InsightPromotionType.MonetaryDiscount

  return (
    <>
      <PromotionWrapper container>
        <PromotionDescriptionWrapper item xs={12}>
          <PromotionDescriptionContent item xs={12}>
            <SellIcon>
              <SaleIcon />
            </SellIcon>
            <PromotionDescriptionText>
              {promotionMonetaryLogic[promotionType]} {promotions?.item}{' '}
              {promotions?.promotionDetails === 'promo_code' && (
                <PromoCode>Code: {promotions?.promotionDetailsValue}</PromoCode>
              )}
              <FinalUrl>{promotions?.finalUrl}</FinalUrl>
            </PromotionDescriptionText>
          </PromotionDescriptionContent>
        </PromotionDescriptionWrapper>
      </PromotionWrapper>
      <StyledLabel variant='caption' marginTop='24px'>
        Schedule
      </StyledLabel>
      <StyledTypography variant='body2'>
        {getPromotionScheduleText(
          promotions?.displayedPromotionStart || '',
          promotions?.displayedPromotionEnd || '',
        )}
      </StyledTypography>
    </>
  )
}

const LiveMarketingUpdateDetails: React.FC<LiveMarketingUpdateDetailsProps> = ({
  insight,
  partnerId,
}) => {
  const [adCampaignLoading, setAdCampainLoading] = useState(false)
  const [associatedAdCampaign, setAssociatedAdCampaign] = useState<any>(null)
  const { search, isIndexReady } = useSearchAlgoliaIndex('AdCampaigns', partnerId)
  const safeAsync = useSafeAsync()

  const fetchAssociatedAdCampaign = useCallback(async () => {
    if (!insight?.campaignUpdate?.campaignId) return
    if (!isIndexReady) return

    setAdCampainLoading(true)

    const result = await search('', {
      hitsPerPage: 10,
      filters: `NOT deletedOn:*`,
      attributesToHighlight: [],
      facetFilters: [`id:${insight?.campaignUpdate?.campaignId}`],
    })
    const adCampaigns = result?.hits
    setAssociatedAdCampaign(adCampaigns?.[0])
    setAdCampainLoading(false)
  }, [insight?.campaignUpdate?.campaignId, search])

  // Fetch the associated store when partnerId is updated
  useEffect(() => {
    safeAsync(fetchAssociatedAdCampaign()).catch((e) => {
      console.error(e)
      setAdCampainLoading(false)
    })
  }, [insight?.campaignUpdate?.campaignId, safeAsync, fetchAssociatedAdCampaign])

  const getLiveMarketingUpdateType = useCallback((insight: TargetableInsight) => {
    const marketingUpdateType = insight?.campaignUpdate?.updateType
    switch (marketingUpdateType) {
      case InsightCampaignUpdateTypes.Keywords:
        return getUpdatedKeywords(insight)
      case InsightCampaignUpdateTypes.HeadlinesAndDescriptions:
        return getUpdatedHeadlinesAndDescriptions(insight)
      case InsightCampaignUpdateTypes.Budget:
        return getUpdatedBudget(insight)
      case InsightCampaignUpdateTypes.EndDate:
        return getUpdatedEndDate(insight)
      case InsightCampaignUpdateTypes.Locations:
        return getUpdatedLocationTargeting(insight)
      case InsightCampaignUpdateTypes.Promotions:
        return getUpdatedPromotions(insight)
      default:
        return <></>
    }
  }, [])

  return (
    <>
      {!adCampaignLoading && associatedAdCampaign ? (
        <>
          <GoogleFeed
            ad={{ ...(associatedAdCampaign?.google?.ad as GoogleFeedAd) }}
            campaign={{ ...associatedAdCampaign?.google?.campaign }}
            marketingChannelId={associatedAdCampaign?.marketingChannelId as string}
            partnerId={partnerId}
          />
          <Disclaimer text='Ad appearance may vary across devices' variant='firstLine' />
        </>
      ) : null}
      {/* Live Marketing Update type */}
      {getLiveMarketingUpdateType(insight)}
    </>
  )
}

export default LiveMarketingUpdateDetails
