import { ReactComponent as ChowlyNavLogo } from 'assets/icons/ChowlyNavImg.svg'
import closeIcon from 'assets/icons/close-icon.svg'
import { ReactComponent as MoveLeftUp } from 'assets/icons/move-left-up.svg'
import { HamburgerButton, UserMenu } from 'components'
import { Experiments } from 'config'
import { useGetLocationPartners, useIsMobile, useOpenModal, useRouteLocationId } from 'hooks'
import { useAuth0 } from 'libs/auth0-react'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import SearchLocationsField from './SearchLocationsField'
import * as Styled from './styles'

type NavLinkItem = {
  title: string
  route?: string
  href?: string
  target?: string
  popOut?: boolean
  icon?: ReactNode
  onClick?: () => void
  subNav?: Array<NavLinkItem>
}

const NavBar = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const locationId = useRouteLocationId()
  const { partner } = useGetLocationPartners(locationId)
  const { isMobile } = useIsMobile()
  const { logout, authorizedFeatures, user } = useAuth0()

  const [isNavBarOpen, setIsNavBarOpen] = useState(isMobile ? false : true) // in mobile don't open the nav bar by default. On web - do.
  const [selectedOption, setSelectedOption] = useState(partner?.attributes.name || '')

  const toggleNavBar = () => setIsNavBarOpen(!isNavBarOpen)
  const toolbarRef = useRef<HTMLDivElement | null>(null)

  const billingIsEnabled = authorizedFeatures?.find(
    (feature) => feature[Experiments.BILLING_NAVIGATION],
  )
  const isRccPlatform = authorizedFeatures?.find((feature) => feature[Experiments.RCC_PLATFORM])
  const isDigitalMarketing = authorizedFeatures?.find(
    (feature) => feature[Experiments.DIGITAL_MARKETING],
  )
  const omIsEnabled = authorizedFeatures?.find((feature) => feature[Experiments.ORDER_MANAGEMENT])
  const isAdminUser = user?.email?.includes('@chowlyinc.com')

  const openModal = useOpenModal()

  const navLinks: NavLinkItem[] = [
    {
      title: 'Home',
      route: `${locationId}/home`,
    },
    {
      title: 'Menu Management',
      route: `${locationId}/menu-management`,
    },
    ...(omIsEnabled
      ? [
          {
            title: 'Order Management',
            route: `${locationId}/order-management`,
          },
        ]
      : []),
    ...(isRccPlatform || isDigitalMarketing
      ? [
          {
            title: 'Digital Marketing',
            route: `${locationId}/digital-marketing`,
          },
        ]
      : []),
    {
      title: 'Analytics',
      route: `${locationId}/analytics`,
    },
  ]

  const manageAccountLinks: NavLinkItem[] = [
    ...(billingIsEnabled
      ? [
          {
            title: 'Billing',
            route: `${locationId}/billing`,
          },
        ]
      : []),
    {
      title: 'Account Management',
      onClick: () => {
        openModal('ManageAccount')
      },
    },
    {
      title: 'Help Center',
      onClick: () => {
        window.open('https://chowly.help/s/', '_blank')
      },
      icon: <MoveLeftUp />,
    },
    {
      title: 'Log Out',
      onClick: () => {
        logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })
      },
    },
  ]

  const replacePathName = (newLocationId: string) =>
    pathname.includes('analytics')
      ? `/${newLocationId}/menu-management`
      : pathname.replace(/\/\d+\//, `/${newLocationId}/`)

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const changeSelectHandler = (selectedOption: any, closeNavBar?: boolean) => {
    setSelectedOption(selectedOption.name)
    if (selectedOption?.id) {
      navigate(replacePathName(selectedOption?.id))
      if (closeNavBar) toggleNavBar()
    }
  }

  useEffect(() => setSelectedOption(partner?.attributes?.name || ''), [partner?.attributes?.name])

  return (
    <>
      {/* Commenting out as we might need this in the future */}
      {/* {isNavBarOpen && <Styled.Backdrop {...(isMobile && { onClick: toggleNavBar })} />} */}

      {/* General Header for Mobile */}
      <Styled.MobileContainer>
        <HamburgerButton onClick={toggleNavBar} />
        <Styled.LogoLinkMobile to='/'>
          <ChowlyNavLogo />
        </Styled.LogoLinkMobile>
      </Styled.MobileContainer>

      {/* General Header */}
      <Styled.Container>
        <Styled.Header>
          <HamburgerButton onClick={toggleNavBar} />
          <Styled.LogoLink to={`${locationId}/home`}>
            <ChowlyNavLogo />
          </Styled.LogoLink>
        </Styled.Header>

        <Styled.ToolBarHeader ref={toolbarRef}>
          <Styled.SearchWrapperHeader>
            <SearchLocationsField
              onSelect={(location) => changeSelectHandler(location)}
              placeholder={selectedOption || 'Location Name'}
              isAdminUser={isAdminUser}
              locationId={locationId}
            />
          </Styled.SearchWrapperHeader>
        </Styled.ToolBarHeader>
      </Styled.Container>

      {/* Left SideBar */}
      <Styled.SideBar isOpen={isNavBarOpen}>
        <Styled.CloseButton alt='cancel' src={closeIcon} onClick={toggleNavBar} />
        <Styled.ToolBar>
          <Styled.SearchWrapper>
            <SearchLocationsField
              onSelect={(location) => changeSelectHandler(location, true)}
              placeholder={selectedOption || 'Location Name'}
              isAdminUser={isAdminUser}
              locationId={locationId}
            />
          </Styled.SearchWrapper>
          <Styled.Nav>
            {navLinks.map((link, linkIndex) => (
              <Styled.NavLink
                key={linkIndex}
                to={link.route ?? ''}
                {...(isMobile && { onClick: toggleNavBar })}
              >
                <Styled.NavLinkText>
                  <span>{link.title}</span>
                </Styled.NavLinkText>
              </Styled.NavLink>
            ))}
          </Styled.Nav>
          <UserMenu />
          <Styled.ManageAccount>
            {manageAccountLinks.map((link, linkIndex) => (
              <Styled.ManageLink key={linkIndex} to={link.route ?? '#'} onClick={link.onClick}>
                <Styled.NavLinkText>
                  {link.title === 'Log Out' ? (
                    <Styled.Logout>{link.title}</Styled.Logout>
                  ) : (
                    <span>
                      {link.title}
                      {link?.icon}
                    </span>
                  )}
                </Styled.NavLinkText>
              </Styled.ManageLink>
            ))}
          </Styled.ManageAccount>
        </Styled.ToolBar>
      </Styled.SideBar>
    </>
  )
}

export default NavBar
