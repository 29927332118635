import Dinero, { DineroObject } from 'dinero.js'
import { LocationsData } from 'hooks/useGetPartnerLocations'
import _ from 'lodash'
import moment from 'moment'
import {
  AdSchedule,
  CampaignAdInfo,
  EmailMarketingCampaign,
  OptimizedCampaign,
  SplitDate,
  TargetableInsight,
} from 'types/digitalMarketing'
import { ToDoItem } from 'types/digitalMarketing/common'
import { GenericAd } from 'types/digitalMarketing/googleAd'
import { FacebookLocation, TargetableStore } from 'types/digitalMarketing/store'

import {
  convertClientToPartnerTimezone,
  convertUTCToPartnerTimezone,
  getDateStr,
  getTzValueByKey,
} from './DateTime'

/**
 * Get the location info of the matching partnerId.
 */
export const getPartnerLocation = (partnerId: string | number, locationsData: LocationsData) => {
  return locationsData?.locations?.find((location) => location.id === partnerId)
}

/**
 * Get the timezone of a location or the browser's timezone.
 */
export const getLocationTimezone = (tz?: string) => {
  const timezone = getTzValueByKey(tz || '')
  // partnerTimezone or browser's timezone.
  const partnerTimezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone

  return partnerTimezone
}

export const isGoogleAd = (item?: ToDoItem | null) => {
  return (item as GenericAd)?.google !== undefined
}

export const isMetaAd = (item?: ToDoItem | null) => {
  return (item as OptimizedCampaign)?.ads !== undefined
}

export const isTargetableInsight = (item?: ToDoItem | null) => {
  return (item as TargetableInsight)?.campaignUpdate !== undefined
}

export const isEmailMarketingAd = (item?: ToDoItem | null) => {
  return (item as EmailMarketingCampaign)?.aasm_state !== undefined
}

/**
 * @returns the ToDo item casted as a GoogleAd if it is a valid google ad
 */
export const withTodoAsGoogleAd = (item?: ToDoItem | null) =>
  isGoogleAd(item) ? (item as GenericAd) : undefined

/**
 * @returns the ToDo item casted as a MetaAd if it is a valid facebook ad
 */
export const withTodoAsMetaAd = (item?: ToDoItem | null) =>
  isMetaAd(item) ? (item as OptimizedCampaign) : undefined

/**
 * @returns the ToDo item casted as a TargetableInsight if it is a valid targetable insight
 */
export const withTodoAsTargetableInsight = (item?: ToDoItem | null) =>
  isTargetableInsight(item) ? (item as TargetableInsight) : undefined

/**
 * @returns the ToDo item casted as a EmailMarketingAd if it is a valid email marketing ad
 */
export const withTodoAsEmailMarketingAd = (item?: ToDoItem | null) =>
  isEmailMarketingAd(item) ? (item as EmailMarketingCampaign) : undefined

/**
 * Check if a campaign should be displayed to the user based on the configured display date and time
 * and the current time in the partner's timezone.
 * @param campaign the FB campaign or insight to check
 * @param partnerTz the partner's timezone
 * @returns true if the campaign should be displayed, false otherwise
 */
export const shouldDisplayCampaign = (campaign: ToDoItem, partnerTz: string) => {
  const asInsight = withTodoAsTargetableInsight(campaign)
  const asFacebookCampaign = withTodoAsMetaAd(campaign)
  let isVisible = true

  const displayDate = asFacebookCampaign?.displayDate || asInsight?.displayDate
  const displayTime = asFacebookCampaign?.displayTime || asInsight?.displayTime

  if (displayDate && displayTime) {
    const dd = displayDate
    const dt = displayTime

    // TGT campaigns are (supposed to be) always in UTC. Here we build a proper
    // UTC date string from the campaign's display date and time
    const dayStr = dd.day.toString().padStart(2, '0')
    // NOTE: month in campaigns is already fixed to be 1-12 indexed.
    const monthStr = dd.month.toString().padStart(2, '0')
    const yearStr = dd.year.toString()
    const hourStr = dt.hour.toString().padStart(2, '0')
    const minuteStr = dt.minute.toString().padStart(2, '0')
    const utcDateStr = `${yearStr}-${monthStr}-${dayStr}T${hourStr}:${minuteStr}:00Z`

    // Create a Date object from the UTC string
    const utcDate = new Date(utcDateStr)

    // Figure out the current partner's timezone
    const partnerTimezone = getLocationTimezone(partnerTz)

    // Convert the UTC date to the partner's timezone
    const displayDateInPartnerTZStr = convertUTCToPartnerTimezone(utcDate, partnerTimezone)
    const displayDateInPartnerTZ = new Date(displayDateInPartnerTZStr)

    // Get the current date and time in the partner's timezone
    const todayLocalTime = getDateStr(new Date())
    const currentPartnerTime = convertClientToPartnerTimezone(todayLocalTime, partnerTimezone)

    // Compare the dates
    isVisible = displayDateInPartnerTZ <= currentPartnerTime
  }

  return isVisible
}

/**
 * Checks if the given suggested Google Ad should be displayed to the user based on the configured
 * display date and time and the current time in the partner's timezone.
 * @param ad the Google Ad to check
 * @returns true if the ad should be displayed, false otherwise
 */
export const shouldDisplayGoogleAd = (ad: ToDoItem, partnerTz: string) => {
  const asGoogleAd = withTodoAsGoogleAd(ad)
  let isVisible = true

  if (asGoogleAd) {
    const displayDate = asGoogleAd.smartFeed?.displayDate

    if (displayDate) {
      // Current partner's timezone
      const partnerTimezone = getLocationTimezone(partnerTz)

      // Convert the UTC date to the partner's timezone
      const displayDateInPartnerTZStr = convertUTCToPartnerTimezone(displayDate, partnerTimezone)
      const displayDateInPartnerTZ = new Date(displayDateInPartnerTZStr)

      // Get the current date and time in the partner's timezone
      const todayLocalTime = getDateStr(new Date())
      const currentPartnerTime = convertClientToPartnerTimezone(todayLocalTime, partnerTimezone)
      // Compare the dates
      isVisible = displayDateInPartnerTZ <= currentPartnerTime
    }
  }

  return isVisible
}

export const getDateFromObject = (dateObj: SplitDate) => {
  return moment(`${dateObj.year}-${dateObj.month}-${dateObj.day}`, 'YYYY-MM-DD')
}

export const getDateFromSchedule = (schedule: AdSchedule, key: string) => {
  const day = _.get(schedule, [key, 'day'])
  const month = _.get(schedule, [key, 'month'])
  const year = _.get(schedule, [key, 'year'])

  return moment(`${month}-${day}-${year}`, 'MM-DD-YYYY')
}

const allCTAOptions = [
  { value: 'OPEN_LINK', text: 'OPEN LINK' },
  { value: 'LIKE_PAGE', text: 'LIKE PAGE' },
  { value: 'SHOP_NOW', text: 'SHOP NOW' },
  { value: 'PLAY_GAME', text: 'PLAY GAME' },
  { value: 'INSTALL_APP', text: 'INSTALL APP' },
  { value: 'USE_APP', text: 'USE APP' },
  { value: 'CALL', text: 'CALL' },
  { value: 'CALL_ME', text: 'CALL ME' },
  { value: 'VIDEO_CALL', text: 'VIDEO CALL' },
  { value: 'INSTALL_MOBILE_APP', text: 'INSTALL MOBILE APP' },
  { value: 'USE_MOBILE_APP', text: 'USE MOBILE APP' },
  { value: 'MOBILE_DOWNLOAD', text: 'MOBILE DOWNLOAD' },
  { value: 'BOOK_TRAVEL', text: 'BOOK TRAVEL' },
  { value: 'LISTEN_MUSIC', text: 'LISTEN MUSIC' },
  { value: 'WATCH_VIDEO', text: 'WATCH VIDEO' },
  { value: 'LEARN_MORE', text: 'LEARN MORE' },
  { value: 'SIGN_UP', text: 'SIGN UP' },
  { value: 'DOWNLOAD', text: 'DOWNLOAD' },
  { value: 'WATCH_MORE', text: 'WATCH MORE' },
  { value: 'VISIT_PAGES_FEED', text: 'VISIT PAGES FEED' },
  { value: 'CALL_NOW', text: 'CALL NOW' },
  { value: 'APPLY_NOW', text: 'APPLY NOW' },
  { value: 'CONTACT', text: 'CONTACT' },
  { value: 'BUY_NOW', text: 'BUY NOW' },
  { value: 'GET_OFFER', text: 'GET OFFER' },
  { value: 'GET_OFFER_VIEW', text: 'GET OFFER VIEW' },
  { value: 'BUY_TICKETS', text: 'BUY TICKETS' },
  { value: 'UPDATE_APP', text: 'UPDATE APP' },
  { value: 'GET_DIRECTIONS', text: 'GET DIRECTIONS' },
  { value: 'BUY', text: 'BUY' },
  { value: 'SEND_UPDATES', text: 'SEND UPDATES' },
  { value: 'MESSAGE_PAGE', text: 'MESSAGE PAGE' },
  { value: 'DONATE', text: 'DONATE' },
  { value: 'SUBSCRIBE', text: 'SUBSCRIBE' },
  { value: 'SAY_THANKS', text: 'SAY THANKS' },
  { value: 'SELL_NOW', text: 'SELL NOW' },
  { value: 'SHARE', text: 'SHARE' },
  { value: 'DONATE_NOW', text: 'DONATE NOW' },
  { value: 'GET_QUOTE', text: 'GET QUOTE' },
  { value: 'CONTACT_US', text: 'CONTACT US' },
  { value: 'ORDER_NOW', text: 'ORDER NOW' },
  { value: 'START_ORDER', text: 'START ORDER' },
  { value: 'ADD_TO_CART', text: 'ADD TO CART' },
  { value: 'VIDEO_ANNOTATION', text: 'VIDEO ANNOTATION' },
  { value: 'RECORD_NOW', text: 'RECORD NOW' },
  { value: 'INQUIRE_NOW', text: 'INQUIRE NOW' },
  { value: 'CONFIRM', text: 'CONFIRM' },
  { value: 'REFER_FRIENDS', text: 'REFER FRIENDS' },
  { value: 'REQUEST_TIME', text: 'REQUEST TIME' },
  { value: 'GET_SHOWTIMES', text: 'GET SHOWTIMES' },
  { value: 'LISTEN_NOW', text: 'LISTEN NOW' },
  { value: 'WOODHENGE_SUPPORT', text: 'WOODHENGE SUPPORT' },
  { value: 'SOTTO_SUBSCRIBE', text: 'SOTTO SUBSCRIBE' },
  { value: 'FOLLOW_USER', text: 'FOLLOW USER' },
  { value: 'RAISE_MONEY', text: 'RAISE MONEY' },
  { value: 'EVENT_RSVP', text: 'EVENT RSVP' },
  { value: 'WHATSAPP_MESSAGE', text: 'WHATSAPP MESSAGE' },
  { value: 'FOLLOW_NEWS_STORYLINE', text: 'FOLLOW NEWS STORYLINE' },
  { value: 'SEE_MORE', text: 'SEE MORE' },
  { value: 'BOOK_NOW', text: 'BOOK NOW' },
  { value: 'FIND_A_GROUP', text: 'FIND A GROUP' },
  { value: 'FIND_YOUR_GROUPS', text: 'FIND YOUR GROUPS' },
  { value: 'PAY_TO_ACCESS', text: 'PAY TO ACCESS' },
  { value: 'PURCHASE_GIFT_CARDS', text: 'PURCHASE GIFT CARDS' },
  { value: 'FOLLOW_PAGE', text: 'FOLLOW PAGE' },
  { value: 'SEND_A_GIFT', text: 'SEND A GIFT' },
  { value: 'SWIPE_UP_SHOP', text: 'SWIPE UP SHOP' },
  { value: 'SWIPE_UP_PRODUCT', text: 'SWIPE UP PRODUCT' },
  { value: 'SEND_GIFT_MONEY', text: 'SEND GIFT MONEY' },
  { value: 'PLAY_GAME_ON_FACEBOOK', text: 'PLAY GAME ON FACEBOOK' },
  { value: 'GET_STARTED', text: 'GET STARTED' },
  { value: 'OPEN_INSTANT_APP', text: 'OPEN INSTANT APP' },
  { value: 'AUDIO_CALL', text: 'AUDIO CALL' },
  { value: 'GET_PROMOTIONS', text: 'GET PROMOTIONS' },
  { value: 'JOIN_CHANNEL', text: 'JOIN CHANNEL' },
  { value: 'MAKE_AN_APPOINTMENT', text: 'MAKE AN APPOINTMENT' },
  { value: 'ASK_ABOUT_SERVICES', text: 'ASK ABOUT SERVICES' },
  { value: 'BOOK_A_CONSULTATION', text: 'BOOK A CONSULTATION' },
  { value: 'GET_A_QUOTE', text: 'GET A QUOTE' },
  { value: 'BUY_VIA_MESSAGE', text: 'BUY VIA MESSAGE' },
  { value: 'ASK_FOR_MORE_INFO', text: 'ASK FOR MORE INFO' },
  { value: 'CHAT_WITH_US', text: 'CHAT WITH US' },
  { value: 'VIEW_PRODUCT', text: 'VIEW PRODUCT' },
  { value: 'NO_BUTTON', text: 'NO BUTTON' },
]

export const getCTAOptions = () => {
  return _.orderBy(
    _.map(allCTAOptions, (option) => ({
      value: option.value,
      text: option.text,
    })),
    ['text'],
    ['asc'],
  )
}

export const getCampaignStores = (stores: TargetableStore[], ads: CampaignAdInfo[]) =>
  _.filter(stores, (store) => {
    const storeIds = _.map(ads, 'storeId')
    return _.includes(storeIds, store.id)
  })

export const getFacebookAddress = (location: FacebookLocation) => {
  let address = ''
  if (!_.isEmpty(location)) {
    address += location?.street ? `${location.street}` : ''
    address += location?.city ? `${address ? ',' : ''} ${location.city}` : ''
    address += location?.state ? `${address ? ',' : ''} ${location.state}` : ''
    address += location?.zip ? `${!location?.state ? ',' : ''} ${location.zip}` : ''
    address += location?.country ? `${address ? ',' : ''} ${location.country}` : ''
  }

  return address
}

function getFormat(precision: number) {
  if (precision === 2) {
    return '0.00'
  }
  return '0'
}

export function formatCurrency(amount: number) {
  return Dinero({ amount }).toFormat(getFormat(Dinero.defaultPrecision))
}

export function getLifetime(budget: DineroObject, format: string) {
  if (_.isEmpty(budget)) {
    return null
  }

  return Dinero(budget).toFormat(format || getFormat(Dinero.defaultPrecision))
}

export function sumLifetime(budget: DineroObject, storeCount: number) {
  const budgetCopy = _.clone(budget)
  _.set(budgetCopy, 'amount', budgetCopy.amount * storeCount)
  return getLifetime(budgetCopy, '0,0.00')
}
