/**
 * Formats a phone number string into the format (XXX) XXX-XXXX.
 *
 * @param {string} phoneNumber - The phone number to format.
 * @returns {string} The formatted phone number.
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})(.*)?$/)

  if (match) {
    const formatted = `(${match[1]}) ${match[2]}-${match[3]}`
    if (match[4]) {
      return `${formatted} ${match[4]}` // Append any extra digits
    }
    return formatted
  }

  return phoneNumber
}

/**
 * Cleans a phone number by removing all whitespace and non-numeric characters,
 * while preserving the international dialing prefix (`+`).
 *
 * @param {string | undefined} phoneNumber - The phone number to clean.
 * @returns {string} - The cleaned phone number with only digits and `+` (if present).
 *
 * @example
 * cleanPhoneNumber("123 456 7890"); // "1234567890"
 * cleanPhoneNumber("(123) 456-7890"); // "1234567890"
 * cleanPhoneNumber("+1 (123) 456-7890"); // "+11234567890"
 * cleanPhoneNumber(" 123-456-7890 ext. 123"); // "1234567890"
 */
export const cleanPhoneNumber = (phoneNumber: string | undefined): string => {
  if (!phoneNumber) return ''
  return phoneNumber.replace(/\s+/g, '').replace(/[^\d+]/g, '')
}
