import CloseIcon from '@mui/icons-material/Close'
import { Drawer, IconButton } from '@mui/material'
import { ModalType, PaymentProvider, ReportingOrder } from 'api/orderManagement/types'
import { AlertContainer } from 'components'
import { aperitifUrl } from 'config'
import { useAuth0 } from 'libs/auth0-react'
import React from 'react'
import { PlatformName } from 'types'
import { formatSubmissionDate, formatSubmissionTime } from 'utils'

import * as Styled from './OrderDetailsDrawerStyles'
import { OrderDisplayStatus, mapOrderStatus, statusConfig } from './OrderManagement'

interface OrderDetailsDrawerProps {
  isOpen: boolean
  onClose: () => void
  order: ReportingOrder | null
  onModalOpen: (order: ReportingOrder, type: ModalType) => void
}

const OrderDetailsDrawer: React.FC<OrderDetailsDrawerProps> = ({
  isOpen,
  onClose,
  order,
  onModalOpen,
}) => {
  if (!order) return null

  const { user } = useAuth0()
  const isAdminUser = user?.email?.includes('@chowlyinc.com')

  const {
    order_platform_order_id,
    platform_name,
    submission_time,
    pos_order_id,
    customer_first_name,
    customer_last_name,
    customer_phone,
    customer_email,
    subtotal,
    tax,
    tip,
    delivery_fee,
    order_type,
    order_status,
    delivery_street,
    payment_provider,
    internal_order_id,
  } = order.attributes

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      sx={{ '& .MuiDrawer-paper': { maxWidth: '410px', width: '100%', padding: 0 } }}
    >
      <Styled.DrawerHeader>
        <h2>Order Details</h2>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Styled.DrawerHeader>
      <Styled.DrawerContent>
        <Styled.Row full>
          <Styled.Label>Platform Ticket ID:</Styled.Label>
          <Styled.Value horizontal>
            <Styled.PlatformTicket>{order_platform_order_id}</Styled.PlatformTicket>
            <Styled.StyledChip
              label={mapOrderStatus(order_status)}
              icon={statusConfig[mapOrderStatus(order_status) as OrderDisplayStatus].icon}
              status={mapOrderStatus(order_status) as OrderDisplayStatus}
              size='small'
            />
          </Styled.Value>
        </Styled.Row>
        <Styled.Row>
          <Styled.Label>Platform:</Styled.Label>
          {isAdminUser ? (
            <Styled.CustomLink
              href={`${aperitifUrl}/orders/${internal_order_id}`}
              target='_blank'
              rel='noreferrer'
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {platform_name}
            </Styled.CustomLink>
          ) : (
            <Styled.Value>{platform_name}</Styled.Value>
          )}
        </Styled.Row>
        <Styled.RowPair>
          <Styled.Row>
            <Styled.Label>Submission Time:</Styled.Label>
            <Styled.Value horizontal>
              <Styled.SubmissionTime>{formatSubmissionTime(submission_time)}</Styled.SubmissionTime>
              <Styled.SubmissionDate>{formatSubmissionDate(submission_time)}</Styled.SubmissionDate>
            </Styled.Value>
          </Styled.Row>
          <Styled.Row>
            <Styled.Label>POS Ticket ID:</Styled.Label>
            <Styled.WrappedValue>{pos_order_id}</Styled.WrappedValue>
          </Styled.Row>
        </Styled.RowPair>
        <Styled.Row>
          <Styled.Label>Customer Information:</Styled.Label>
          <Styled.Value>
            <Styled.InfoItem>
              {customer_first_name} {customer_last_name}
            </Styled.InfoItem>
            <Styled.InfoItem>
              <a href={`tel:${customer_phone}`}>{customer_phone}</a>
            </Styled.InfoItem>
            <Styled.InfoItem>
              <a href={`mailto:${customer_email}`}>{customer_email}</a>
            </Styled.InfoItem>
          </Styled.Value>
        </Styled.Row>
        <Styled.RowPair>
          <Styled.Row>
            <Styled.Label>Subtotal:</Styled.Label>
            <Styled.Value>${subtotal}</Styled.Value>
          </Styled.Row>
          <Styled.Row>
            <Styled.Label>Tax Amount:</Styled.Label>
            <Styled.Value>${tax}</Styled.Value>
          </Styled.Row>
        </Styled.RowPair>
        <Styled.RowPair>
          <Styled.Row>
            <Styled.Label>Tip Amount:</Styled.Label>
            <Styled.Value>${tip}</Styled.Value>
          </Styled.Row>
          <Styled.Row>
            <Styled.Label>Delivery Fee:</Styled.Label>
            <Styled.Value>${delivery_fee}</Styled.Value>
          </Styled.Row>
        </Styled.RowPair>
        <Styled.Row>
          <Styled.Label>Delivery Address:</Styled.Label>
          {delivery_street.length ? (
            <Styled.Value>{delivery_street}</Styled.Value>
          ) : (
            <Styled.Value>N/A</Styled.Value>
          )}
        </Styled.Row>
        <Styled.Row>
          <Styled.Label>Order Type:</Styled.Label>
          <Styled.Value>{order_type}</Styled.Value>
        </Styled.Row>

        {payment_provider === PaymentProvider.square && (
          <AlertContainer
            messages='Square Pay payments can be refunded from the POS as needed'
            type='secondary'
          />
        )}

        {platform_name === PlatformName.COO && (
          <Styled.ButtonContainer>
            <Styled.StyledButton
              variant='contained'
              onClick={() => {
                onModalOpen(order, 'cancel')
                onClose()
              }}
            >
              Cancel Order
            </Styled.StyledButton>
            {payment_provider === PaymentProvider.stripe && (
              <Styled.StyledButton
                variant='contained'
                onClick={() => {
                  onModalOpen(order, 'refund')
                  onClose()
                }}
              >
                Refund Order
              </Styled.StyledButton>
            )}
          </Styled.ButtonContainer>
        )}
      </Styled.DrawerContent>
    </Drawer>
  )
}

export default OrderDetailsDrawer
