import { useCallback, useEffect, useState } from 'react'
import {
  Container,
  TabsContainer,
  Tabs,
  Tab,
  TableHeader,
  SearchInput,
  SearchIconWrapper,
  EmailMarketingCampaignsTable,
  PaginationWrapper,
  RowsPerPageWrapper,
  PaginationText,
  CurrentPageInfo,
  PaginationSelect,
} from './styles'
import { marketingApi } from 'config';
import SearchIcon from '@mui/icons-material/Search';
import { getEmailMarketingCampaigns } from 'api/digitalMarketing'
import useSafeAsync from 'hooks/useSafeAsync';
import useGetLocationPartners from 'hooks/useGetLocationPartners';
import { EmailMarketingCampaign } from 'types/digitalMarketing';
import { CampaignItem, NoData, Skeleton } from './components';
import { debounce, deburr } from 'lodash';
import Pagination from '@mui/material/Pagination';
import { SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

type Props = {
  partnerId?: string
}

const EmailMarketingCampaigns = ({ partnerId }: Props) => {
  const { partner } = useGetLocationPartners()
  const organizationId = partner?.attributes?.organization_id
  const [isSearching, setIsSearching] = useState(false)
  const [emailMarketingCampaigns, setEmailMarketingCampaigns] = useState<EmailMarketingCampaign[]>([]);
  const [currentStatusFilter, setCurrentStatusFilter] = useState('all');
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
 
  const filterByStatus = (element: EmailMarketingCampaign, statusFiltered?: string) => {
    const filter = statusFiltered || currentStatusFilter;
    if (filter === 'all') return element;
    
    if (element.aasm_state === filter) {
      return element
    }
    return false;
  };

  const hits = emailMarketingCampaigns?.filter((i) => filterByStatus(i, currentStatusFilter));

  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
  };

  const handleSearchChange = async (val: string) => {
    if (!organizationId) return
    const campaigns = await getEmailMarketingCampaigns(organizationId)
    const filteredCampaigns = val
      ? campaigns.filter(campaign =>
          campaign.name.toLowerCase().includes(val.toLowerCase())
        )
      : campaigns;
    setEmailMarketingCampaigns(filteredCampaigns);
    setIsSearching(false);
  };

  const safeAsync = useSafeAsync();

  const fetchCampaigns = useCallback(async () => {
    if (!organizationId) return []
    setIsSearching(true);
    const campaigns = await getEmailMarketingCampaigns(organizationId)
   
    setEmailMarketingCampaigns(campaigns);
    setIsSearching(false);
  }, [organizationId]);

  const debouncedFetch = debounce(handleSearchChange, 500);

  const isActive = (scope: string) => {
    if (currentStatusFilter === scope) return true;
    return false;
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<unknown>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  };

  const startRow = (page - 1) * rowsPerPage;
  const endRow = Math.min(startRow + rowsPerPage, hits.length);
  const paginatedHits = hits?.slice(startRow, endRow);
 
  useEffect(() => {   
    safeAsync(fetchCampaigns())
    .catch((e) => {
      console.error(e);
      setIsSearching(false)
    });
  }, [partnerId, safeAsync, fetchCampaigns]);

  const handleOnShowItem = useCallback((item: EmailMarketingCampaign) => {
    const baseUrl = marketingApi.hostUrl;
    let url = `${baseUrl}/campaigns/${item.id}`;
   
    const editStatuses = ["draft", "pending_approval"];
  
    if (editStatuses.includes(item.aasm_state)) {
      url += `/edit`;
    }

    window.open(url, '_blank')
  }, []);

  return (
      <Container>
        <TabsContainer>
          <Tabs>
            <Tab active={isActive('all')} onClick={() => setCurrentStatusFilter('all')}>
              All ({emailMarketingCampaigns?.filter((i) => filterByStatus(i, 'all')).length})
            </Tab>
            <Tab active={isActive('draft')} onClick={() => setCurrentStatusFilter('draft')}>
              Draft ({emailMarketingCampaigns?.filter((i) => filterByStatus(i, 'draft')).length})
            </Tab>
            <Tab active={isActive('pending_approval')} onClick={() => setCurrentStatusFilter('pending_approval')}>
              Pending Approval ({emailMarketingCampaigns?.filter((i) => filterByStatus(i, 'pending_approval')).length})
            </Tab>
            <Tab active={isActive('approved')} onClick={() => setCurrentStatusFilter('approved')}>
              Approved ({emailMarketingCampaigns?.filter((i) => filterByStatus(i, 'approved')).length})
            </Tab>
            <Tab active={isActive('rejected')} onClick={() => setCurrentStatusFilter('rejected')}>
              Rejected ({emailMarketingCampaigns?.filter((i) => filterByStatus(i, 'rejected')).length})
            </Tab>
            <Tab active={isActive('sent')} onClick={() => setCurrentStatusFilter('sent')}>
              Sent ({emailMarketingCampaigns?.filter((i) => filterByStatus(i, 'sent')).length})
            </Tab>
            <Tab active={isActive('paused')} onClick={() => setCurrentStatusFilter('paused')}>
              Paused ({emailMarketingCampaigns?.filter((i) => filterByStatus(i, 'paused')).length})
            </Tab>
            <Tab active={isActive('error')} onClick={() => setCurrentStatusFilter('error')}>
              Errored ({emailMarketingCampaigns?.filter((i) => filterByStatus(i, 'error')).length})
            </Tab>
          </Tabs>
          <div>
            {searchVisible ? (
              <SearchInput
                visible={searchVisible}
                type="text"
                placeholder="Search by name..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                  const trimmedValue = deburr(e.target.value.trim()).toLowerCase();
                  debouncedFetch(trimmedValue);
                }}
                autoFocus
              />
            ) : (
              <SearchIconWrapper onClick={toggleSearch} data-testid="search-icon">
                <SearchIcon />
              </SearchIconWrapper>
            )}
          </div>
        </TabsContainer>
        <EmailMarketingCampaignsTable>
          <TableHeader>
            <div>Campaign Name</div>
            <div>Subject</div>
            <div>Send Date</div>
            <div></div>
          </TableHeader>
          <div data-cy="table-body">
              {isSearching ? (
                <Skeleton />
              ) : (
                (!partnerId || hits.length === 0) ? (
                  <NoData />
                ) : (
                  paginatedHits?.map((campaign) => {
                    return (
                      <CampaignItem 
                        key={campaign.id}
                        item={campaign}
                        onAction={handleOnShowItem}
                      />
                    )
                  })
                )
              )}
          </div>
          {hits?.length > 0 && (
            <PaginationWrapper>
              <RowsPerPageWrapper>
                <PaginationText>Rows per Page:</PaginationText>
                <PaginationSelect
                  value={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </PaginationSelect>
              </RowsPerPageWrapper>

              <CurrentPageInfo>
                {startRow + 1}-{endRow} of {hits?.length}
              </CurrentPageInfo>

              <Pagination
                count={Math.ceil(hits?.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                variant="text"
                shape="rounded"
              />
            </PaginationWrapper>
          )}
        </EmailMarketingCampaignsTable>
      </Container>
  )
}

export default EmailMarketingCampaigns